import * as React from 'react';
import { CustomThunkDispatch } from './configureStore';
import { IAppPublic } from '@sportnet/ui/Authorization/types';
import { RootState } from './rootReducer';
import { activeAppspaceSelector } from './containers/App/selectors';
import { connect } from 'react-redux';
import { setApps, setAuthUser } from './containers/App/actions';
import { useParams } from 'react-router-dom';
import Api from './api';
import Authorization from '@sportnet/ui/Authorization';
import Cookies from 'js-cookie';
import Invitation from './pages/Invitation';
import SportnetApi from './sportnetApi';
import config from './config';

const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

interface OwnProps {
  dispatch: CustomThunkDispatch;
}

const mapStateToProps = (state: RootState) => ({
  activeAppspace: activeAppspaceSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & IMapStateToProps;

const Routes: React.FC<Props> = (props) => {
  const { instanceId } = useParams();
  const { dispatch } = props;
  React.useEffect(() => {
    const token = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || '';
    Api.setToken(token);
    SportnetApi.setToken(token);
  }, []);

  const onAuthorized = (data: any) => {
    dispatch(setAuthUser(data));
  };

  const onAppsReceived = (data: IAppPublic[]) => {
    dispatch(setApps(data));
  };

  return (
    <Authorization
      onAppsReceived={onAppsReceived}
      // accessToken={Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || ''}
      appId={config.APP_ID}
      onAuthorized={onAuthorized}
      scope="profile,messages"
      basePath={`/invitation/${instanceId}`}
      state={instanceId}
    >
      <Invitation {...props} />
    </Authorization>
  );
};

export default connect(mapStateToProps)(Routes);
