import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { __, required } from '../../../utilities';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Message from '@sportnet/ui/Message';

type Props = {
  segments?: { _id: string; name: string }[];
};

interface FormData {
  email: string;
  segments?: { [key: string]: boolean };
}

const ParticipantInvitationForm: React.FC<Props> = ({ segments = [] }) => {
  return (
    <>
      <Field
        name="email"
        component={FormFieldRedux}
        label={__('Emailová adresa')}
        required
        validate={[required]}
      />
      <Message primary>
        {__('Zvoľte časti, ku ktorým bude mať účastník prístup.')}
      </Message>
      {segments?.map((segment) => {
        return (
          <Field
            name={`segments[${segment._id}]`}
            component={FormFieldRedux}
            type="checkbox"
            label={segment.name}
          />
        );
      })}
    </>
  );
};

export default reduxForm<FormData, Props>({})(ParticipantInvitationForm);
