import {
  IProcedureInstanceDetailState,
  procedureInstanceDetailReducer,
} from './pages/ProcedureInstances/reducer';
import {
  ProcedureDetailState,
  procedureDetailReducer,
} from './pages/Procedures/Detail/reducer';
import {
  aclReducer,
  applicationReducer,
  entitiesReducer,
  userGroupsReducer,
} from './containers/App/reducer';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as listReducer } from '@sportnet/redux-list';

const rootReducer = combineReducers({
  application: applicationReducer,
  list: listReducer,
  entities: entitiesReducer,
  form: formReducer,
  procedureDetail: procedureDetailReducer,
  procedureInstanceDetail: procedureInstanceDetailReducer,
  userGroups: userGroupsReducer,
  acl: aclReducer,
});

export default rootReducer;

export type RootState = {
  application: ReturnType<typeof applicationReducer>;
  list: ReturnType<typeof listReducer>;
  entities: ReturnType<typeof entitiesReducer>;
  form: ReturnType<typeof formReducer>;
  procedureDetail: ProcedureDetailState;
  procedureInstanceDetail: IProcedureInstanceDetailState;
  userGroups: ReturnType<typeof userGroupsReducer>;
  acl: { [key: string]: boolean };
};
