import { IUploadDocumentLogEntry } from '../../library/DocumentLog';

const getNewFilesFromLogEntry = (logEntry: IUploadDocumentLogEntry) => {
  if (
    !logEntry.newState.data ||
    !logEntry.newState.data.files ||
    logEntry.newState.data.files.length === 0
  ) {
    return [];
  }

  const prevFiles =
    (logEntry.prevState.data && logEntry.prevState.data.files) || [];

  const existingIds: any = prevFiles.reduce((acc: any, file) => {
    acc[file.fileid] = true;
    return acc;
  }, {});

  return logEntry.newState.data.files.filter((file) => {
    if (!(file.fileid in existingIds)) {
      existingIds[file.fileid] = true;
      return true;
    }
    return false;
  });
};

export default getNewFilesFromLogEntry;
