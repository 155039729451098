import * as React from 'react';
import { CustomThunkDispatch } from '../../configureStore';
import { Link, Route, Routes } from 'react-router-dom';
import { __ } from '../../utilities';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../containers/App/actions';
import Detail from './Detail';
import DetailNew from './Detail/new';
import List from './List';
import ProcedureInstancesList from '../ProcedureInstances/Lists/ProcedureInstancesList';

type Props = {
  dispatch: CustomThunkDispatch;
};

const Procedures: React.FC<Props> = ({ dispatch }) => {
  React.useEffect(() => {
    dispatch(
      setBreadcrumbs([
        <Link key="root" to="#">
          {__('Licenčné konania')}
        </Link>,
      ])
    );
  }, [dispatch]);

  return (
    <Routes>
      <Route path={`/new`} element={<DetailNew />} />
      <Route path={`/:id/instances`} element={<ProcedureInstancesList />} />
      <Route path={`/:id`} element={<Detail />} />
      <Route path={``} element={<List />} />
    </Routes>
  );
};

export default connect()(Procedures);
