import { RootState } from '../../rootReducer';
import { createSelector } from 'reselect';
import getProp from '@sportnet/utilities/getProp';

const applicationDomainSelector = (state: RootState) => {
  return state.application;
};

export const aclSelector = (state: RootState) => state.acl;

export const userGroupsSelector = (state: RootState) => {
  return state.userGroups;
};

export const activeAppspaceIdSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.activeAppspace || ''
);

export const appspacesSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.appspaces
);

export const activeAppspaceSelector = createSelector(
  appspacesSelector,
  activeAppspaceIdSelector,
  (appspaces, activeId) => {
    if (activeId && appspaces) {
      const ppo = appspaces.find((appspace) => {
        return appspace.app_space === activeId;
      });
      return ppo;
    }
    return null;
  }
);

export const activeAppspaceNameSelector = createSelector(
  activeAppspaceSelector,
  (substate) => {
    if (substate) {
      return getProp(substate, ['org_profile', 'name']);
    }
    return null;
  }
);

export const activeAppspaceProfileSelector = createSelector(
  activeAppspaceSelector,
  (substate) => {
    if (substate) {
      return substate.org_profile;
    }
    return null;
  }
);

export const authUserSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.authUser
);

export const applicationInfoSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.applicationInfo
);

export const appsSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.apps
);

export const breadcrumbsSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.breadcrumbs
);
