import * as React from 'react';
import { CustomThunkDispatch } from '../../configureStore';
import { RootState } from 'rootReducer';
import { Route, Routes, useParams } from 'react-router-dom';
import { activeAppspaceIdSelector } from 'containers/App/selectors';
import { connect } from 'react-redux';
import { setActiveAppspace } from 'containers/App/actions';
import Detail from './Detail';
import InstanceSegmentReport from './InstanceSegmentReport';
import InstancesList from './Lists/InstancesList';

const mapStateToProps = (state: RootState) => ({
  activeAppSpace: activeAppspaceIdSelector(state),
});

type Props = {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps>;

const ProcedureInstances: React.FC<Props> = ({ dispatch, activeAppSpace }) => {
  const { appspace } = useParams();
  React.useEffect(() => {
    if (!activeAppSpace && appspace) {
      dispatch(setActiveAppspace(appspace));
    }
  }, [activeAppSpace, appspace, dispatch]);

  return (
    <Routes>
      <Route path={'/'} element={<InstancesList />} />
      <Route path={`/:instanceId`} element={<Detail />} />
      <Route
        path={`/:instanceId/segments/:segmentId/report`}
        element={<InstanceSegmentReport />}
      />
    </Routes>
  );
};

export default connect(mapStateToProps)(ProcedureInstances);
