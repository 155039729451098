import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import {
  ProcedureSegment,
  ProcedureSegmentDocument,
} from '../../../../library/Procedure';
import { RootState } from '../../../../rootReducer';
import { __, required } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadProcedureInstanceById } from '../../actions';
import { procedureInstanceDetailSelector } from '../../selectors';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import api from '../../../../api';

interface IFormData {
  note: string;
}
interface OwnProps {
  form: string;
  document: ProcedureSegmentDocument;
  segment: ProcedureSegment;
  initialValues: {
    note?: string;
  };
  handleClose: () => void;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  appSpace: activeAppspaceIdSelector(state),
  instance: procedureInstanceDetailSelector(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & InjectedFormProps<IFormData, OwnProps>;

const ProcedureInstanceDocumentReportForm: React.FC<Props> = ({document}) => {
  return (
    <Row>
      {document.status === 'ACCEPTED' && (
        <Col xs={12}>
          <Field
            component={FormField}
            validate={[required]}
            type="theselectsimple"
            name="reportStatus"
            label={__('Spôsob schválenia')}
            required
            options={[
              {
                label: __("Schválené"),
                value: 'ACCEPTED'
              },
              {
                label: __("Schválené s výhradou"),
                value: 'ACCEPTED_WITH_NOTICE'
              }
            ]}
          />
        </Col>
      )}
      <Col xs={12}>
        <Field
          component={FormField}
          validate={[required]}
          required
          type="textarea"
          name="note"
          label={__('Poznámka')}
        />
      </Col>
    </Row>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm<IFormData, Props>({
    enableReinitialize: true,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      if (props.instance) {
        await api.appendInstanceDocumentReport(
          props.appSpace,
          props.instance._id,
          props.segment._id,
          props.document._id,
          undefined,
          { ...values }
        );
        props.dispatch(
          loadProcedureInstanceById.action({ id: props.instance._id })
        );
        props.handleClose();
      }
    },
  })
)(ProcedureInstanceDocumentReportForm);
