import * as actions from './actions';
import { AnyAction } from 'redux';
import { ApplicationInfo, Appspace, User } from '../../library/App';
import { IAppPublic } from '@sportnet/ui/Authorization/types';
import { User_Group } from '../../sportnetApi';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';

export type ApplicationState = {
  readonly applicationInfo: ApplicationInfo | null;
  readonly appspaces: Appspace[];
  readonly authUser: User | null;
  readonly activeAppspace: string | null;
  readonly breadcrumbs: Array<JSX.Element | string>;
  readonly codelists: {
    [codelistId: string]: Array<{
      label: string;
      value: string;
    }>;
  };
  apps: IAppPublic[];
};

const INITIAL_STATE: ApplicationState = {
  applicationInfo: null,
  appspaces: [],
  authUser: null,
  activeAppspace: null,
  breadcrumbs: [],
  codelists: {},
  apps: [],
};

export const applicationReducer = reducerWithInitialState(INITIAL_STATE)
  .case(
    actions.setAppspaces,
    (state, appspaces): ApplicationState => ({
      ...state,
      appspaces,
    })
  )
  .case(
    actions.setActiveAppspace,
    (state, activeAppspace): ApplicationState => ({
      ...state,
      activeAppspace,
    })
  )
  .case(
    actions.setAuthUser,
    (state, authorizationResponse): ApplicationState => ({
      ...state,
      authUser: authorizationResponse.user,
    })
  )
  .case(
    actions.setApplicationInfo,
    (state, applicationInfo): ApplicationState => ({
      ...state,
      applicationInfo,
    })
  )
  .case(
    actions.setApps,
    (state, apps): ApplicationState => ({
      ...state,
      apps,
    })
  )
  .case(
    actions.removeActiveAppspace,
    (state): ApplicationState => ({
      ...state,
      activeAppspace: null,
    })
  )
  .case(
    actions.setBreadcrumbs,
    (state, breadcrumbs): ApplicationState => ({
      ...state,
      breadcrumbs,
    })
  );

export const entitiesReducer = (state = {}, action: AnyAction) => {
  return state;
};

type UserGroupsState = ReadonlyArray<User_Group>;

const USER_GROUPS_INITIAL_STATE: UserGroupsState = [];

export const userGroupsReducer = reducerWithInitialState(
  USER_GROUPS_INITIAL_STATE
).case(
  actions.loadUserGroups.async.done,
  (state, payload): UserGroupsState => payload.result.groups || []
);

export const aclReducer = reducerWithInitialState({}).case(
  actions.getAcl.async.done,
  (state, payload): {} => payload.result
);
