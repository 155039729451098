import * as React from 'react';
import { CustomThunkDispatch } from '../../configureStore';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboard from '../../pages/Dashboard';
import ProcedureInstances from '../../pages/ProcedureInstances';
import Procedures from '../../pages/Procedures';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  dispatch: CustomThunkDispatch;
}

const App: React.FC<Props> = () => {
  return (
    <Wrapper>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="procedures/*" element={<Procedures />} />
        <Route path="instances/*" element={<ProcedureInstances />} />
      </Routes>
    </Wrapper>
  );
};

export default connect()(App) as any;
