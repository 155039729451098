import { CommitError, commit } from '@sportnet/redux-list';
import { CustomThunkAction } from '../../../configureStore';
import { LIST_NAME } from './';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';

export const load = (
  offset: number,
  limit: number
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    return dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const { items, total } = await Api.getProcedures(appspace, {
              offset,
              limit,
            });
            return {
              total,
              results: items,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      })
    );
  };
};
