import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { CustomThunkDispatch } from '../../../configureStore';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { ProcedureSegment } from '../../../library/Procedure';
import { RootState } from '../../../rootReducer';
import { WidgetsContent } from 'library/Common';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';
import {
  addSegmentAndSave,
  loadProcedureById,
  handleStatusChange as reduxHandleStatusChange,
  saveAll,
  updateSegmentsOrder,
} from './actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  detailIsFetchingSelector,
  isSomeFormDirtySelector,
  isSomethingFetchingSelector,
  segmentsSelector,
} from './selectors';
import { loadUserGroups } from '../../../containers/App/actions';
import { useNavigate, useParams } from 'react-router-dom';
import AttendantsList from './forms/attendantsList';
import BasicForm from './forms/basicForm';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Layout from '../../../containers/Layout';
import Segment from '@sportnet/ui/Segment';
import SegmentForm from './forms/segmentForm';
import api from '../../../api';
import config from '../../../config';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';

export const FORM_NAME = `${config.PROCEDURES_NS}_FORM`;

export interface IFormData {
  name: string;
  dateTo: string;
  description?: WidgetsContent;
}

export const segmentFormNameFactory = (id: string) =>
  `${config.PROCEDURES_NS}_SEGMENT_FORM_${id}`;

interface OwnProps {
  data: IFormData;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const { data } = ownProps;
  let initialValues = null;
  if (data) {
    initialValues = {
      name: data.name,
      dateTo: data.dateTo,
      description: data.description || [],
    };
  }
  const isFetching = detailIsFetchingSelector(state);
  return {
    isFetching,
    isInitialFetching: isFetching && !data,
    initialValues,
    segments: segmentsSelector(state),
    isSomethingFetching: isSomethingFetchingSelector(state),
    isSomeFormDirty: isSomeFormDirtySelector(state),
    appSpace: activeAppspaceIdSelector(state),
  };
};
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps &
  WithPopupsProps & { dispatch: CustomThunkDispatch } & InjectedFormProps<
    IFormData,
    OwnProps
  >;

const DraftProcedure: React.FC<Props> = ({
  alert,
  confirm,
  appSpace,
  dispatch,
  isFetching,
  isInitialFetching,
  segments: defaultSegments,
  handleSubmit,
  error,
  isSomethingFetching,
  isSomeFormDirty,
  dirty,
}) => {
  const [isRemoving, setIsRemoving] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [creatingCopy, setCreatingCopy] = React.useState(false);

  const [segments, setSegments] = React.useState(defaultSegments);
  React.useEffect(() => {
    setSegments(defaultSegments);
  }, [defaultSegments]);

  // Function to move a segment up or down
  const moveSegment = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= segments.length) return; // Prevent invalid moves
    const updatedSegments = [...segments];
    const [movedSegment] = updatedSegments.splice(fromIndex, 1); // Remove from fromIndex
    updatedSegments.splice(toIndex, 0, movedSegment); // Insert at toIndex
    setSegments(updatedSegments);
    dispatch(
      updateSegmentsOrder.action(
        updatedSegments.map(({ data: { _id } }) => _id)
      )
    );
  };

  const navigate = useNavigate();
  const { id = '' } = useParams();

  const saveInterval: React.MutableRefObject<number | undefined> =
    React.useRef();

  React.useEffect(() => {
    dispatch(loadUserGroups.action());
    saveInterval.current = window.setInterval(() => {
      dispatch(saveAll.action());
    }, 30000);
    return () => {
      window.clearInterval(saveInterval as any);
    };
  }, [dispatch]);

  const renderSegment = (
    segment: {
      _generatedId: string;
      data: ProcedureSegment;
    },
    idx: number,
    allSegments: any[]
  ) => (
    <SegmentForm
      key={segment._generatedId}
      _generatedId={segment._generatedId}
      idx={idx}
      isFirst={idx === 0}
      isLast={idx + 1 === allSegments.length}
      form={segmentFormNameFactory(segment._generatedId)}
      moveSegment={moveSegment}
    />
  );

  const handleAddSegment = () => {
    dispatch(addSegmentAndSave.action());
  };

  const renderError = (error?: string) => {
    if (!error) {
      return '';
    }
    return ` - ${__('Chyba pri ukladaní')}`;
  };

  const renderStatus = () => {
    if (isSomeFormDirty) {
      return __('Niektoré zmeny nie sú uložené');
    }
    if (isFetching) {
      return __('Ukladá sa ...');
    }
    return __('Všetko je uložené');
  };

  const handleDelete = async () => {
    await confirm(
      __('Skutočne si želáte odstrániť rozpísané konanie?'),
      async () => {
        setIsRemoving(true);
        try {
          await api.deleteProcedure(appSpace, id);
          navigate(`/admin/${appSpace}/procedures`);
        } catch (e) {
          alert(__('Ľutujeme, konanie sa nepodarilo odstrániť.'));
        } finally {
          setIsRemoving(false);
        }
      }
    );
  };

  const handleOpen = async () => {
    setIsOpening(true);
    try {
      await handleStatusChange(
        'OPENED',
        __(
          'Skutočne si želáte otvoriť konanie? Všetkým zúčastnením bude odoslaná správa a konanie už nebude možné upraviť.'
        )
      );
    } catch (e) {
      //
    } finally {
      setIsOpening(false);
    }
  };

  const handleStatusChange = async (
    status: 'OPENED',
    confirmationMessage: string
  ) => {
    await confirm(confirmationMessage, async () => {
      try {
        await dispatch(reduxHandleStatusChange.action({ status }));
        dispatch(loadProcedureById.action({ id }));
      } catch (e) {
        if ((e as any).details.name === 'NO_PARTICIPANTS_SELECTED') {
          alert(
            __(
              'Konania sa musí zúčastniť aspoň jeden účastník. Prosím, pridajte účastníka kliknutím na tlačidlo "Pridať účastníka" v časti "Účastníci konania"'
            )
          );
        } else if ((e as any).details.name === 'NO_SEGMENTS_CREATED') {
          alert(
            __(
              'Konanie musí obsahovať minimálne jednu časť konania. Prosím, pridajte časť konania kliknutím na tlačidlo "Pridať časť".'
            )
          );
        } else if ((e as any).details.name === 'NO_DOCUMENTS_IN_SEGMENT') {
          alert(
            __(
              'Ľutujeme, niektorá z časti konania neobsahuje žiadne dokumenty. Dokument do časti pridáte kliknutím na tlačidlo "Pridať dokument". V prípade, že si želáte prázdnu časť odstrániť, kliknite na krížik, ktorý sa nachádza v pravom hornom rohu danej časti konania.'
            )
          );
        } else {
          alert(__('Ľutujeme, nepodarilo sa zmeniť stav konania.'));
        }
      }
    });
  };

  const createCopy = async () => {
    if (window.confirm(__('Skutočne si želáte vytvoriť kópiu konania?'))) {
      try {
        setCreatingCopy(true);
        const copy = await api.createProcedureCopyById(appSpace, id, {});
        navigate(`/admin/${appSpace}/procedures/${copy._id}`);
      } catch (e) {
        alert(__('Kópiu konania sa nepodarilo vytvoriť'));
      } finally {
        setCreatingCopy(false);
      }
    }
  };

  return (
    <Layout
      isLoading={isInitialFetching}
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>{renderStatus()}</ContextBarItem>
          <ContextBarItem>
            <Button onClick={handleDelete} danger basic loading={isRemoving}>
              {__('Zmazať')}
            </Button>
            &nbsp;
            <Button onClick={handleOpen} success loading={isOpening}>
              {__('Otvoriť konanie')}
            </Button>
            &nbsp;
            <Button
              onClick={handleSubmit}
              disabled={!isSomeFormDirty}
              loading={isSomethingFetching}
              primary
            >
              {__('Uložiť')}
            </Button>
            &nbsp;
            <Button onClick={createCopy} loading={creatingCopy} primary>
              {__('Vytvoriť kópiu')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment>
        <Segment raised>
          <Header size="s" withSeparator>
            {__('Základné údaje')}
            {isFetching && ` - ${__('Ukladá sa ...')}`}
            {dirty && !isFetching && ` - ${__('Neuložené zmeny')}`}
            {renderError(error)}
          </Header>
          <BasicForm />
        </Segment>
        <AttendantsList />

        {segments.map(renderSegment)}

        <Button icon="plus" primary basic onClick={handleAddSegment}>
          {__('Pridať časť')}
        </Button>
      </Segment>
    </Layout>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm<IFormData, Props>({
    form: FORM_NAME,
    enableReinitialize: true,
    onSubmit: (values: any, dispatch: any, props: any) => {
      dispatch(saveAll.action(values));
    },
  }),
  withPopups
)(DraftProcedure);
