import * as actions from './actions';
import { Instance } from '../../library/Instance';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export type IProcedureInstanceDetailState = Readonly<{
  data: Instance | null;
  isFetching: boolean;
  error: string;
}>;

export const INITIAL_STATE: IProcedureInstanceDetailState = {
  data: null,
  isFetching: false,
  error: '',
};

export const procedureInstanceDetailReducer =
  reducerWithInitialState<IProcedureInstanceDetailState>(INITIAL_STATE)
    .case(
      actions.loadProcedureInstanceById.async.started,
      (state): IProcedureInstanceDetailState => ({
        ...state,
        isFetching: true,
        error: '',
      })
    )
    .case(
      actions.loadProcedureInstanceById.async.done,
      (state, parameters): IProcedureInstanceDetailState => ({
        data: parameters.result,
        isFetching: false,
        error: '',
      })
    )
    .case(
      actions.loadProcedureInstanceById.async.failed,
      ({ error }): IProcedureInstanceDetailState => ({
        data: null,
        error: 'failed',
        isFetching: false,
      })
    )
    .case(
      actions.changeInvitationStatus.async.done,
      (state, parameters): IProcedureInstanceDetailState => ({
        data: parameters.result,
        isFetching: false,
        error: '',
      })
    )
    .case(
      actions.loadInvitationById.async.started,
      (): IProcedureInstanceDetailState => ({
        data: null,
        isFetching: true,
        error: '',
      })
    )
    .case(
      actions.loadInvitationById.async.done,
      (state, parameters): IProcedureInstanceDetailState => ({
        data: parameters.result,
        isFetching: false,
        error: '',
      })
    )
    .case(
      actions.loadInvitationById.async.failed,
      ({ error }): IProcedureInstanceDetailState => ({
        data: null,
        error: 'failed',
        isFetching: false,
      })
    );
