import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { LIST_NAME } from '../../List';
import { Procedure } from '../../../../library/Procedure';
import { RootState } from '../../../../rootReducer';
import { __ } from '../../../../utilities';
import { connect } from 'react-redux';
import { detailIsFetchingSelector, detailSelector } from '../selectors';
import { getListResults, initialize, isCommiting } from '@sportnet/redux-list';
import { load } from '../../List/actions';
import { loadProcedureById } from '../actions';
import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import Tree, { MenuItem } from '@sportnet/ui/Tree';
import api from '../../../../api';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const mapStateToProps = (state: RootState) => ({
  isFetching: isCommiting(LIST_NAME)(state) || false,
  isFetchingProcedure: detailIsFetchingSelector(state),
  procedures: (getListResults(LIST_NAME)(state) || []) as Procedure[],
  procedure: detailSelector(state),
});

const ProceduresTree: React.FC<
  ReturnType<typeof mapStateToProps> & {
    dispatch: CustomThunkDispatch;
    segmentId: string;
    documentId: string;
    disabled?: boolean;
  }
> = ({
  procedures,
  procedure,
  segmentId,
  documentId,
  isFetching,
  isFetchingProcedure,
  dispatch,
  disabled = false,
}) => {
  const [treeData, setTreeData] = React.useState<MenuItem[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          query: {},
          offset: 0,
        },
      })
    );
    dispatch(load(0, 100));
  }, [dispatch]);

  React.useEffect(() => {
    if (procedure) {
      setTreeData(
        procedures.map((i) => {
          const procedureHasShares = (procedure.shares || []).find(
            (share) => share.procedureId === i._id
          );
          return {
            _id: i._id,
            title: i.name,
            expanded: !!procedureHasShares,
            children: (i.segments || []).map((segment) => {
              const segmentHasShares = (procedure.shares || []).find(
                (share) =>
                  share.procedureId === i._id &&
                  share.segmentId === segment._id &&
                  share.sourceSegmentId === segmentId
              );
              return {
                _id: segment._id,
                title: segment.name,
                expanded: !!segmentHasShares,
                children: (segment.documents || []).map((document) => {
                  const documentIsShared = (procedure.shares || []).find(
                    (share) =>
                      share.procedureId === i._id &&
                      share.segmentId === segment._id &&
                      share.sourceSegmentId === segmentId &&
                      share.documentId === document._id &&
                      share.sourceDocumentId === documentId
                  );
                  return {
                    _id: document._id,
                    title: documentIsShared ? (
                      <em style={{ color: 'green' }}>{`${document.name} (${__(
                        'Zdieľaný'
                      )})`}</em>
                    ) : (
                      document.name
                    ),
                    isDocument: true,
                    appSpace: i.appSpace,
                    procedureId: i._id,
                    segmentId: segment._id,
                  } as any;
                }),
              };
            }),
          };
        })
      );
    }
  }, [procedures, procedure, segmentId, documentId]);

  const shareDocument = async (data: {
    procedureId: string;
    segmentId: string;
    _id: string;
  }) => {
    if (procedure) {
      try {
        setIsSubmitting(true);
        let shares: Array<{
          procedureId: string;
          segmentId: string;
          documentId: string;
        }> = [...(procedure.shares || [])].filter(
          (i) =>
            i.sourceSegmentId === segmentId && i.sourceDocumentId === documentId
        );
        const sharedIdx = shares.findIndex(
          (i) =>
            i.procedureId === data.procedureId &&
            i.segmentId === data.segmentId &&
            i.documentId === data._id
        );
        if (sharedIdx > -1) {
          delete shares[sharedIdx];
          shares = shares.filter((i) => i);
        } else {
          shares.push({
            procedureId: data.procedureId,
            segmentId: data.segmentId,
            documentId: data._id,
          });
        }
        await api.setSegmentDocumentShares(
          procedure.appSpace,
          procedure._id,
          segmentId,
          documentId,
          {},
          {
            shares,
          }
        );
        dispatch(loadProcedureById.action({ id: procedure._id }));
      } catch (e) {
        alert(__('Zdieľanie dokumentu sa nepodarilo nastaviť'));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="l" />
      </LoaderWrapper>
    );
  }

  return (
    <Segment loading={isSubmitting || isFetchingProcedure}>
      <div style={{ height: 400, width: '100%', overflow: 'auto' }}>
        <Tree
          onClickNodeTitle={(i: any) => {
            if (i.isDocument && !disabled) {
              shareDocument(i);
            }
          }}
          treeData={treeData}
          onChange={setTreeData}
        />
      </div>
    </Segment>
  );
};

export default connect(mapStateToProps)(ProceduresTree as any) as any;
