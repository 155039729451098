import { RootState } from '../../rootReducer';
import { createSelector } from 'reselect';

const domain = (state: RootState) => {
  return state.procedureInstanceDetail;
};

// Procedures
export const procedureInstanceDetailSelector = createSelector(
  domain,
  (substate) => substate.data
);

export const procedureInstanceDetailIsFetchingSelector = createSelector(
  domain,
  (substate) => substate.isFetching
);
