// import FormField from '@sportnet/ui/FormField/redux-form';
// import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { InstanceOptions } from './InstanceForm';
import {
  ProcedureInstanceSegment,
  ProcedureInstanceSegmentDocument,
} from '../../../../library/Instance';
import { RootState } from '../../../../rootReducer';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { ThemeProvider, withTheme } from 'styled-components';
import { __ } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import { format, isAfter, isBefore } from 'date-fns';
import { isSubmitting, submit } from 'redux-form';
import { loadProcedureInstanceById } from '../../actions';
import { procedureInstanceDetailSelector } from '../../selectors';
import { warningColor } from './ProcedureInstanceSegmentForm';
import Button from '@sportnet/ui/Button';
import DocumentTypeUploadForm from './DocumentsForms/Upload';
import DotLabel from '@sportnet/ui/Label/DotLabel';
import FileInput from '../../../../components/FileInput';
import Header from '@sportnet/ui/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import ProcedureInstanceDocumentReportForm from './ProcedureInstanceDocumentReportForm';
import ProcedureInstanceDocumentStatusForm from './ProcedureInstanceDocumentStatusForm';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import UploadDocumentLog from '../../../../components/UploadDocumentLog';
import Widgets from '@sportnet/content/view';
import api from '../../../../api';
import getProp from '@sportnet/utilities/getProp';
import styled from '../../../../theme/styled-components';
import withPopups, { WithPopupsProps } from '../../../../components/WithPopups';

const REPORT_FORM_NAME = 'DOCUMENT_REPORT_FORM';

const Description = styled('div')`
  font-size: 14px;
`;

export const StatusChange = styled('div')`
  color: #9e9e9e;
  font-weight: normal;
  text-decoration: underline;
  padding-left: 17px;
  cursor: pointer;
`;

const RefusalMessage = styled('div')`
  padding-left: 17px;
`;

const BottomPadding = styled.div`
  padding-bottom: 10px;
`;

interface OwnProps {
  segment: ProcedureInstanceSegment;
  document: ProcedureInstanceSegmentDocument;
  isAuditor: boolean;
}

const getDocumentStatusFormName = (documentId: string) =>
  `DOCUMENT_${documentId}_STATUS_FORM`;

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  appSpace: activeAppspaceIdSelector(state),
  instance: procedureInstanceDetailSelector(state),
  isCommiting: isSubmitting(getDocumentStatusFormName(props.document._id))(
    state
  ),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  InstanceOptions & { theme: Theme } & WithPopupsProps &
  IMapStateToProps & { dispatch: CustomThunkDispatch };

const ProcedureInstanceDocumentForm: React.FC<Props> = ({
  isOwner,
  isAuditor,
  dispatch,
  isCommiting,
  isParticipant,
  segment,
  theme,
  instance,
  appSpace,
  document,
}) => {
  const [opened, setOpened] = React.useState(false);
  const [reportModalOpened, setReportModalOpened] = React.useState(false);
  const [initialModalValues, setInitialModalValues] = React.useState<{
    status?: string;
  }>({});
  const [initialReportModalValues, setInitialReportModalValues] =
    React.useState<{
      note?: string | undefined;
    }>({});

  const handleReportModalOpen = () => {
    setReportModalOpened(true);
    setInitialReportModalValues(document.report || {});
  };

  const handleReportModalClose = () => {
    setReportModalOpened(false);
    setInitialReportModalValues({});
  };

  const getStatus = () => {
    let isOwner = false;
    if (instance && instance.createdByAppSpace._id === appSpace) {
      isOwner = true;
    }
    let reportButton = (
      <StatusChange onClick={handleReportModalOpen}>
        {__('Pridať záverečnú správu')}
      </StatusChange>
    );
    if (document.report && document.report.note) {
      reportButton = (
        <StatusChange onClick={handleReportModalOpen}>
          {__('Zobraziť záverečnú správu')}
        </StatusChange>
      );
    }
    let dotLabel;
    switch (document.status) {
      case 'CHANGES_REQUESTED':
        return <DotLabel color={warningColor}>{__('Čaká na úpravy')}</DotLabel>;
      case 'NEW':
      case 'FAILED':
        dotLabel = (
          <DotLabel color={warningColor}>{__('Čaká na úpravy')}</DotLabel>
        );
        if (
          document.dateTo &&
          isBefore(new Date(document.dateTo), new Date())
        ) {
          dotLabel = (
            <>
              <DotLabel color={theme.color.danger}>{__('Nesplnené')}</DotLabel>
              {isOwner && reportButton}
            </>
          );
        }
        return (
          <div>
            {dotLabel}
            <RefusalMessage>
              {document.dateTo && (
                <div>
                  Termín dodania:{' '}
                  {format(new Date(document.dateTo), 'd.M.yyyy HH:mm')}
                </div>
              )}
              {document.note && <div>Dôvod zamietnutia: {document.note}</div>}
            </RefusalMessage>
          </div>
        );
      case 'OPENED':
        return (
          <DotLabel color={warningColor}>{__('Čaká na schválenie')}</DotLabel>
        );
      case 'IN_PROCESS':
        return (
          <DotLabel color={theme.color.primary}>{__('Rozpracované')}</DotLabel>
        );
      case 'ACCEPTED':
        return (
          <>
            <DotLabel color={theme.color.success}>{__('Schválené')}</DotLabel>
            {isOwner && !isAuditor && reportButton}
          </>
        );
      default:
        throw new Error(`DOCUMENT_IN_UNKNOWN_STATE: ${document.status}`);
    }
  };

  const openModal = (status: string) => {
    setOpened(true);
    setInitialModalValues({ status });
  };

  const closeModal = () => {
    setOpened(false);
    setInitialModalValues({});
  };

  const renderDocumentForm = () => {
    let isOwner = false;
    if (instance && instance.createdByAppSpace._id === appSpace) {
      isOwner = true;
    }
    if (instance) {
      const shares = getProp(
        instance,
        ['shares', 'sharedDocuments'],
        []
      ).filter(
        (share: any) =>
          share.sourceSegmentId === segment._id &&
          share.sourceDocumentId === document._id
      );
      const sharedInstances = shares.reduce((acc: any, share: any) => {
        const shareInstance = getProp(
          instance,
          ['shares', 'instances'],
          []
        ).find((i: any) => i.procedureId === share.procedureId);
        if (shareInstance) {
          return [
            ...acc,
            {
              ...share,
              _id: `${share.procedureId}:${share.segmentId}:${share.documentId}`,
              name: shareInstance.name,
            },
          ];
        }
        return acc;
      }, []);
      let isPastDate = isBefore(new Date(instance.dateTo), new Date());
      if (!isPastDate && document.dateTo) {
        isPastDate = isBefore(new Date(document.dateTo), new Date());
      }
      const props = {
        form: `INSTANCE_DOCUMENT_${document._id}`,
        document,
        sharedInstances,
        initialValues: {
          ...document,
          shares: sharedInstances.map((i: any) => ({
            label: i.name,
            value: i._id,
          })),
        },
        segment,
        isOwner,
        invited: instance && instance.status === 'INVITED',
        disabled:
          document.status === 'ACCEPTED' ||
          (instance && instance.status === 'INVITED') ||
          isPastDate,
      };
      switch (document.type) {
        case 'upload':
          return !isAuditor ? <DocumentTypeUploadForm {...props} /> : null;
        default:
          throw new Error('Unexpected document type provided');
      }
    }
    return <div />;
  };

  const isCollapsed = () => {
    if (isParticipant) {
      return document.status === 'ACCEPTED' || document.status === 'OPENED';
    }
    return (
      document.status === 'ACCEPTED' ||
      document.status === 'NEW' ||
      document.status === 'FAILED'
    );
  };

  const onOpenFile = async (fileId: string) => {
    if (!instance) {
      return;
    }

    const { downloadId } = await api.getDocumentFile(
      appSpace || instance.appSpace,
      instance._id,
      segment._id,
      document._id,
      fileId
    );
    window.open(
      `${process.env.REACT_APP_CONTROLLING_API_BASE_URL}/downloader/${downloadId}`
    );
  };

  const onDeleteFile = async (i: { fileid: string }) => {
    if (instance) {
      try {
        await api.updateInstanceDocument(
          appSpace,
          instance._id,
          segment._id,
          document._id,
          undefined,
          { type: 'upload', data: { removeFilesIds: [i.fileid] } }
        );
        dispatch(loadProcedureInstanceById.action({ id: instance._id })).then(
          () => {
            if (window.history.pushState) {
              window.history.pushState(null, '', `#document_${document._id}`);
            } else {
              window.location.hash = `#document_${document._id}`;
            }
          }
        );
      } catch (e) {
        alert(__('Nepodarilo sa zneaktívniť súbor'));
      } finally {
        //
      }
    }
  };

  const FORM_NAME = getDocumentStatusFormName(document._id);

  return (
    <React.Fragment>
      <Segment
        secondary
        header={
          <SegmentHeader
            withSeparator
            size="xs"
            collapsible
            isCollapsed={isCollapsed()}
            subtitle={<React.Fragment>{getStatus()}</React.Fragment>}
          >
            {document.name}
          </SegmentHeader>
        }
      >
        {document.description && (
          <Description>
            <ThemeProvider theme={defaultContentTheme}>
              <Widgets
                renderLink={(link: any) => (
                  <a href="/" target="_blank" {...link.linkProps}>
                    {link.linkProps.children}
                  </a>
                )}
                items={document.description}
              />
            </ThemeProvider>
          </Description>
        )}
        {renderDocumentForm()}
        {isOwner &&
          (document.status === 'OPENED' ||
            document.status === 'CHANGES_REQUESTED') && (
            <>
              <Button
                onClick={() => {
                  openModal('IN_PROCESS');
                }}
                warning
              >
                {__('Označiť ako rozpracované')}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  openModal('ACCEPTED');
                }}
                success
              >
                {__('Schváliť')}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  openModal('FAILED');
                }}
                danger
              >
                {__('Zamietnuť')}
              </Button>
            </>
          )}
        {isOwner &&
          document.status !== 'OPENED' &&
          document.status !== 'CHANGES_REQUESTED' &&
          document.status !== 'IN_PROCESS' &&
          document.status !== 'FAILED' &&
          !!document.dateTo &&
          isAfter(new Date(document.dateTo), new Date()) && (
            <Button
              onClick={() => {
                openModal('CHANGES_REQUESTED');
              }}
              success
            >
              {__('Odomknúť pre úpravy')}
            </Button>
          )}
        <BottomPadding />
        {(document.log || []).length > 0 ? (
          <Segment
            raised
            header={
              <SegmentHeader withSeparator size="xs" collapsible>
                {__('Záznam o zmenách')}
              </SegmentHeader>
            }
          >
            <UploadDocumentLog
              files={document.files || []}
              onOpenFile={onOpenFile}
              log={document.log || []}
            />
          </Segment>
        ) : (
          <></>
        )}
        {(document.files || []).length > 0 ? (
          <Segment
            raised
            header={
              <SegmentHeader withSeparator size="xs" collapsible>
                {__('Nahrané súbory')}
              </SegmentHeader>
            }
          >
            <FileInput
              onChange={() => {
                //
              }}
              onFileClick={(i) => {
                onOpenFile(i.fileid);
              }}
              {...((isOwner ||
                (isParticipant && document.status !== 'ACCEPTED')) && {
                onDeleteCallback: onDeleteFile,
              })}
              disabled
              value={document.files || []}
            />
          </Segment>
        ) : (
          <></>
        )}
      </Segment>
      <Modal isOpen={opened} handleClose={closeModal}>
        <ModalContent>
          <Header withSeparator>{__('Zmena stavu dokumentu')}</Header>
          <ProcedureInstanceDocumentStatusForm
            form={FORM_NAME}
            documentId={document._id}
            segmentId={segment._id}
            handleClose={closeModal}
            initialValues={initialModalValues}
            {...(!!document.dateTo &&
              isBefore(new Date(document.dateTo), new Date()) &&
              document.status !== 'OPENED' &&
              document.status !== 'CHANGES_REQUESTED' && {
                availableStatuses: [
                  {
                    label: __('Odomknuté pre úpravy'),
                    value: 'CHANGES_REQUESTED',
                  },
                ],
              })}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button secondary type="button" onClick={closeModal}>
              {__('Zavrieť')}
            </Button>
            <Button
              primary
              type="button"
              loading={isCommiting}
              onClick={() => {
                dispatch(submit(FORM_NAME));
              }}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      <Modal isOpen={reportModalOpened} handleClose={handleReportModalClose}>
        <ModalContent>
          <Header size="s" withSeparator>
            {__('Záverečná správa pre dokument ')} {document.name}
          </Header>
          {reportModalOpened && (
            <ProcedureInstanceDocumentReportForm
              initialValues={initialReportModalValues}
              document={document}
              segment={segment}
              form={REPORT_FORM_NAME}
              handleClose={handleReportModalClose}
            />
          )}
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button secondary type="button" onClick={handleReportModalClose}>
              {__('Zavrieť')}
            </Button>
            <Button
              primary
              loading={isCommiting}
              type="button"
              onClick={() => {
                dispatch(submit(REPORT_FORM_NAME));
              }}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </React.Fragment>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps),
  withTheme
)(ProcedureInstanceDocumentForm);
