import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { CustomThunkDispatch } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';
import {
  changeInvitationStatus,
  loadInvitationById,
} from '../ProcedureInstances/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  procedureInstanceDetailIsFetchingSelector,
  procedureInstanceDetailSelector,
} from '../ProcedureInstances/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@sportnet/ui/Button';
import InstanceForm from '../ProcedureInstances/Detail/Forms/InstanceForm';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import TheLayout from '@sportnet/ui/TheLayout';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const mapStateToProps = (state: RootState) => ({
  data: procedureInstanceDetailSelector(state),
  isFetching: procedureInstanceDetailIsFetchingSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps &
  WithPopupsProps & {
    dispatch: CustomThunkDispatch;
  };

const Invitation: React.FC<Props> = ({
  isFetching,
  confirm,
  dispatch,
  data,
}) => {
  const { instanceId } = useParams();
  const navigation = useNavigate();

  React.useEffect(() => {
    dispatch(loadInvitationById.action({ id: instanceId || '' }));
  }, [dispatch, instanceId]);

  const acceptInvitation = async () => {
    await changeInvitationState(
      __('Skutočne si želáte zúčastniť konania?'),
      'OPENED'
    );
  };

  const changeInvitationState = async (
    confirmationMessage: string,
    status: 'OPENED' | 'STORNO'
  ) => {
    if (data) {
      await confirm(confirmationMessage, async () => {
        await dispatch(changeInvitationStatus.action({ status }));
        if (status === 'OPENED') {
          navigation(`/admin/${data.appSpace}/instances/${data._id}`);
        }
      });
    }
  };

  if (!data) {
    return null;
  }
  const LayoutSettings: { bottom?: JSX.Element; title?: string } = {};
  if (data) {
    LayoutSettings.title = data.name;
  }
  if (data && data.status === 'INVITED') {
    LayoutSettings.bottom = (
      <ContextBar>
        <ContextBarSpacer />
        <ContextBarItem>
          <Button onClick={acceptInvitation} success>
            {__('Zúčastniť sa konania')}
          </Button>
        </ContextBarItem>
      </ContextBar>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <TheLayout {...LayoutSettings}>
        {data || isFetching ? (
          <div
            style={{
              maxWidth: '960px',
              width: '100%',
              marginTop: '10px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Segment loading={isFetching}>
              {data && <InstanceForm data={data} />}
            </Segment>
          </div>
        ) : (
          <NotFound
            title={__('Pozvánka neexistuje alebo už bola medzičasom prijatá.')}
            icon="error"
          />
        )}
      </TheLayout>
    </div>
  );
};

export default compose(connect(mapStateToProps), withPopups)(Invitation);
