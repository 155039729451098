import * as React from 'react';
import { CustomThunkDispatch } from '../../configureStore';
import { IApp, IAppSpace } from '@sportnet/ui/TheLayout/types';
import {
  INavigationItem,
  INavigationSubItem,
} from '@sportnet/ui/TheLayout/Navigation';
import { Link } from 'react-router-dom';
import { Loader } from '@sportnet/ui/Loader';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities';
import {
  aclSelector,
  activeAppspaceNameSelector,
  activeAppspaceSelector,
  applicationInfoSelector,
  appsSelector,
  appspacesSelector,
  authUserSelector,
  breadcrumbsSelector,
} from '../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import AuthorizationContext from '@sportnet/ui/Authorization/Auth/AuthorizationContext';
import Cookies from 'js-cookie';
import TheLayout from '@sportnet/ui/TheLayout';
import TitlePanel from '@sportnet/ui/TitlePanel';
import getProp from '@sportnet/utilities/getProp';
import styled from '../../theme/styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface OwnProps {
  topFixed?: JSX.Element;
  bottomFixed?: JSX.Element;
  isLoading?: boolean | null;
  children?: React.ReactNode;
}

const mapStateToProps = (state: RootState) => ({
  appspace: activeAppspaceSelector(state),
  appspaces: appspacesSelector(state),
  apps: appsSelector(state),
  organizationName: activeAppspaceNameSelector(state),
  application: applicationInfoSelector(state),
  breadcrumbs: breadcrumbsSelector(state),
  acl: aclSelector(state),
  user: authUserSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & { theme: any } & IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  };

interface MenuItem {
  url: string;
  label: string;
  subitems: MenuItem[];
  acl?: string;
}

const Layout: React.FC<Props> = ({
  appspace,
  application,
  breadcrumbs,
  topFixed,
  bottomFixed,
  children,
  organizationName,
  isLoading,
  theme,
  apps,
  appspaces,
  user,
  acl,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  const { logout } = React.useContext(AuthorizationContext);

  const changeMenuItems = React.useCallback(
    (aclObject: typeof acl) => {
      const newMenuItems = [
        {
          url: `/admin/${appspace ? appspace.app_space : ''}/procedures`,
          label: __('Licenčné konania'),
          acl: 'getProcedures',
          subitems: [],
        },
        {
          url: `/admin/${appspace ? appspace.app_space : ''}/instances`,
          label: __('Moje konania'),
          acl: 'meInstances',
          subitems: [],
        },
      ].reduce((acc, item) => {
        if (!item.acl || aclObject[item.acl]) {
          const { acl, ...restItem } = item;
          return [...acc, restItem];
        }
        return acc;
      }, []);
      setMenuItems(newMenuItems);
    },
    [appspace]
  );

  React.useEffect(() => {
    changeMenuItems(acl);
  }, [acl, changeMenuItems]);

  const handleOpen = () => {
    setSidebarVisible(true);
  };

  const handleClose = () => {
    setSidebarVisible(false);
  };

  const basicCrumbs = [
    <Link key={0} to="/">
      {__('Zoznam organizácií')}
    </Link>,
    organizationName,
  ];
  const settings = {
    topFixed: (
      <div style={{ position: 'relative', zIndex: 1, backgroundColor: '#fff' }}>
        <TitlePanel
          name={appspace ? appspace.display_name || '' : ''}
          title={getProp(application, ['name'], '')}
          logo={getProp(application, ['logo'], '')}
          onLogout={logout}
          crumbs={[...basicCrumbs, ...breadcrumbs]}
          menuButton={{
            opened: sidebarVisible,
            onOpen: handleOpen,
            onClose: handleClose,
          }}
        />
        {topFixed}
      </div>
    ),
    bottomFixed: <div />,
  };
  if (bottomFixed) {
    settings.bottomFixed = bottomFixed;
  }

  return (
    <TheLayout
      // logo={getProp(application, ['logo'], '')}
      title={getProp(application, ['name'], '')}
      top={topFixed}
      accessToken={Cookies.get('access_token')}
      navigation={menuItems}
      renderNavigationLink={(renderProps: {
        item: INavigationItem | INavigationSubItem;
        linkProps: {
          className: string;
          onClick?: () => void;
          children: React.ReactNode;
        };
      }) => {
        const {
          item: { url },
          linkProps,
        } = renderProps;

        return (
          <Link to={url || ''} {...linkProps}>
            {linkProps.children}
          </Link>
        );
      }}
      onLogout={logout}
      app={({ ...application, appSpaceIds: [] } as IApp) || undefined}
      apps={(apps || []) as IApp[]}
      appSpace={appspace as IAppSpace}
      appSpaces={(appspaces || []) as IAppSpace[]}
      // userOrganization={organizationName || ''}
      // userOrganizationProfile={
      //   appspace
      //     ? appspace.org_profile
      //       ? appspace.org_profile.name
      //       : ''
      //     : ''
      // }
      userName={appspace ? appspace.display_name || '' : ''}
      userPictureSrc={
        user && user.photo && user.photo.public_url
          ? user.photo.public_url
          : undefined
      }
      {...(bottomFixed && { bottom: bottomFixed })}
    >
      {isLoading ? (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      ) : (
        children
      )}
    </TheLayout>
  );
};

export default compose(withTheme, connect(mapStateToProps))(Layout);
