import * as React from 'react';
import { CustomThunkDispatch } from '../../../configureStore';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Procedure } from '../../../library/Procedure';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  getListParameters,
  getListResults,
  getListTotal,
  hasCommitFailed,
  initialize,
  isCommiting,
} from '@sportnet/redux-list';
import { load as reduxLoad } from './actions';
import { setBreadcrumbs } from '../../../containers/App/actions';
import BasicTable from '@sportnet/ui/BasicTable';
import ContextBar from './contextBar';
import Layout from '../../../containers/Layout';
import NotFound from '@sportnet/ui/NotFound';
import ProcedureStatus from '../../../components/ProcedureStatus';
import Segment from '@sportnet/ui/Segment';
import config from '../../../config';
import useQuery, { NumberParam } from '@sportnet/query-hoc/useQuery';

export const LIST_NAME = config.PROCEDURES_NS;
const LIST_LIMIT = config.DEFAULT_LIST_LIMIT;

interface OwnProps {
  dispatch: CustomThunkDispatch;
}

const mapStateToProps = (state: RootState) => ({
  isFetching: isCommiting(LIST_NAME)(state) || false,
  total: getListTotal(LIST_NAME)(state),
  parameters: getListParameters(LIST_NAME)(state),
  procedures: (getListResults(LIST_NAME)(state) || []) as Procedure[],
  hasCommitFailed: hasCommitFailed(LIST_NAME)(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps;

const prepareSearchString = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    return `${acc}&${key}=${value}`;
  }, '?');
};

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
  },
};

const Procedures: React.FC<Props> = ({
  total,
  procedures,
  isFetching,
  hasCommitFailed,
  dispatch,
}) => {
  const { appspace = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setQuery, query, serializedQuery } = useQuery(
    prepareSearchString(searchParams),
    (serializedQuery) => navigate(`${location.pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG
  );

  const load = React.useCallback(
    async (serializedQuery: string) => {
      dispatch(reduxLoad(query.offset ? Number(query.offset) : 0, LIST_LIMIT));
    },
    [dispatch, query]
  );

  React.useEffect(() => {
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          query,
          offset: query.offset || 0,
        },
      })
    );
    dispatch(
      setBreadcrumbs([
        <Link key="root" to="#">
          {__('Licenčné konania')}
        </Link>,
      ])
    );
    load(serializedQuery);
  }, [serializedQuery, dispatch, load, query]);

  const handleClickLoad = () => window.location.reload();

  return (
    <Layout
      isLoading={isFetching}
      bottomFixed={
        <ContextBar
          limit={LIST_LIMIT}
          loading={isFetching}
          offset={query.offset as number}
          total={total || 0}
          setParameter={setQuery}
          onClickNew={() => {
            navigate(`/admin/${appspace}/procedures/new`);
          }}
        />
      }
    >
      <Segment>
        <Segment raised>
          {hasCommitFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={handleClickLoad}
            />
          ) : !isFetching && !procedures.length ? (
            <NotFound title={__('Nenašli sa žiadne konania')} icon="search" />
          ) : (
            <BasicTable
              columns={[
                { header: __('Názov') },
                { header: __('Stav') },
                { header: __('Dátum ukončenia') },
              ]}
              onClickRow={(item: Procedure) => {
                navigate(`/admin/${appspace}/procedures/${item._id}`);
              }}
              rows={procedures}
              renderRow={(item: Procedure) => [
                item.name,
                <ProcedureStatus status={item.status} key={item._id} />,
                format(new Date(item.dateTo), 'dd.MM.yyyy'),
              ]}
              rowKey="_id"
            />
          )}
        </Segment>
      </Segment>
    </Layout>
  );
};

export default compose(connect(mapStateToProps))(Procedures);
