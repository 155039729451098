import * as React from 'react';
import { CustomThunkDispatch } from '../../../configureStore';
import { RootState } from '../../../rootReducer';
import { WidgetsContent } from 'library/Common';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  detailIsFetchingSelector,
  detailSelector,
  isSomeFormDirtySelector,
  isSomethingFetchingSelector,
  segmentsSelector,
} from './selectors';
import { loadProcedureById } from './actions';
import { useParams } from 'react-router-dom';
import DraftProcedure from './draft';
import Layout from '../../../containers/Layout';
import ReadOnlyProcedure from './readonly';
import config from '../../../config';

export const FORM_NAME = `${config.PROCEDURES_NS}_FORM`;

export interface IFormData {
  name: string;
  dateTo: string;
  description?: WidgetsContent;
}

export const segmentFormNameFactory = (id: string) =>
  `${config.PROCEDURES_NS}_SEGMENT_FORM_${id}`;

interface OwnProps {
  dispatch: CustomThunkDispatch;
}

const mapStateToProps = (state: RootState) => {
  const data = detailSelector(state);
  let initialValues = null;
  if (data) {
    initialValues = {
      name: data.name,
      dateTo: data.dateTo,
      description: data.description || []
    };
  }
  const isFetching = detailIsFetchingSelector(state);
  return {
    isFetching,
    isInitialFetching: isFetching && !data,
    data,
    initialValues,
    segments: segmentsSelector(state),
    isSomethingFetching: isSomethingFetchingSelector(state),
    isSomeFormDirty: isSomeFormDirtySelector(state),
  };
};
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps;

const Procedure: React.FC<Props> = ({ dispatch, data, isFetching }) => {
  const { id = '' } = useParams();

  React.useEffect(() => {
    dispatch(loadProcedureById.action({ id }));
  }, [id, dispatch]);

  if (isFetching || !data) {
    return <Layout isLoading />;
  }
  if (data.status === 'DRAFT') {
    return <DraftProcedure data={data} />;
  }
  if (data.status === 'OPENED' || data.status === 'CLOSED') {
    return <ReadOnlyProcedure data={data} />;
  }
  throw new Error('Something went terribly wrong, procedure in unknown state.');
};

export default compose(connect(mapStateToProps))(Procedure);
