import * as React from 'react';
import {
  CommitError,
  commit,
  getListResults,
  initialize,
  isCommiting,
} from '@sportnet/redux-list';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { CustomThunkDispatch } from '../../../configureStore';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Instance } from '../../../library/Instance';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProcedureSegment } from '../../../library/Procedure';
import { RootState } from '../../../rootReducer';
import { WidgetsContent } from 'library/Common';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { detailIsFetchingSelector, segmentsSelector } from './selectors';
import {
  loadProcedureById,
  handleStatusChange as reduxHandleStatusChange,
} from './actions';
import {
  loadUserGroups,
  setBreadcrumbs,
} from '../../../containers/App/actions';
import BasicForm from './forms/basicForm';
import Button from '@sportnet/ui/Button';
import ExtendTermForm from './forms/extendTermForm';
import Header from '@sportnet/ui/Header';
import Layout from '../../../containers/Layout';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import ProcedureInstancesList from '../../../pages/ProcedureInstances/Lists/ProcedureInstancesList/list';
import Segment from '@sportnet/ui/Segment';
import SegmentForm from './forms/segmentForm';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import api from '../../../api';
import config from '../../../config';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';

export const FORM_NAME = `${config.PROCEDURES_NS}_FORM`;

export interface IFormData {
  name: string;
  dateTo: string;
  description?: WidgetsContent;
  status: 'DRAFT' | 'OPENED' | 'CLOSED';
}

export const segmentFormNameFactory = (id: string) =>
  `${config.PROCEDURES_NS}_SEGMENT_FORM_${id}`;

interface OwnProps {
  data: IFormData;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  // const data = detailSelector(state);
  const { data } = ownProps;
  let initialValues = null;
  if (data) {
    initialValues = {
      name: data.name,
      dateTo: data.dateTo,
      description: data.description || []
    };
  }
  const isFetching = detailIsFetchingSelector(state);
  return {
    isFetching,
    isInitialFetching: isFetching && !data,
    initialValues,
    appSpace: activeAppspaceIdSelector(state),
    segments: segmentsSelector(state),
    isListCommiting: isCommiting(LIST_NAME)(state),
    instances: (getListResults(LIST_NAME)(state) || []) as Instance[],
  };
};
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps &
  WithPopupsProps & { dispatch: CustomThunkDispatch } & InjectedFormProps<
    IFormData,
    OwnProps
  >;

const LIST_NAME = 'INSTANCES_LIST';

const ReadOnlyProcedure: React.FC<Props> = ({
  confirm,
  alert,
  dispatch,
  isInitialFetching,
  segments,
  appSpace,
  data,
  isListCommiting,
  instances,
}) => {
  const [opened, setOpened] = React.useState(false);
  const [creatingCopy, setCreatingCopy] = React.useState(false);

  const { id = '' } = useParams();
  const navigate = useNavigate();

  const getProcedureInstances = React.useCallback(async () => {
    return dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const { items, total } = await api.getProcedureInstances(
              appSpace,
              id
            );
            return {
              total,
              results: items,
            };
          } catch (e) {
            throw new CommitError();
          }
        },
      })
    );
  }, [appSpace, dispatch, id]);

  React.useEffect(() => {
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          offset: 0,
        },
      })
    );
    dispatch(
      setBreadcrumbs([
        <Link key="parent" to={`/admin/${appSpace}/procedures`}>
          {__('Licenčné konania')}
        </Link>,
        <Link key="root" to="#">
          {data.name}
        </Link>,
      ])
    );
    getProcedureInstances();
    dispatch(loadUserGroups.action());
  }, [appSpace, data, dispatch, getProcedureInstances]);

  const renderSegment = (
    segment: {
      _generatedId: string;
      data: ProcedureSegment;
    },
    idx: number
  ) => (
    <SegmentForm
      key={segment._generatedId}
      _generatedId={segment._generatedId}
      idx={idx}
      disabled
      form={segmentFormNameFactory(segment._generatedId)}
    />
  );

  const handleClose = () => {
    setOpened(false);
  };

  const handleOpen = () => {
    setOpened(true);
  };

  const handleProcedureClose = async () => {
    await handleStatusChange(
      'CLOSED',
      __('Skutočne si želáte uzavrieť konanie?')
    );
  };

  const handleStatusChange = async (
    status: 'CLOSED',
    confirmationMessage: string
  ) => {
    await confirm(confirmationMessage, async () => {
      try {
        await dispatch(reduxHandleStatusChange.action({ status }));
        dispatch(loadProcedureById.action({ id }));
      } catch (e) {
        alert(__('Ľutujeme, nepodarilo sa zmeniť stav konania.'));
      }
    });
  };

  const createCopy = async () => {
    if (window.confirm(__('Skutočne si želáte vytvoriť kópiu konania?'))) {
      try {
        setCreatingCopy(true);
        const copy = await api.createProcedureCopyById(appSpace, id, {});
        navigate(`/admin/${appSpace}/procedures/${copy._id}`);
      } catch (e) {
        alert(__('Kópiu konania sa nepodarilo vytvoriť'));
      } finally {
        setCreatingCopy(false);
      }
    }
  };

  return (
    <Layout
      isLoading={isInitialFetching}
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            {data.status === 'OPENED' && (
              <>
                <Button primary onClick={handleOpen}>
                  {__('Predĺžiť konanie')}
                </Button>
                &nbsp;
                <Button onClick={handleProcedureClose} danger>
                  {__('Uzavrieť konanie')}
                </Button>
                &nbsp;
              </>
            )}
            <Button onClick={createCopy} loading={creatingCopy} primary>
              {__('Vytvoriť kópiu')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment>
        <Segment raised>
          <Header size="s" withSeparator>
            {__('Základné údaje')}
          </Header>
          <BasicForm disabled />
        </Segment>
        <Segment
          raised
          header={
            <SegmentHeader collapsible size="s" withSeparator>
              {__('Účastníci')}
              <br />
              <br />
              <Button
                primary
                onClick={() => {
                  navigate(`/admin/${appSpace}/procedures/${id}/instances`);
                }}
              >
                {__('Prejsť na zoznam')}
              </Button>
            </SegmentHeader>
          }
        >
          <ProcedureInstancesList
            isFetching={isListCommiting}
            items={instances}
          />
        </Segment>
        {segments.map(renderSegment)}
      </Segment>
      <Modal isOpen={opened} handleClose={handleClose}>
        <ModalContent>
          <Header>{__('Predĺženie konania')}</Header>
          <ExtendTermForm
            handleClose={handleClose}
            data={{ dateTo: data.dateTo }}
          />
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm<IFormData, Props>({
    form: FORM_NAME,
    enableReinitialize: true,
  }),
  withPopups
)(ReadOnlyProcedure);
