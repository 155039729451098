import * as React from 'react';
import { Smarttags } from '../../../../library/Common';
import { User_Group } from '../../../../sportnetApi';
import { WrappedFieldProps } from 'redux-form';
import FormField from '@sportnet/ui/FormField';

interface OwnProps {
  label: string;
  availableUserGroups: User_Group[];
  readOnly?: boolean;
}

type Props = OwnProps & WrappedFieldProps;

const userGroupsField = (props: Props) => {
  const value: string[] = Array.isArray(props.input.value)
    ? props.input.value
    : [];
  const { readOnly = false } = props;
  return (
    <FormField
      type="smarttag"
      label={props.label}
      input={props.input}
      meta={props.meta}
      readOnly={readOnly}
      value={[
        {
          key: 'Skupina',
          values: value.map((v) => ({ key: v })),
        },
      ]}
      onChange={(smarttags: Smarttags) => {
        props.input.onChange(
          smarttags.reduce((acc: string[], grp) => {
            acc.push(...((grp.values || []).map((val) => val.key) as string[]));
            return acc;
          }, [])
        );
      }}
      availableSmarttags={[
        {
          key: 'Skupina',
          values: props.availableUserGroups.map((grp) => ({ key: grp._id })),
        },
      ]}
    />
  );
};

export default userGroupsField;
