import * as React from 'react';
import { RootState } from '../../rootReducer';
import { activeAppspaceIdSelector } from '../../containers/App/selectors';
import { connect } from 'react-redux';

const mapStateToProps = (state: RootState) => ({
  appSpaceId: activeAppspaceIdSelector(state),
});
export type WithAppSpaceIdProps = ReturnType<typeof mapStateToProps>;

const makeHoc = <WrappedProps extends WithAppSpaceIdProps>(
  Component: React.ComponentType<WrappedProps & WithAppSpaceIdProps>
) => {
  const EnhancedComponent = class MakeHoc extends React.Component<WrappedProps> {
    render() {
      return <Component {...this.props} />;
    }
  };

  return connect(mapStateToProps)(EnhancedComponent as any);
};

export default makeHoc;
