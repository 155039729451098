import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { CustomThunkDispatch } from '../../../configureStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import {
  aclSelector,
  activeAppspaceIdSelector,
  activeAppspaceSelector,
  authUserSelector,
} from '../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formatUserName } from '@sportnet/utilities';
import { loadProcedureInstanceById } from '../actions';
import {
  procedureInstanceDetailIsFetchingSelector,
  procedureInstanceDetailSelector,
} from '../selectors';
import { setBreadcrumbs } from '../../../containers/App/actions';
import { submit } from 'redux-form';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import InstanceForm from './Forms/InstanceForm';
import Layout from '../../../containers/Layout';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import ParticipantInvitationForm from './participantInvitationForm';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import api from '../../../api';
import sportnetApi from 'sportnetApi';

const PARTICIPANT_INVITATION_FORM_NAME = 'PARTICIPANT_INVITATION_FORM';

const mapStateToProps = (state: RootState) => ({
  data: procedureInstanceDetailSelector(state),
  isFetching: procedureInstanceDetailIsFetchingSelector(state),
  appSpace: activeAppspaceIdSelector(state),
  user: authUserSelector(state),
  authUserRole: activeAppspaceSelector(state)?.role,
  acl: aclSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = IMapStateToProps & {
  dispatch: CustomThunkDispatch;
};

const ProcedureInstanceDetail: React.FC<Props> = ({
  dispatch,
  isFetching,
  data,
  appSpace,
  user,
  authUserRole,
  acl,
}) => {
  const [
    participantInvitationModalOpened,
    setParticipantInvitationModalOpened,
  ] = React.useState(false);
  const [
    participantInvitationIsSubmitting,
    setParticipantInvitationIsSubmitting,
  ] = React.useState(false);
  const [participantInvitationSuccess, setParticipantInvitationSuccess] =
    React.useState(false);

  const { instanceId = '' } = useParams();
  const navigation = useNavigate();

  const [allUsers, setAllUsers] = React.useState<any[]>([]);
  const [allUsersIsLoading, setAllUsersIsLoading] = React.useState(false);
  const [selectedAuditors, setSelectedAuditors] = React.useState<any>({});
  const [auditorsIsSaving, setAuditorsIsSaving] = React.useState(false);

  React.useEffect(() => {
    dispatch(
      setBreadcrumbs([
        <Link key="root" to={`/admin/${appSpace}/instances`}>
          {__('Moje konania')}
        </Link>,
        <Link key="root" to="#">
          {__('Detail konania')}
        </Link>,
      ])
    );
    dispatch(loadProcedureInstanceById.action({ id: instanceId })).then(
      (instance) => {
        // nacitame auditorov
        setAllUsersIsLoading(true);
        sportnetApi
          .appgrantGetRoles('controlling', appSpace)
          .then(({ users }) => {
            setAllUsers(
              (users || []).sort((a, b) =>
                (a.display_name || '') < (b.display_name || '') ? -1 : 1
              )
            );
            setSelectedAuditors(
              (instance.auditors || []).reduce((r: any, aud: any) => {
                if (users?.find((u) => u.user_id === aud.sportnetId)) {
                  r[aud.sportnetId] = true;
                }
                return r;
              }, {})
            );
          })
          .finally(() => {
            setAllUsersIsLoading(false);
          });
      }
    );
  }, [dispatch, appSpace, instanceId]);

  React.useEffect(() => {
    if (data && data.status === 'INVITED' && data.appSpace === appSpace) {
      navigation(`/invitation/${data._id}`);
    }
  }, [data, appSpace, navigation]);

  const toggleParticipantInvitationModal = () =>
    setParticipantInvitationModalOpened(!participantInvitationModalOpened);

  const submitParticipantInvitationForm = async (values: {
    email: string;
    segments?: { [key: string]: boolean };
  }) => {
    try {
      setParticipantInvitationIsSubmitting(true);
      await api.createInstanceParticipantInvitation(
        appSpace,
        instanceId,
        undefined,
        {
          email: values.email,
          segments:
            data?.segments
              ?.filter((s: any) => !!(values.segments || {})[s._id])
              .map((s: any) => ({ _id: s._id, name: s.name })) || [],
        }
      );
      setParticipantInvitationSuccess(true);
    } catch (e) {
      alert(__('Účastníka sa nepodarilo do konania pozvať.'));
    } finally {
      setParticipantInvitationIsSubmitting(false);
      window.setTimeout(() => {
        setParticipantInvitationModalOpened(false);
        setParticipantInvitationSuccess(false);
      }, 4000);
    }
  };

  return (
    <Layout
      isLoading={isFetching}
      bottomFixed={
        data &&
        user &&
        data.status === 'OPENED' &&
        data.user._id === user._id ? (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button onClick={toggleParticipantInvitationModal} primary>
                {__('Pozvať ďalšieho účastníka')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        ) : (
          <></>
        )
      }
    >
      <>
        <Modal
          handleClose={toggleParticipantInvitationModal}
          isOpen={participantInvitationModalOpened}
        >
          <ModalContent>
            <ParticipantInvitationForm
              form={PARTICIPANT_INVITATION_FORM_NAME}
              onSubmit={submitParticipantInvitationForm}
              segments={data?.segments?.map((s: any) => ({
                _id: s._id,
                name: s.name,
              }))}
            />
          </ModalContent>
          <ModalActions>
            <div />
            <div>
              <Button onClick={toggleParticipantInvitationModal}>
                {__('Zavrieť')}
              </Button>
              &nbsp;
              <Button
                primary={!participantInvitationSuccess}
                success={participantInvitationSuccess}
                loading={participantInvitationIsSubmitting}
                onClick={() =>
                  dispatch(submit(PARTICIPANT_INVITATION_FORM_NAME))
                }
              >
                {participantInvitationSuccess
                  ? __('Pozvánka bola odoslaná')
                  : __('Pozvať účastníka')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        {!!data && (
          <Segment>
            <InstanceForm data={data} />
            {!!data.delegates && data.delegates.length && (
              <Segment
                raised
                header={
                  <SegmentHeader collapsible size="s" isCollapsed={true}>
                    {__('Delegáti konania')} ({data.delegates.length})
                  </SegmentHeader>
                }
              >
                <BasicTable
                  rows={data.delegates}
                  rowKey="_id"
                  columns={[
                    { id: 'name', header: __('Meno') },
                    { id: 'sportnetId', header: __('SportnetId') },
                    { id: 'segments', header: __('Dostupné časti konania') },
                  ]}
                  renderRow={(user) => {
                    return [
                      formatUserName(user),
                      user._id,
                      (user.segments || [])
                        .map(({ name }: { name: string }) => name)
                        .join(', '),
                    ];
                  }}
                ></BasicTable>
              </Segment>
            )}
            {!!acl &&
              acl['updateInstanceAuditors'] &&
              data.createdByAppSpace._id === appSpace && (
                <Segment
                  raised
                  header={
                    <SegmentHeader collapsible size="s" isCollapsed={true}>
                      {__('Audítori konania')} (
                      {
                        Object.entries(selectedAuditors).filter(([, s]) => s)
                          .length
                      }
                      )
                    </SegmentHeader>
                  }
                  loading={allUsersIsLoading}
                >
                  <BasicTable
                    rows={allUsers}
                    rowKey="user_id"
                    columns={[
                      { id: 'name', header: __('Meno') },
                      { id: 'role', header: __('Rola') },
                      { id: 'sportnetId', header: __('SportnetId') },
                    ]}
                    renderRow={(user) => {
                      return [user.display_name, user.role, user.user_id];
                    }}
                    selected={selectedAuditors}
                    onSelect={setSelectedAuditors}
                    onClickRow={(row) => {
                      setSelectedAuditors((current: any) => ({
                        ...current,
                        [row.user_id]: !current[row.user_id],
                      }));
                    }}
                    rowStyle={(row, index): any => {
                      if (selectedAuditors[row.user_id]) {
                        return { fontWeight: 'bold' };
                      }
                      return undefined;
                    }}
                  ></BasicTable>
                  <br />
                  <div>
                    <Button
                      primary
                      disabled={auditorsIsSaving}
                      loading={auditorsIsSaving}
                      onClick={() => {
                        const newAuditors = allUsers
                          .filter(
                            (u) =>
                              u.user_id in selectedAuditors &&
                              selectedAuditors[u.user_id]
                          )
                          .map((u) => ({
                            sportnetId: u.user_id,
                            displayName: u.display_name,
                          }));
                        setAuditorsIsSaving(true);
                        api
                          .updateInstanceAuditors(
                            appSpace,
                            instanceId,
                            {},
                            { auditors: newAuditors }
                          )
                          .catch((e) => {
                            alert(__('Nepodarilo sa upraviť zoznam audítorov'));
                            console.error(e);
                          })
                          .finally(() => {
                            setAuditorsIsSaving(false);
                          });
                      }}
                    >
                      {__('Uložiť zoznam audítorov')}
                    </Button>
                  </div>
                </Segment>
              )}
          </Segment>
        )}
      </>
    </Layout>
  );
};

export default compose(connect(mapStateToProps))(ProcedureInstanceDetail);
