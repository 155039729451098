import * as React from 'react';
import { BasicUser } from '../../../../library/App';
import { CustomThunkDispatch } from '../../../../configureStore';
import { RootState } from '../../../../rootReducer';
import { __ } from '../../../../utilities';
import { connect } from 'react-redux';
import { detailSelector } from '../selectors';
import { removeParticipantFromProcedure } from '../actions';
import AttendantForm from './attendantForm';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import formatUserName from '@sportnet/utilities/formatUserName';
import getProp from '@sportnet/utilities/getProp';
import withPopups, { WithPopupsProps } from '../../../../components/WithPopups';

const mapStateToProps = (state: RootState) => ({
  procedure: detailSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  disabled?: boolean;
};

type Props = IMapStateToProps &
  WithPopupsProps &
  OwnProps & {
    dispatch: CustomThunkDispatch;
  };

const AttendantsList: React.FC<Props> = ({
  confirm,
  dispatch,
  procedure,
  disabled,
}) => {
  const [isOpened, setIsOpened] = React.useState(false);

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleOpen = () => {
    setIsOpened(true);
  };

  const removeAttendant = async (ppo: string) => {
    await dispatch(removeParticipantFromProcedure.action({ ppo }));
  };

  return (
    <React.Fragment>
      <Segment raised>
        <Header withSeparator size="s">
          {__('Účastníci konania')}
        </Header>
        {!disabled && (
          <React.Fragment>
            <Button icon="plus" primary basic onClick={handleOpen}>
              {__('Pridať účastníka')}
            </Button>
            <br />
            <br />
          </React.Fragment>
        )}
        <BasicTable
          rowKey="_id"
          columns={[
            { header: __('Organizácia') },
            { header: __('Profil organizácie') },
            { header: __('Meno a priezvisko') },
            { header: '' },
          ]}
          rows={getProp(procedure, ['participants'], []).map((i) => ({
            ...i,
            _id: `${i.ppo}|${i.user._id}`,
          }))}
          renderRow={(item: {
            ppo: string;
            organization: { name: string; organization_name: string };
            user: BasicUser;
          }) => [
            item.organization.organization_name,
            item.organization.name,
            formatUserName({
              name: item.user.name,
              surname: item.user.surname,
              titles: item.user.titles
                ? {
                    before: item.user.titles.before || '',
                    after: item.user.titles.after || '',
                  }
                : {
                    before: '',
                    after: '',
                  },
            }),
            !disabled && (
              <Button
                onClick={async () => {
                  await confirm(
                    __('Skutočne si želáte odstrániť účastníka konania?'),
                    async () => {
                      await removeAttendant(item.ppo);
                    }
                  );
                }}
                danger
                basic
                key={`remove_attendant_${item.ppo}`}
              >
                {__('Odstrániť')}
              </Button>
            ),
          ]}
        />
      </Segment>
      {isOpened && (
        <Modal isOpen={isOpened} handleClose={handleClose}>
          <ModalContent>
            <Header>{__('Nový účastník')}</Header>
            <AttendantForm handleClose={handleClose} />
          </ModalContent>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withPopups(connect(mapStateToProps)(AttendantsList));
