/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  status: number;

  name: string;

  description?: string;

  payload?: {};

  userinfo?: string;
};
export type Pager = {
  limit: number;

  offset: number;

  total: number;

  nextOffset?: number;
};
export type File = {
  mimetype: string;

  name: string;

  size: number;

  fileid: string;

  filepath: string;
};
export type Procedure = {
  _id: string;

  appSpace: string;

  name: string;

  dateTo: string;

  status: 'DRAFT' | 'OPENED' | 'CLOSED';

  segments?: Array<{
    _id: string;

    name: string;

    administratedBy?: {
      groups?: Array<string>;
    };

    documents?: Array<{
      type: 'upload';

      name: string;

      description?: Array<{}>;

      dateTo?: string;

      _id: string;
    }>;
  }>;

  participants?: Array<{
    ppo: string;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    organization?: {
      _id?: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };
  }>;

  description?: Array<{}>;
};
export type Procedure_Basic = {
  _id: string;

  appSpace: string;

  name: string;

  dateTo: string;

  status: 'DRAFT' | 'OPENED' | 'CLOSED';

  description?: Array<{}>;
};
export type Procedure_Create = {
  name: string;

  dateTo: string;

  description?: Array<{}>;
};
export type Procedure_Update = {
  name?: string;

  dateTo?: string;

  description?: Array<{}>;
};
export type Participant = {
  ppo: string;

  user: {
    _id: string;

    name: string;

    surname: string;

    titles?: {
      before?: string;

      after?: string;
    };
  };

  organization?: {
    _id?: string;

    name?: string;

    organization_id?: string;

    organization_name?: string;
  };
};
export type Segment = {
  _id: string;

  name: string;

  administratedBy?: {
    groups?: Array<string>;
  };

  documents?: Array<{
    type: 'upload';

    name: string;

    description?: Array<{}>;

    dateTo?: string;

    _id: string;
  }>;
};
export type Segment_Modify = {
  name?: string;

  administratedBy?: {
    groups?: Array<string>;
  };
};
export type Procedure_Instance = {
  _id: string;

  appSpace: string;

  procedureId: string;

  createdByAppSpace: {
    _id: string;

    name?: string;

    organization_id?: string;

    organization_name?: string;
  };

  description?: Array<{}>;

  appSpaceInfo: {
    _id: string;

    name: string;

    organization_id: string;

    organization_name: string;
  };

  name: string;

  dateTo: string;

  status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

  userId: string;

  delegates?: Array<{
    _id: string;

    name: string;

    surname: string;

    titles?: {};

    segments?: Array<{
      _id: string;

      name: string;
    }>;
  }>;

  auditors?: Array<{
    sportnetId: string;

    displayName: string;
  }>;

  user: {
    _id: string;

    name: string;

    surname: string;

    titles?: {
      before?: string;

      after?: string;
    };
  };

  report?: {
    note: string;

    reportStatus?: string;

    files?: Array<{
      mimetype: string;

      name: string;

      size: number;

      fileid: string;

      filepath: string;
    }>;
  };

  lastChanged?: string;

  segments?: Array<{
    _id?: string;

    name?: string;

    report?: {
      note?: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    administratedBy?: {
      groups?: Array<string>;
    };

    documents?: Array<{
      _id: string;

      report?: {
        note: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      name: string;

      type: string;

      status: string;

      files?: {};

      log?: Array<{
        name: string;

        userId?: string;

        user?: {
          _id: string;

          name: string;

          surname: string;
        };

        date?: string;

        description?: string;

        prevState?: {};

        newState?: {};
      }>;
    }>;
  }>;
};
export type Procedure_Instance_Basic = {
  _id: string;

  appSpace: string;

  procedureId: string;

  createdByAppSpace: {
    _id: string;

    name?: string;

    organization_id?: string;

    organization_name?: string;
  };

  description?: Array<{}>;

  appSpaceInfo: {
    _id: string;

    name: string;

    organization_id: string;

    organization_name: string;
  };

  name: string;

  dateTo: string;

  status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

  userId: string;

  delegates?: Array<{
    _id: string;

    name: string;

    surname: string;

    titles?: {};

    segments?: Array<{
      _id: string;

      name: string;
    }>;
  }>;

  auditors?: Array<{
    sportnetId: string;

    displayName: string;
  }>;

  user: {
    _id: string;

    name: string;

    surname: string;

    titles?: {
      before?: string;

      after?: string;
    };
  };

  report?: {
    note: string;

    reportStatus?: string;

    files?: Array<{
      mimetype: string;

      name: string;

      size: number;

      fileid: string;

      filepath: string;
    }>;
  };

  lastChanged?: string;
};
export type Document_Types = {
  type: 'upload';
};
export type Common_Document = {
  type: 'upload';

  name: string;

  description?: Array<{}>;

  dateTo?: string;
};
export type Document = {
  type: 'upload';

  name: string;

  description?: Array<{}>;

  dateTo?: string;

  _id: string;
};
export type Document_Shares = {
  procedureId: string;

  segmentId: string;

  documentId: string;

  sharedDocuments: Array<{
    procedureId: string;

    segmentId: string;

    documentId: string;
  }>;
};
export type Document_Modify = {
  type: 'upload';

  name: string;

  description?: Array<{}>;

  dateTo?: string;
};
export type Upload_Base = {
  data: {
    note?: string;

    files?: Array<{
      mimetype: string;

      name: string;

      size: number;

      fileid: string;

      filepath: string;
    }>;

    shares?: Array<{
      procedureId: string;

      segmentId: string;

      documentId: string;
    }>;

    removeFilesIds?: Array<string>;

    restoreFilesIds?: Array<string>;
  };
};
export type Upload = {
  type: 'upload';

  name: string;

  description?: Array<{}>;

  dateTo?: string;

  data: {
    note?: string;

    files?: Array<{
      mimetype: string;

      name: string;

      size: number;

      fileid: string;

      filepath: string;
    }>;

    shares?: Array<{
      procedureId: string;

      segmentId: string;

      documentId: string;
    }>;

    removeFilesIds?: Array<string>;

    restoreFilesIds?: Array<string>;
  };
};
export type Segment_Instance = {
  _id?: string;

  name?: string;

  report?: {
    note?: string;

    reportStatus?: string;

    files?: Array<{
      mimetype: string;

      name: string;

      size: number;

      fileid: string;

      filepath: string;
    }>;
  };

  administratedBy?: {
    groups?: Array<string>;
  };

  documents?: Array<{
    _id: string;

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    name: string;

    type: string;

    status: string;

    files?: {};

    log?: Array<{
      name: string;

      userId?: string;

      user?: {
        _id: string;

        name: string;

        surname: string;
      };

      date?: string;

      description?: string;

      prevState?: {};

      newState?: {};
    }>;
  }>;
};
export type Document_Instance = {};
export type Document_Instance_Update = {};
export type Document_Instance_Status_Update = {};
export type ACCEPTED = {
  status: 'ACCEPTED';
};
export type FAILURE = {
  status: 'FAILED';

  dateTo?: string;

  note: string;
};
export type IN_PROCESS = {
  status: 'IN_PROCESS';

  note?: string;
};
export type CHANGES_REQUESTED = {
  status: 'CHANGES_REQUESTED';

  note?: string;
};
export type Widget = {};
export type User = {
  _id: string;

  name: string;

  surname: string;

  titles?: {
    before?: string;

    after?: string;
  };
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name Api#getProcedures
   */
  getProcedures(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      appSpace: string;

      name: string;

      dateTo: string;

      status: 'DRAFT' | 'OPENED' | 'CLOSED';

      segments?: Array<{
        _id: string;

        name: string;

        administratedBy?: {
          groups?: Array<string>;
        };

        documents?: Array<{
          type: 'upload';

          name: string;

          description?: Array<{}>;

          dateTo?: string;

          _id: string;
        }>;
      }>;

      participants?: Array<{
        ppo: string;

        user: {
          _id: string;

          name: string;

          surname: string;

          titles?: {
            before?: string;

            after?: string;
          };
        };

        organization?: {
          _id?: string;

          name?: string;

          organization_id?: string;

          organization_name?: string;
        };
      }>;

      description?: Array<{}>;
    }>;

    total: number;
  }> {
    let path = '/admin/{appSpace}/procedures';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#postProcedure
   */
  postProcedure(
    appSpace: string,
    parameters: {} = {},
    body: {
      name: string;

      dateTo: string;

      description?: Array<{}>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path = '/admin/{appSpace}/procedures';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getProcedureById
   */
  getProcedureById(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#updateProcedure
   */
  updateProcedure(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
    body: {
      name?: string;

      dateTo?: string;

      description?: Array<{}>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete Procedure. Possible only while in DRAFT state
   * @method
   * @name Api#deleteProcedure
   */
  deleteProcedure(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#createProcedureCopyById
   */
  createProcedureCopyById(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/copy';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add new participant to procedure. Possible only while procedure not in CLOSED state
   * @method
   * @name Api#addParticipantToProcedure
   */
  addParticipantToProcedure(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
    body: {
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/participants';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove participant from procedure. Possible only while procedure not in CLOSED state
   * @method
   * @name Api#removeParticipantFromProcedure
   */
  removeParticipantFromProcedure(
    appSpace: string,
    procedureId: string,
    participantId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/participants/{participantId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{participantId}', participantId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#changeProcedureStatus
   */
  changeProcedureStatus(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
    body: {
      status: 'OPENED' | 'CLOSED';
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Insert Segment. Possible only while procedure in DRAFT state
   * @method
   * @name Api#postProcedureSegment
   */
  postProcedureSegment(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
    body: {
      name?: string;

      administratedBy?: {
        groups?: Array<string>;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    administratedBy?: {
      groups?: Array<string>;
    };

    documents?: Array<{
      type: 'upload';

      name: string;

      description?: Array<{}>;

      dateTo?: string;

      _id: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/segments';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Reorder segments in procedure
   * @method
   * @name Api#updateSegmentsOrder
   */
  updateSegmentsOrder(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
    body: {
      orderedSegmentIds: Array<string>;
    },
  ): Promise<{}> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/segments-reorder';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Updates Procedure Segment. Possible only while procedure in DRAFT state
   * @method
   * @name Api#updateProcedureSegment
   */
  updateProcedureSegment(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    parameters: {} = {},
    body: {
      name?: string;

      administratedBy?: {
        groups?: Array<string>;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    administratedBy?: {
      groups?: Array<string>;
    };

    documents?: Array<{
      type: 'upload';

      name: string;

      description?: Array<{}>;

      dateTo?: string;

      _id: string;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete Procedure Segment. Possible only while procedure in DRAFT state
   * @method
   * @name Api#deleteProcedureSegment
   */
  deleteProcedureSegment(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateTo: string;

    status: 'DRAFT' | 'OPENED' | 'CLOSED';

    segments?: Array<{
      _id: string;

      name: string;

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        type: 'upload';

        name: string;

        description?: Array<{}>;

        dateTo?: string;

        _id: string;
      }>;
    }>;

    participants?: Array<{
      ppo: string;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      organization?: {
        _id?: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };
    }>;

    description?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Inserts Segment Document. Possible only while procedure in DRAFT state
   * @method
   * @name Api#postSegmentDocument
   */
  postSegmentDocument(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    parameters: {} = {},
    body: {
      type: 'upload';

      name: string;

      description?: Array<{}>;

      dateTo?: string;
    },
  ): Promise<{
    type: 'upload';

    name: string;

    description?: Array<{}>;

    dateTo?: string;

    _id: string;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}/documents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Reorder documents in segment in procedure
   * @method
   * @name Api#updateSegmentDocumentsOrder
   */
  updateSegmentDocumentsOrder(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    parameters: {} = {},
    body: {
      orderedDocumentIds: Array<string>;
    },
  ): Promise<{}> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}/documents-reorder';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Updates Segment Document. Possible only while procedure in DRAFT state
   * @method
   * @name Api#updateSegmentDocument
   */
  updateSegmentDocument(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
    body: {
      type: 'upload';

      name: string;

      description?: Array<{}>;

      dateTo?: string;
    },
  ): Promise<{
    type: 'upload';

    name: string;

    description?: Array<{}>;

    dateTo?: string;

    _id: string;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}/documents/{documentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete Segment Document. Possible only while procedure in DRAFT state
   * @method
   * @name Api#deleteSegmentDocument
   */
  deleteSegmentDocument(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}/documents/{documentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Segment Document Shares.
   * @method
   * @name Api#getDocumentShares
   */
  getDocumentShares(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
  ): Promise<{
    procedureId: string;

    segmentId: string;

    documentId: string;

    sharedDocuments: Array<{
      procedureId: string;

      segmentId: string;

      documentId: string;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}/documents/{documentId}/shares';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates Segment Document Shares. Possible only while procedure in DRAFT state
   * @method
   * @name Api#setSegmentDocumentShares
   */
  setSegmentDocumentShares(
    appSpace: string,
    procedureId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
    body: {
      shares: Array<{
        procedureId: string;

        segmentId: string;

        documentId: string;
      }>;
    },
  ): Promise<{
    procedureId: string;

    segmentId: string;

    documentId: string;

    sharedDocuments: Array<{
      procedureId: string;

      segmentId: string;

      documentId: string;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/procedures/{procedureId}/segments/{segmentId}/documents/{documentId}/shares';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getProcedureInstances
   */
  getProcedureInstances(
    appSpace: string,
    procedureId: string,
    parameters: {
      statuses?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      appSpace: string;

      procedureId: string;

      createdByAppSpace: {
        _id: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };

      description?: Array<{}>;

      appSpaceInfo: {
        _id: string;

        name: string;

        organization_id: string;

        organization_name: string;
      };

      name: string;

      dateTo: string;

      status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

      userId: string;

      delegates?: Array<{
        _id: string;

        name: string;

        surname: string;

        titles?: {};

        segments?: Array<{
          _id: string;

          name: string;
        }>;
      }>;

      auditors?: Array<{
        sportnetId: string;

        displayName: string;
      }>;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      report?: {
        note: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      lastChanged?: string;
    }>;

    total: number;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/instances';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['statuses'] !== 'undefined') {
      queryParameters['statuses'] = parameters['statuses'];
    }

    queryParameters['statuses'] = this.transformParameter(
      queryParameters['statuses'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getParticipantInstances
   */
  getParticipantInstances(
    appSpace: string,
    participantAppSpace: string,
    parameters: {
      statuses?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      appSpace: string;

      procedureId: string;

      createdByAppSpace: {
        _id: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };

      description?: Array<{}>;

      appSpaceInfo: {
        _id: string;

        name: string;

        organization_id: string;

        organization_name: string;
      };

      name: string;

      dateTo: string;

      status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

      userId: string;

      delegates?: Array<{
        _id: string;

        name: string;

        surname: string;

        titles?: {};

        segments?: Array<{
          _id: string;

          name: string;
        }>;
      }>;

      auditors?: Array<{
        sportnetId: string;

        displayName: string;
      }>;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      report?: {
        note: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      lastChanged?: string;

      segments?: Array<{
        _id?: string;

        name?: string;

        report?: {
          note?: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        administratedBy?: {
          groups?: Array<string>;
        };

        documents?: Array<{
          _id: string;

          report?: {
            note: string;

            reportStatus?: string;

            files?: Array<{
              mimetype: string;

              name: string;

              size: number;

              fileid: string;

              filepath: string;
            }>;
          };

          name: string;

          type: string;

          status: string;

          files?: {};

          log?: Array<{
            name: string;

            userId?: string;

            user?: {
              _id: string;

              name: string;

              surname: string;
            };

            date?: string;

            description?: string;

            prevState?: {};

            newState?: {};
          }>;
        }>;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/participant/{participantAppSpace}/instances';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['statuses'] !== 'undefined') {
      queryParameters['statuses'] = parameters['statuses'];
    }

    queryParameters['statuses'] = this.transformParameter(
      queryParameters['statuses'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace(
      '{participantAppSpace}',
      participantAppSpace.toString(),
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#meInstances
   */
  meInstances(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
      statuses?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      appSpace: string;

      procedureId: string;

      createdByAppSpace: {
        _id: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };

      description?: Array<{}>;

      appSpaceInfo: {
        _id: string;

        name: string;

        organization_id: string;

        organization_name: string;
      };

      name: string;

      dateTo: string;

      status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

      userId: string;

      delegates?: Array<{
        _id: string;

        name: string;

        surname: string;

        titles?: {};

        segments?: Array<{
          _id: string;

          name: string;
        }>;
      }>;

      auditors?: Array<{
        sportnetId: string;

        displayName: string;
      }>;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      report?: {
        note: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      lastChanged?: string;
    }>;

    total: number;
  }> {
    let path = '/admin/{appSpace}/instances';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['statuses'] !== 'undefined') {
      queryParameters['statuses'] = parameters['statuses'];
    }

    queryParameters['statuses'] = this.transformParameter(
      queryParameters['statuses'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getProcedureInstanceById
   */
  getProcedureInstanceById(
    appSpace: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/instances/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getProcedureShares
   */
  getProcedureShares(
    appSpace: string,
    procedureId: string,
    parameters: {} = {},
  ): Promise<{
    documents: Array<{
      documentId: string;

      segmentId: string;

      procedureId: string;

      documentName: string;

      segmentName: string;

      procedureName: string;

      sourceDocumentId: string;

      sourceSegmentId: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/procedures/{procedureId}/shares';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{procedureId}', procedureId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getInstanceShares
   */
  getInstanceShares(
    appSpace: string,
    instanceId: string,
    parameters: {} = {},
  ): Promise<{
    instances: Array<{
      _id: string;

      appSpace: string;

      procedureId: string;

      createdByAppSpace: {
        _id: string;

        name?: string;

        organization_id?: string;

        organization_name?: string;
      };

      description?: Array<{}>;

      appSpaceInfo: {
        _id: string;

        name: string;

        organization_id: string;

        organization_name: string;
      };

      name: string;

      dateTo: string;

      status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

      userId: string;

      delegates?: Array<{
        _id: string;

        name: string;

        surname: string;

        titles?: {};

        segments?: Array<{
          _id: string;

          name: string;
        }>;
      }>;

      auditors?: Array<{
        sportnetId: string;

        displayName: string;
      }>;

      user: {
        _id: string;

        name: string;

        surname: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      report?: {
        note: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      lastChanged?: string;

      segments?: Array<{
        _id?: string;

        name?: string;

        report?: {
          note?: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        administratedBy?: {
          groups?: Array<string>;
        };

        documents?: Array<{
          _id: string;

          report?: {
            note: string;

            reportStatus?: string;

            files?: Array<{
              mimetype: string;

              name: string;

              size: number;

              fileid: string;

              filepath: string;
            }>;
          };

          name: string;

          type: string;

          status: string;

          files?: {};

          log?: Array<{
            name: string;

            userId?: string;

            user?: {
              _id: string;

              name: string;

              surname: string;
            };

            date?: string;

            description?: string;

            prevState?: {};

            newState?: {};
          }>;
        }>;
      }>;
    }>;

    sharedDocuments: Array<{
      procedureId: string;

      segmentId: string;

      documentId: string;

      sourceSegmentId: string;

      sourceDocumentId: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/instances/{instanceId}/shares';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#updateInstanceAuditors
   */
  updateInstanceAuditors(
    appSpace: string,
    instanceId: string,
    parameters: {} = {},
    body: {
      auditors: Array<{
        sportnetId: string;

        displayName: string;
      }>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/instances/{instanceId}/auditors';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#uploadInstanceFile
   */
  uploadInstanceFile(
    appSpace: string,
    instanceId: string,
    parameters: {} = {},
    form: {
      upload?: {};
    },
  ): Promise<{
    mimetype: string;

    name: string;

    size: number;

    fileid: string;

    filepath: string;
  }> {
    let path = '/admin/{appSpace}/instances/{instanceId}/upload';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#appendInstanceReport
   */
  appendInstanceReport(
    appSpace: string,
    instanceId: string,
    parameters: {} = {},
    body: {
      note: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/instances/{instanceId}/report';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getInstanceReportFile
   */
  getInstanceReportFile(
    appSpace: string,
    instanceId: string,
    fileId: string,
    parameters: {} = {},
  ): Promise<{
    downloadId?: string;
  }> {
    let path = '/admin/{appSpace}/instances/{instanceId}/report/file/{fileId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{fileId}', fileId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#appendInstanceSegmentReport
   */
  appendInstanceSegmentReport(
    appSpace: string,
    instanceId: string,
    segmentId: string,
    parameters: {} = {},
    body: {
      note: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/segments/{segmentId}/report';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#appendInstanceDocumentReport
   */
  appendInstanceDocumentReport(
    appSpace: string,
    instanceId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
    body: {
      note: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/segments/{segmentId}/documents/{documentId}/report';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getSegmentReportFile
   */
  getSegmentReportFile(
    appSpace: string,
    instanceId: string,
    segmentId: string,
    fileId: string,
    parameters: {} = {},
  ): Promise<{
    downloadId?: string;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/segments/{segmentId}/report/file/{fileId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{fileId}', fileId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#createInstanceParticipantInvitation
   */
  createInstanceParticipantInvitation(
    appSpace: string,
    instanceId: string,
    parameters: {} = {},
    body: {
      email: string;

      segments: Array<{
        _id: string;

        name: string;
      }>;
    },
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/participant-invitation';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#processInstanceParticipantInvitation
   */
  processInstanceParticipantInvitation(
    appSpace: string,
    instanceId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/participant-invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#updateInstanceDocument
   */
  updateInstanceDocument(
    appSpace: string,
    instanceId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
    body: {},
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/segments/{segmentId}/documents/{documentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#updateInstanceDocumentStatus
   */
  updateInstanceDocumentStatus(
    appSpace: string,
    instanceId: string,
    segmentId: string,
    documentId: string,
    parameters: {} = {},
    body: {},
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/segments/{segmentId}/documents/{documentId}/status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getDocumentFile
   */
  getDocumentFile(
    appSpace: string,
    instanceId: string,
    segmentId: string,
    documentId: string,
    fileId: string,
    parameters: {} = {},
  ): Promise<{
    downloadId?: string;
  }> {
    let path =
      '/admin/{appSpace}/instances/{instanceId}/segments/{segmentId}/documents/{documentId}/file/{fileId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{instanceId}', instanceId.toString());

    path = path.replace('{segmentId}', segmentId.toString());

    path = path.replace('{documentId}', documentId.toString());

    path = path.replace('{fileId}', fileId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Operation access for current user and appSpace
   * @method
   * @name Api#getAcl
   */
  getAcl(appSpace: string, parameters: {} = {}): Promise<{}> {
    let path = '/admin/{appSpace}/acl';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getInvitationById
   */
  getInvitationById(
    instanceId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path = '/invitations/{instanceId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#changeInvitationStatus
   */
  changeInvitationStatus(
    instanceId: string,
    parameters: {} = {},
    body: {
      status: 'OPENED' | 'STORNO';
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    procedureId: string;

    createdByAppSpace: {
      _id: string;

      name?: string;

      organization_id?: string;

      organization_name?: string;
    };

    description?: Array<{}>;

    appSpaceInfo: {
      _id: string;

      name: string;

      organization_id: string;

      organization_name: string;
    };

    name: string;

    dateTo: string;

    status: 'INVITED' | 'OPENED' | 'CLOSED' | 'STORNO';

    userId: string;

    delegates?: Array<{
      _id: string;

      name: string;

      surname: string;

      titles?: {};

      segments?: Array<{
        _id: string;

        name: string;
      }>;
    }>;

    auditors?: Array<{
      sportnetId: string;

      displayName: string;
    }>;

    user: {
      _id: string;

      name: string;

      surname: string;

      titles?: {
        before?: string;

        after?: string;
      };
    };

    report?: {
      note: string;

      reportStatus?: string;

      files?: Array<{
        mimetype: string;

        name: string;

        size: number;

        fileid: string;

        filepath: string;
      }>;
    };

    lastChanged?: string;

    segments?: Array<{
      _id?: string;

      name?: string;

      report?: {
        note?: string;

        reportStatus?: string;

        files?: Array<{
          mimetype: string;

          name: string;

          size: number;

          fileid: string;

          filepath: string;
        }>;
      };

      administratedBy?: {
        groups?: Array<string>;
      };

      documents?: Array<{
        _id: string;

        report?: {
          note: string;

          reportStatus?: string;

          files?: Array<{
            mimetype: string;

            name: string;

            size: number;

            fileid: string;

            filepath: string;
          }>;
        };

        name: string;

        type: string;

        status: string;

        files?: {};

        log?: Array<{
          name: string;

          userId?: string;

          user?: {
            _id: string;

            name: string;

            surname: string;
          };

          date?: string;

          description?: string;

          prevState?: {};

          newState?: {};
        }>;
      }>;
    }>;
  }> {
    let path = '/invitations/{instanceId}/status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{instanceId}', instanceId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getDownloaderFile
   */
  getDownloaderFile(downloadId: string, parameters: {} = {}): Promise<void> {
    let path = '/downloader/{downloadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{downloadId}', downloadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new Api();
export { Api };
