import * as React from 'react';
import { FileObjectDefinition } from './definitions';
import { Loader } from '@sportnet/ui/Loader';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import styled, { css, withTheme } from 'styled-components';

const Title = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LoaderWrapper = styled.div`
  padding-left: 20px;
`;

const Name = styled(Title)<{ removed?: boolean }>`
  font-size: ${rem(14)};
  display: flex;
  ${({ removed }) =>
    removed &&
    css`
      text-decoration: line-through;
    `}
`;

const ButtoWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const Wrapper = styled('div')<{ clickable?: boolean; isNew?: boolean }>`
  position: relative;
  padding: ${rem(8)} ${rem(10)};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};
  ${({ isNew }) =>
    isNew &&
    css`
      background: #ebf1ff;
    `}
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  .sportnetui-btn {
    padding: 0 ${rem(7)};
    font-size: ${rem(8)};
    min-height: ${rem(22)};
    .icon {
      height: ${rem(8)};
      width: ${rem(8)};
    }
  }
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  min-height: ${rem(45)};
  align-items: center;
`;

interface OwnProps {
  file: FileObjectDefinition;
  onDelete?: (e: Event) => void;
  onClick?: () => void;
}

type Props = OwnProps & { theme: Theme };

const FileLine: React.FC<Props> = ({ file, onDelete, onClick, theme }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  return (
    <Wrapper
      isNew={!file.fileid}
      onClick={async () => {
        if (onClick) {
          setIsFetching(true);
          await onClick();
          setIsFetching(false);
        }
      }}
      clickable={!!onClick}
    >
      <Name removed={!!file.removed}>
        {file.name || ''}{' '}
        {isFetching && (
          <LoaderWrapper>
            <Loader size="xs" />
          </LoaderWrapper>
        )}
      </Name>
      {onDelete && (!file.removed || !file.fileid) && (
        <ButtoWrapper>
          <Button danger icon="close" onClick={onDelete as any} />
        </ButtoWrapper>
      )}
    </Wrapper>
  );
};

export default withTheme(FileLine);
