import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Field, isSubmitting, reduxForm, submit } from 'redux-form';
import { ModalActions } from '@sportnet/ui/Modal';
import { RootState } from '../../../../rootReducer';
import { __, required } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { extendProcedure } from '../actions';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';

export const FORM_NAME = 'EXTEND_TERM_FORM';

export type IFormData = {
  dateTo: string;
};

type OwnProps = {
  handleClose: () => void;
  data: {
    dateTo: string;
  };
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  initialValues: ownProps.data,
  isSubmitting: isSubmitting(FORM_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  };

const ExtendTermForm: React.FC<Props> = ({
  dispatch,
  isSubmitting,
  handleClose,
}) => {
  const handleSave = () => {
    dispatch(submit(FORM_NAME));
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Field
            component={FormField}
            label={__('Dátum ukončenia')}
            name="dateTo"
            type="date"
            required
            validate={[required]}
          />
        </Col>
      </Row>
      <ModalActions>
        <div>&nbsp;</div>
        <div>
          <Button secondary type="button" onClick={handleClose}>
            {__('Zavrieť')}
          </Button>
          <Button
            loading={isSubmitting}
            primary
            type="button"
            onClick={handleSave}
          >
            {__('Uložiť')}
          </Button>
        </div>
      </ModalActions>
    </React.Fragment>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: false,
    form: FORM_NAME,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      await props.dispatch(extendProcedure.action());
      props.handleClose();
    },
  })
)(ExtendTermForm);
