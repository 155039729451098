import { FORM_NAME, segmentFormNameFactory } from '.';
import { RootState } from '../../../rootReducer';
import { createSelector } from 'reselect';
import { documentFormNameFactory } from './forms/documentForm';
import { isDirty, isValid } from 'redux-form';

const domain = (state: RootState) => {
  return state.procedureDetail;
};

const wholeState = (state: RootState) => {
  return state;
};

// Procedures
export const detailSelector = createSelector(
  domain,
  (substate) => substate.data
);

export const detailIsFetchingSelector = createSelector(
  domain,
  (substate) => substate.isFetching
);

export const detailIsReorderingSelector = createSelector(
  domain,
  (substate) => substate.isReordering
);

// Segments
export const segmentsSelector = createSelector(
  domain,
  (substate) => substate.segments
);

export const segmentDomainByIdSelector = (id: string) =>
  createSelector(segmentsSelector, (substate) =>
    substate.find((seg) => seg._generatedId === id)
  );

// Documents
export const segmentDocumentsSelector = (generatedSegmentId: string) =>
  createSelector(segmentDomainByIdSelector(generatedSegmentId), (substate) =>
    substate ? substate.documents : []
  );

export const segmentDocumentDomainByDocumentIdAnySegmentIdSelector = (
  generatedSegmentId: string,
  generatedDocumentId: string
) =>
  createSelector(segmentDocumentsSelector(generatedSegmentId), (substate) =>
    substate.find((seg) => seg._generatedId === generatedDocumentId)
  );

// General
export const allFormsSelector = createSelector(domain, (substate) => {
  const segments: Array<{ _id: string; form: string }> = [];
  const documents: Array<{
    _id: string;
    _segmentId: string;
    form: string;
  }> = [];

  substate.segments.forEach((seg) => {
    segments.push({
      _id: seg._generatedId,
      form: segmentFormNameFactory(seg._generatedId),
    });
    seg.documents.forEach((doc) => {
      documents.push({
        _id: doc._generatedId,
        _segmentId: seg._generatedId,
        form: documentFormNameFactory(doc._generatedId),
      });
    });
  });

  return {
    procedure: FORM_NAME,
    segments,
    documents,
  };
});

export const dirtyFormsSelector = createSelector(
  allFormsSelector,
  wholeState,
  (substate, state) => ({
    procedure: isDirty(substate.procedure)(state) ? substate.procedure : null,
    segments: substate.segments.filter((seg) => isDirty(seg.form)(state)),
    documents: substate.documents.filter((doc) => isDirty(doc.form)(state)),
  })
);

export const isSomeFormDirtySelector = createSelector(
  dirtyFormsSelector,
  (substate) =>
    substate.procedure !== null ||
    substate.segments.length > 0 ||
    substate.documents.length > 0
);

export const validAndDirtyFormsSelector = createSelector(
  dirtyFormsSelector,
  wholeState,
  (substate, state) => ({
    procedure: substate.procedure
      ? isValid(substate.procedure)(state)
        ? substate.procedure
        : null
      : null,
    segments: substate.segments.filter((seg) => isValid(seg.form)(state)),
    documents: substate.documents.filter((doc) => isValid(doc.form)(state)),
  })
);

export const isSomethingFetchingSelector = createSelector(
  domain,
  (substate) => {
    if (substate.isFetching) {
      return true;
    }
    for (const seg of substate.segments) {
      if (seg.isFetching) {
        return true;
      }
      for (const doc of seg.documents) {
        if (doc.isFetching) {
          return true;
        }
      }
    }
    return false;
  }
);
