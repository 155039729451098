import * as React from 'react';
import { CustomThunkDispatch } from 'configureStore';
import { RootState } from 'rootReducer';
import { Route, Routes } from 'react-router-dom';
import { activeAppspaceIdSelector } from './selectors';
import { connect } from 'react-redux';
import { getAcl } from './actions';
import Authorization from '../../authorization';
import InvitationAuthorization from '../../invitationAuthorization';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

const mapStateToProps = (state: RootState) => ({
  activeAppSpace: activeAppspaceIdSelector(state),
});

type Props = {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps>;

const App: React.FC<Props> = ({ dispatch, activeAppSpace }) => {
  React.useEffect(() => {
    if (activeAppSpace) {
      dispatch(getAcl.action(activeAppSpace));
    }
  }, [activeAppSpace, dispatch]);

  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Authorization />} />
        <Route
          path="/invitation/:instanceId"
          element={<InvitationAuthorization />}
        />
        <Route path="/admin/:appspace/*" element={<Authorization />} />
      </Routes>
    </Wrapper>
  );
};

export default connect(mapStateToProps)(App);
