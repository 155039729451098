import {
  FORM_NAME as EXTEND_TERM_FORM_NAME,
  IFormData as ExtendTermIFormData,
} from './forms/extendTermForm';
import { ExtraArgumentType } from '../../../configureStore';
import {
  FORM_NAME,
  IFormData as IProcedureFormData,
  segmentFormNameFactory,
} from './';
import {
  IFormData as IDocumentFormData,
  documentFormNameFactory,
} from './forms/documentForm';
import {
  IFormData as IParticipantFormData,
  PARTICIPANT_FORM_NAME,
} from './forms/attendantForm';
import { IFormData as ISegmentFormData } from './forms/segmentForm';
import { Procedure } from './../../../library/Procedure';
import {
  ProcedureSegment,
  ProcedureSegmentDocument,
} from '../../../library/Procedure';
import { RootState } from '../../../rootReducer';
import { User } from '../../../library/App';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { change, getFormValues, isDirty } from 'redux-form';
import {
  detailSelector,
  segmentDocumentDomainByDocumentIdAnySegmentIdSelector,
  segmentDomainByIdSelector,
  validAndDirtyFormsSelector,
} from './selectors';
import { v1 as uuid } from 'uuid';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../../config';

const create = actionCreatorFactory(config.PROCEDURES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadProcedureById = createAsync<
  {
    id: string;
  },
  Procedure
>('GET_PROCEDURE', async (parameters, dispatch, getState, { Api }) => {
  const appspace = activeAppspaceIdSelector(getState());
  const procedure = await Api.getProcedureById(appspace, parameters.id);
  const { documents: shares } = await Api.getProcedureShares(
    appspace,
    parameters.id
  );
  return { ...procedure, shares };
});

export const createProcedure = createAsync<void, Procedure>(
  'CREATE_PROCEDURE',
  async (parameters, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const formData = getFormValues(FORM_NAME)(getState()) as IProcedureFormData;

    const data = {
      name: formData.name,
      dateTo: formData.dateTo,
      description: formData.description || [],
    };

    return await Api.postProcedure(appspace, undefined, data);
  }
);

export const extendProcedure = createAsync<void, Procedure>(
  'EXTEND_PROCEDURE',
  async (parameters, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const procedure = detailSelector(getState());
    const formData = getFormValues(EXTEND_TERM_FORM_NAME)(
      getState()
    ) as ExtendTermIFormData;
    if (procedure) {
      return await Api.updateProcedure(appspace, procedure._id, undefined, {
        dateTo: formData.dateTo,
      });
    }
    throw new Error('No procedure found!');
  }
);

export const saveProcedure = createAsync<void, Procedure>(
  'SAVE_PROCEDURE',
  async (values, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const procedure = detailSelector(getState());

    const formData = (getFormValues(FORM_NAME)(getState()) ||
      values) as IProcedureFormData;

    const data = {
      name: formData.name,
      dateTo: formData.dateTo,
      description: formData.description || [],
    };

    if (procedure) {
      return await Api.updateProcedure(
        appspace,
        procedure._id,
        undefined,
        data
      );
    }
    throw new Error('No procedure found!');
  }
);

export const handleStatusChange = createAsync(
  'PROCEDURE_STATUS_CHANGE',
  async (
    parameters: { status: 'OPENED' | 'CLOSED' },
    dispatch,
    getState,
    { Api }
  ) => {
    const { status } = parameters;
    const appspace = activeAppspaceIdSelector(getState());
    const procedure = detailSelector(getState());
    if (procedure) {
      return await Api.changeProcedureStatus(
        appspace,
        procedure._id,
        undefined,
        { status }
      );
    }
    throw new Error('No procedure found!');
  }
);

export const addParticipantToProcedure = createAsync(
  'ADD_PARTICIPANT',
  async (parameters: { user: User }, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    let procedure = detailSelector(getState());
    const formData = getFormValues(PARTICIPANT_FORM_NAME)(
      getState()
    ) as IParticipantFormData;
    if (!procedure) {
      throw new Error('Procedure not found');
    }
    const data = {
      ppo: formData.ppo,
      user: parameters.user,
    };
    procedure = await Api.addParticipantToProcedure(
      appspace,
      procedure._id,
      {},
      data
    );
    return procedure;
  }
);

export const removeParticipantFromProcedure = createAsync(
  'REMOVE_PARTICIPANT',
  async (parameters: { ppo: string }, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    let procedure = detailSelector(getState());
    if (!procedure) {
      throw new Error('Procedure not found');
    }
    procedure = await Api.removeParticipantFromProcedure(
      appspace,
      procedure._id,
      parameters.ppo
    );
    return procedure;
  }
);

export const saveAll = createAsync<void, void>(
  'SAVE_ALL',
  async (values, dispatch, getState, { Api }) => {
    const forms = validAndDirtyFormsSelector(getState());

    const promises: Array<Promise<any>> = [];

    if (forms.procedure) {
      promises.push(dispatch(saveProcedure.action(values)));
    }
    forms.segments.forEach((seg) => {
      promises.push(dispatch(saveSegment.action(seg._id)));
    });
    forms.documents.forEach((doc) => {
      promises.push(
        dispatch(
          saveDocument.action({
            generatedDocumentId: doc._id,
            generatedSegmentId: doc._segmentId,
          })
        )
      );
    });

    await Promise.all(promises.map((p) => p.catch((err) => err))); // obchadzame fail-fast chovanie
  }
);

export const saveSegment = createAsync<string, ProcedureSegment, Error>(
  'SAVE_SEGMENT',
  async (segmentId, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const segment = segmentDomainByIdSelector(segmentId)(getState());
    const procedure = detailSelector(getState());
    if (!segment || !procedure) {
      throw new Error('No segment or procedure found!');
    }

    const segmentForm = segmentFormNameFactory(segmentId);
    if (!isDirty(segmentForm)(getState())) {
      throw new Error('SEGMENT_NOT_CHANGED');
    }

    const formData = getFormValues(segmentForm)(getState()) as ISegmentFormData;

    const data = {
      name: formData.name,
      administratedBy: {
        groups: formData.administratedBy || [],
      },
    };

    if (segment.data._id) {
      // update
      return await Api.updateProcedureSegment(
        appspace,
        procedure._id,
        segment.data._id,
        undefined,
        data
      );
    }
    // new
    return await Api.postProcedureSegment(
      appspace,
      procedure._id,
      undefined,
      data
    );
  }
);

export const addSegment = createAsync<void, string>('ADD_SEGMENT', () => {
  return uuid();
});

export const addSegmentAndSave = createAsync<void, ProcedureSegment>(
  'ADD_SEGMENT_AND_SAVE',
  async (parameters, dispatch, getState, { Api }) => {
    const newId = await dispatch(addSegment.action());
    dispatch(change(segmentFormNameFactory(newId), 'name', 'Časť'));
    return await dispatch(saveSegment.action(newId));
  }
);

export const deleteSegment = createAsync<string, void>(
  'DELETE_SEGMENT',
  async (segmentId, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const segment = segmentDomainByIdSelector(segmentId)(getState());
    const procedure = detailSelector(getState());
    if (!segment || !procedure) {
      throw new Error('No segment or procedure found!');
    }
    if (segment.data._id) {
      // already existing segment
      await Api.deleteProcedureSegment(
        appspace,
        procedure._id,
        segment.data._id
      );
    }
  }
);

export const saveDocument = createAsync<
  { generatedSegmentId: string; generatedDocumentId: string },
  ProcedureSegmentDocument,
  Error
>('SAVE_DOCUMENT', async (params, dispatch, getState, { Api }) => {
  const appspace = activeAppspaceIdSelector(getState());
  const segment = segmentDomainByIdSelector(params.generatedSegmentId)(
    getState()
  );
  const procedure = detailSelector(getState());
  const document = segmentDocumentDomainByDocumentIdAnySegmentIdSelector(
    params.generatedSegmentId,
    params.generatedDocumentId
  )(getState());
  if (!segment || !procedure || !document) {
    throw new Error('No segment, procedure or document found!');
  }

  const documentForm = documentFormNameFactory(params.generatedDocumentId);
  if (!isDirty(documentForm)(getState())) {
    throw new Error('DOCUMENT_NOT_CHANGED');
  }

  const formData = getFormValues(documentForm)(getState()) as IDocumentFormData;

  const data = {
    name: formData.name,
    type: 'upload',
    description: formData.description,
    dateTo: formData.dateTo || undefined,
  } as {
    name: string;
    type: 'upload';
  };

  if (document.data._id) {
    // update
    return await Api.updateSegmentDocument(
      appspace,
      procedure._id,
      segment.data._id,
      document.data._id,
      undefined,
      data
    );
  }
  // new
  return await Api.postSegmentDocument(
    appspace,
    procedure._id,
    segment.data._id,
    undefined,
    data
  );
});

export const addDocument = createAsync<{ generatedSegmentId: string }, string>(
  'ADD_DOCUMENT',
  () => {
    return uuid();
  }
);

export const addDocumentAndSave = createAsync<
  { generatedSegmentId: string },
  ProcedureSegmentDocument
>('ADD_DOCUMENT_AND_SAVE', async (params, dispatch, getState, { Api }) => {
  const newId = await dispatch(
    addDocument.action({ generatedSegmentId: params.generatedSegmentId })
  );
  dispatch(change(documentFormNameFactory(newId), 'name', 'Dokument'));
  return dispatch(
    saveDocument.action({
      generatedDocumentId: newId,
      generatedSegmentId: params.generatedSegmentId,
    })
  );
});

export const deleteDocument = createAsync<
  { generatedSegmentId: string; generatedDocumentId: string },
  void
>('DELETE_DOCUMENT', async (params, dispatch, getState, { Api }) => {
  const appspace = activeAppspaceIdSelector(getState());
  const segment = segmentDomainByIdSelector(params.generatedSegmentId)(
    getState()
  );
  const document = segmentDocumentDomainByDocumentIdAnySegmentIdSelector(
    params.generatedSegmentId,
    params.generatedDocumentId
  )(getState());
  const procedure = detailSelector(getState());
  if (!segment || !procedure || !document) {
    throw new Error('No segment, procedure or document found!');
  }
  if (document.data._id) {
    // already existing segment
    await Api.deleteSegmentDocument(
      appspace,
      procedure._id,
      segment.data._id,
      document.data._id
    );
  }
});

export const updateSegmentDocumentsOrder = createAsync<
  { segmentId: string; orderedDocumentIds: string[] },
  void
>(
  'UPDATE_SEGMENT_DOCUMENTS_ORDER',
  async ({ segmentId, orderedDocumentIds }, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const segment = segmentDomainByIdSelector(segmentId)(getState());
    const procedure = detailSelector(getState());
    if (!segment || !procedure) {
      throw new Error('No segment or procedure found!');
    }
    if (segment.data._id) {
      await Api.updateSegmentDocumentsOrder(
        appspace,
        procedure._id,
        segment.data._id,
        undefined,
        {
          orderedDocumentIds,
        }
      ).catch((e) => {
        alert(`Nepodarilo sa zmeniť poradie: ${e.message}`);
      });
    }
  }
);

export const updateSegmentsOrder = createAsync<string[], void>(
  'UPDATE_SEGMENTS_ORDER',
  async (orderedSegmentIds, dispatch, getState, { Api }) => {
    const appspace = activeAppspaceIdSelector(getState());
    const procedure = detailSelector(getState());
    if (!procedure) {
      throw new Error('No procedure found!');
    }
    await Api.updateSegmentsOrder(appspace, procedure._id, undefined, {
      orderedSegmentIds,
    }).catch((e) => {
      alert(`Nepodarilo sa zmeniť poradie: ${e.message}`);
    });
  }
);
