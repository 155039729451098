import * as React from 'react';
import { Instance } from '../../../library/Instance';
import { RootState } from '../../../rootReducer';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector } from '../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format, formatDistance } from 'date-fns';
import { rem } from 'polished';
import { useNavigate } from 'react-router-dom';
import { warningColor } from '../Detail/Forms/ProcedureInstanceSegmentForm';
import { withTheme } from 'styled-components';
import BasicTable from '@sportnet/ui/BasicTable';
import DotLabel from '@sportnet/ui/Label/DotLabel';
import Segment from '@sportnet/ui/Segment';
import formatUserName from '@sportnet/utilities/formatUserName';
import getProp from '@sportnet/utilities/getProp';
import skLocale from 'date-fns/locale/sk';
import styled from 'styled-components';

const UserName = styled.i`
  font-size: ${rem(12)};
`;

type OwnProps = {
  items: Instance[];
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  appSpace: activeAppspaceIdSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps & { theme: Theme };

const InstancesListTable: React.FC<Props> = ({ theme, items, appSpace }) => {
  const navigate = useNavigate();

  const getStatus = (instance: Instance) => {
    switch (instance.status) {
      case 'INVITED':
        return (
          <DotLabel color={theme.color.primary}>
            {__('Čaká na prijatie')}
          </DotLabel>
        );
      case 'OPENED':
        return <DotLabel color={warningColor}>{__('Prebieha')}</DotLabel>;
      case 'STORNO':
        return <DotLabel color={theme.color.danger}>{__('Zrušené')}</DotLabel>;
      case 'CLOSED':
        return (
          <DotLabel color={theme.color.success}>{__('Uzatvorené')}</DotLabel>
        );
      case 'FAILED':
        return (
          <DotLabel color={theme.color.danger}>{__('Uzatvorené')}</DotLabel>
        );
      default:
        throw new Error('UNKNOWN_INSTANCE_STATUS');
    }
  };

  return (
    <Segment>
      <Segment raised>
        <BasicTable
          columns={[
            { header: __('Názov') },
            { header: __('Organizátor') },
            { header: __('Účastník') },
            { header: __('Stav') },
            { header: __('Termín') },
            { header: __('Posledná zmena') },
          ]}
          rows={items}
          onClickRow={(item: Instance) =>
            navigate(`/admin/${appSpace}/instances/${item._id}`)
          }
          renderRow={(item: Instance) => [
            item.name,
            item.createdByAppSpace.name,
            <>
              {getProp(item, ['appSpaceInfo', 'name'], '')}
              <br />
              <UserName>
                {formatUserName({
                  name: item.user.name,
                  surname: item.user.surname,
                  titles: {
                    before: getProp(item, ['user', 'titles', 'before'], ''),
                    after: getProp(item, ['user', 'titles', 'after'], ''),
                  },
                })}
              </UserName>
            </>,
            getStatus(item),
            format(new Date(item.dateTo), 'd.M.yyyy'),
            item.lastChanged &&
              formatDistance(new Date(item.lastChanged), new Date(), {
                locale: skLocale,
                addSuffix: true,
              }),
          ]}
          rowKey="_id"
        />
      </Segment>
    </Segment>
  );
};

export default compose(connect(mapStateToProps), withTheme)(InstancesListTable);
