import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { InstanceOptions } from './InstanceForm';
import {
  ProcedureInstanceSegment,
  ProcedureInstanceSegmentDocument,
} from '../../../../library/Instance';
import { RootState } from '../../../../rootReducer';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { __ } from '../../../../utilities';
import {
  activeAppspaceIdSelector,
  activeAppspaceSelector,
} from '../../../../containers/App/selectors';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';
import { procedureInstanceDetailSelector } from '../../selectors';
import { theme } from '../../../../theme/theme';
import Button from '@sportnet/ui/Button';
import DotLabel from '@sportnet/ui/Label/DotLabel';
import Header from '@sportnet/ui/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import ProcedureInstanceDocumentForm, {
  StatusChange,
} from './ProcedureInstanceDocumentForm';
import ProcedureInstanceSegmentReportForm from './ProcedureInstanceSegmentReportForm';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import styled, { withTheme } from 'styled-components';

export const warningColor = '#e7b952';

const SegmentReportButton = styled.div`
  margin: 8px 0px 16px 0px;
  padding: 8px 0;
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const getSegmentFormName = (segmentId: string) => `SEGMENT_${segmentId}_REPORT`;

interface OwnProps {
  segment: ProcedureInstanceSegment;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  instance: procedureInstanceDetailSelector(state),
  appSpace: activeAppspaceIdSelector(state),
  isCommiting: isSubmitting(getSegmentFormName(props.segment._id))(state),
  isAuditor: activeAppspaceSelector(state)?.role === 'auditor',
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps &
  InstanceOptions & { theme: Theme } & { dispatch: CustomThunkDispatch };

const ProcedureInstanceSegmentForm: React.FC<Props> = ({
  dispatch,
  isCommiting,
  isParticipant,
  isOwner,
  isAuditor,
  instance,
  appSpace,
  segment,
}) => {
  const [modalOpened, setModalOpened] = React.useState(false);

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    });
  }, []);

  const getAcceptedState = () => {
    let allAccepted = true;
    (segment.documents || []).forEach((document: any) => {
      if (document.status !== 'ACCEPTED') {
        allAccepted = false;
      }
    });
    return allAccepted;
  };

  const getStatus = () => {
    const allAccepted = getAcceptedState();

    let isOwner = false;
    if (instance && instance.createdByAppSpace._id === appSpace) {
      isOwner = true;
    }
    let color = allAccepted ? theme.color.success : warningColor;
    if (!allAccepted && segment.report) {
      color = theme.color.danger;
    }
    if (allAccepted && segment.report) {
      return (
        <DotLabel color={color}>
          <>
            {__('Schválené')}{' '}
            <StatusChange onClick={handleOpen}>
              {__('Zobraziť záverečnú správu')}
            </StatusChange>
          </>
        </DotLabel>
      );
    } else if (allAccepted && !segment.report && isOwner && !isAuditor) {
      return (
        <DotLabel color={color}>
          <>
            {__('Schválené')}{' '}
            <StatusChange onClick={handleOpen}>
              {__('Pridať záverečnú správu')}
            </StatusChange>
          </>
        </DotLabel>
      );
    } else if (allAccepted && !segment.report) {
      return <DotLabel color={color}>{__('Schválené')}</DotLabel>;
    } else if (!allAccepted && !segment.report && isOwner && !isAuditor) {
      return (
        <DotLabel color={color}>
          <>
            {__('Neschválené')}{' '}
            <StatusChange onClick={handleOpen}>
              {__('Pridať záverečnú správu')}
            </StatusChange>
          </>
        </DotLabel>
      );
    } else if (!allAccepted && segment.report) {
      return (
        <DotLabel color={color}>
          <>
            {__('Nesplnené')}{' '}
            <StatusChange onClick={handleOpen}>
              {__('Zobraziť záverečnú správu')}
            </StatusChange>
          </>
        </DotLabel>
      );
    } else if (!allAccepted) {
      return <DotLabel color={color}>{__('Neschválené')}</DotLabel>;
    }
    return '';
  };

  const handleOpen = () => {
    setModalOpened(true);
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  const renderDocument = (document: ProcedureInstanceSegmentDocument) => {
    return (
      <div id={`document_${document._id}`} key={`document_${document._id}`}>
        <ProcedureInstanceDocumentForm
          segment={segment}
          document={document}
          isParticipant={isParticipant}
          isOwner={isOwner}
          isAuditor={isAuditor}
        />
      </div>
    );
  };

  const openSegmentReport = () => {
    if (instance) {
      window.open(
        `/admin/${appSpace}/instances/${instance._id}/segments/${segment._id}/report`
      );
    }
  };

  const localIsOwner = instance && instance.createdByAppSpace._id === appSpace;

  const FORM_NAME = getSegmentFormName(segment._id);
  return (
    <div id={`segment_${segment._id}`}>
      <Segment
        raised
        header={
          <SegmentHeader
            size="s"
            subtitle={getStatus()}
            withSeparator
            collapsible
            isCollapsed={getAcceptedState()}
          >
            {segment.name}
          </SegmentHeader>
        }
      >
        {(segment.documents || []).map(renderDocument)}
      </Segment>
      <Modal isOpen={modalOpened} handleClose={handleClose}>
        <ModalContent>
          <Header size="s" withSeparator>
            {__('Záverečná správa pre časť ')} {segment.name}
          </Header>
          <SegmentReportButton onClick={openSegmentReport}>
            {__('Správa odborníka o preverení dokumentácie')}
          </SegmentReportButton>
          <ProcedureInstanceSegmentReportForm
            initialValues={segment.report || {}}
            segment={segment}
            form={FORM_NAME}
            handleClose={handleClose}
            readonly={isAuditor}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button secondary type="button" onClick={handleClose}>
              {__('Zavrieť')}
            </Button>
            {localIsOwner && !isAuditor && (
              <Button
                primary
                loading={isCommiting}
                type="button"
                onClick={() => {
                  dispatch(submit(FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            )}
          </div>
        </ModalActions>
      </Modal>
    </div>
  );
};

export default withTheme(
  connect(mapStateToProps)(ProcedureInstanceSegmentForm)
);
