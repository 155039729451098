import * as React from 'react';
import {
  CommitError,
  commit,
  getListResults,
  getListTotal,
  initialize,
  isCommiting,
} from '@sportnet/redux-list';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Instance } from '../../../../library/Instance';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { RootState } from '../../../../rootReducer';
import { __ } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../../../containers/App/actions';
import InstancesListTable from '..';
import Layout from '../../../../containers/Layout';
import api from '../../../../api';
import useQuery, {
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';

const LIST_NAME = 'INSTANCES_LIST';

const mapStateToProps = (state: RootState) => ({
  appSpace: activeAppspaceIdSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
  instances: (getListResults(LIST_NAME)(state) || []) as Instance[],
  total: getListTotal(LIST_NAME)(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & { dispatch: CustomThunkDispatch };

const prepareSearchString = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    return `${acc}&${key}=${value}`;
  }, '?');
};

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    procedure: StringParam(''),
  },
};

const ProcedureInstances: React.FC<Props> = ({
  instances,
  isFetching,
  appSpace,
  dispatch,
}) => {
  const { id = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    setQuery,
    query: { procedure },
    serializedQuery,
  } = useQuery(
    prepareSearchString(searchParams),
    (serializedQuery) => navigate(`${location.pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG
  );

  const getProcedureInstances = React.useCallback(
    async (serializedQuery: string) => {
      if (procedure && procedure.length) {
        return dispatch(
          commit.action({
            listName: LIST_NAME,
            load: async () => {
              try {
                const { items, total } = await api.getProcedureInstances(
                  appSpace,
                  procedure
                );
                return {
                  total,
                  results: items,
                };
              } catch (e) {
                throw new CommitError();
              }
            },
          })
        );
      }
    },
    [appSpace, dispatch, procedure]
  );

  React.useEffect(() => {
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          offset: 0,
          procedure: id,
        },
      })
    );
    setQuery({ procedure: id });
    dispatch(
      setBreadcrumbs([
        <Link key="root" to="#">
          {__('Licenčné konania')}
        </Link>,
      ])
    );
    getProcedureInstances(serializedQuery);
  }, [serializedQuery, dispatch, getProcedureInstances, id, setQuery]);

  return (
    <Layout isLoading={isFetching}>
      <InstancesListTable items={instances} />
    </Layout>
  );
};

export default compose(connect(mapStateToProps))(ProcedureInstances);
