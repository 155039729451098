import {
  ApplicationInfo,
  Appspace,
  AuthorizationResponse,
} from '../../library/App';
import { ExtraArgumentType } from '../../configureStore';
import { IAppPublic } from '@sportnet/ui/Authorization/types';
import { RootState } from '../../rootReducer';
import { User_Group } from '../../sportnetApi';
import { activeAppspaceIdSelector } from './selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.APPLICATION_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setAppspaces = create<Appspace[]>('SET_APPSPACES');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const setAuthUser = create<AuthorizationResponse>('SET_AUTH_USER');

export const setApplicationInfo = create<ApplicationInfo>(
  'SET_APPLICATION_INFO'
);

export const setBreadcrumbs =
  create<Array<JSX.Element | string>>('SET_BREADCRUMBS');

export const loadUserGroups = createAsync<void, { groups?: User_Group[] }>(
  'LOAD_USER_GROUPS',
  async (parameters, dispatch, getState, { SportnetApi }) => {
    const appSpace = activeAppspaceIdSelector(getState()) as any;
    const result = await SportnetApi.getPublicAppSpaceGroups(appSpace);
    return result;
  }
);

export const getAcl = createAsync<string, { [key: string]: boolean }>(
  'GET_ACL',
  async (appSpace, dispatch, getState, { Api }) => {
    return Api.getAcl(appSpace);
  }
);

export const setApps = create<IAppPublic[]>('SET_APPS');
