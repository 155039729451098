import {
  IDocumentLogEntryState,
  IUploadDocumentLog,
  IUploadDocumentLogEntryState,
} from '../../library/DocumentLog';
import { isEqual } from 'date-fns';
import getNewFilesFromLogEntry from './getNewFilesFromLogEntry';

interface EventStatusChanged {
  type: 'STATUS_CHANGED';
  payload: {
    date: string;
    user: { _id: string; name: string; surname: string };
    states: [
      IDocumentLogEntryState['status'],
      IDocumentLogEntryState['status']
    ];
    note?: string;
  };
}

interface EventCommentAdded {
  type: 'COMMENT_ADDED';
  payload: {
    flag?: 'AFTER_ACCEPTATION' | 'AFTER_FAILURE' | 'AFTER_DEADLINE';
    date: string;
    user: { _id: string; name: string; surname: string };
    states: [
      IDocumentLogEntryState['status'],
      IDocumentLogEntryState['status']
    ];
    note?: string;
  };
}

interface EventFilesAdded {
  type: 'FILES_ADDED';
  payload: {
    flag?: 'AFTER_ACCEPTATION' | 'AFTER_FAILURE' | 'AFTER_DEADLINE';
    user: { _id: string; name: string; surname: string };
    date: string;
    files: IUploadDocumentLogEntryState['data']['files'];
  };
}

interface EventFilesShared {
  type: 'FILES_SHARED';
  payload: {
    flag?: 'AFTER_ACCEPTATION' | 'AFTER_FAILURE' | 'AFTER_DEADLINE';
    user: { _id: string; name: string; surname: string };
    date: string;
    files: IUploadDocumentLogEntryState['data']['files'];
    originInstance: { _id: string; name: string };
  };
}

interface EventFilesRemoved {
  type: 'FILES_REMOVED';
  payload: {
    flag?: 'AFTER_ACCEPTATION' | 'AFTER_FAILURE' | 'AFTER_DEADLINE';
    user: { _id: string; name: string; surname: string };
    date: string;
    files: IUploadDocumentLogEntryState['data']['files'];
  };
}

interface EventDateToChanged {
  type: 'DATE_TO_CHANGED';
  payload: {
    user: { _id: string; name: string; surname: string };
    date: string;
    dateTo: string;
  };
}

const generateEvents = (log: IUploadDocumentLog, files: any[] = []) => {
  return log.reduce(
    (
      acc: Array<
        | EventStatusChanged
        | EventCommentAdded
        | EventFilesAdded
        | EventFilesRemoved
        | EventFilesShared
        | EventDateToChanged
      >,
      entry
    ) => {
      if (entry.prevState.status !== entry.newState.status) {
        const obj: EventStatusChanged = {
          type: 'STATUS_CHANGED',
          payload: {
            user: entry.user,
            date: entry.date,
            states: [entry.prevState.status, entry.newState.status],
          },
        };
        if (entry.newState.note) {
          obj.payload.note = entry.newState.note;
        }
        acc.push(obj);
      }

      if (entry.newState.data.note) {
        const obj: EventCommentAdded = {
          type: 'COMMENT_ADDED',
          payload: {
            flag: entry.flag,
            user: entry.user,
            note: entry.newState.data.note,
            date: entry.date,
            states: [entry.prevState.status, entry.newState.status],
          },
        };
        acc.push(obj);
      }

      const newFiles = getNewFilesFromLogEntry(entry);

      if (newFiles.length > 0 && entry.newState.data.originInstance) {
        acc.push({
          type: 'FILES_SHARED',
          payload: {
            flag: entry.flag,
            user: entry.user,
            date: entry.date,
            files: newFiles,
            originInstance: entry.newState.data.originInstance,
          },
        });
      } else if (newFiles.length > 0) {
        acc.push({
          type: 'FILES_ADDED',
          payload: {
            flag: entry.flag,
            user: entry.user,
            date: entry.date,
            files: newFiles,
          },
        });
      } else if ((entry.newState.data.removeFilesIds || []).length) {
        acc.push({
          type: 'FILES_REMOVED',
          payload: {
            flag: entry.flag,
            user: entry.user,
            date: entry.date,
            files: files.filter(i => entry.newState.data.removeFilesIds?.includes(i.fileid)),
          },
        });
      }

      if (
        (entry.newState.dateTo && !entry.prevState.dateTo) ||
        (entry.newState.dateTo &&
          entry.prevState.dateTo &&
          !isEqual(
            new Date(entry.prevState.dateTo),
            new Date(entry.newState.dateTo)
          ))
      ) {
        acc.push({
          type: 'DATE_TO_CHANGED',
          payload: {
            user: entry.user,
            date: entry.date,
            dateTo: entry.newState.dateTo,
          },
        });
      }

      return acc;
    },
    []
  );
};

export default generateEvents;
