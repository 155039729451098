export const __ = (toTranslate: string): string => {
  return toTranslate;
};

export const required = (value: string): string | null => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

export const isValidObjectId = (value: string): string | null => {
  if (value.length === 24) {
    return null;
  }
  return __('Hodnota je neplatná');
};

export const isBrowser = () =>
  typeof window !== 'undefined' &&
  typeof window.location !== 'undefined' &&
  typeof window.document !== 'undefined';
