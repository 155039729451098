import * as React from 'react';
import { IUploadDocumentLog } from '../../library/DocumentLog';
import { Loader } from '@sportnet/ui/Loader';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { differenceInCalendarDays, format, formatDistance } from 'date-fns';
import { withTheme } from 'styled-components';
import Feed from '@sportnet/ui/Feed';
import bytes2Size from '@sportnet/utilities/bytes2Size';
import formatUserName from '@sportnet/utilities/formatUserName';
import generateEvents from './generateEvents';
import skLocale from 'date-fns/locale/sk';
import styled from '../../theme/styled-components';

const Feed2 = Feed as any;
interface OwnProps {
  log: IUploadDocumentLog;
  onOpenFile: (fileId: string) => void;
  files: any[];
}

type Props = OwnProps & { theme: Theme };

const Em = styled.span`
  color: ${({ theme }) => theme.color.primary};
`;

const DangerEm = styled.span`
  color: ${({ theme }) => theme.color.danger};
`;

const LeftPadding = styled.div`
  padding-left: 5px;
`;

const File = styled.div`
  margin-bottom: 0.5em;
`;

const LinkLike = styled.span`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  display: flex;
`;

const StyledExtra = styled(Feed.Extra)`
  font-size: 0.9em;
  padding: 0 0.5em;
`;

const uploadedPlural = (username: string, flag: string, n: number) => {
  if (n === 1) {
    if (!username) {
      return (
        <>
          Nahraný <Em>jeden</Em> nový súbor <DangerEm>{flag}</DangerEm>
        </>
      );
    }
    return (
      <>
        <Em>{username}</Em> nahral <DangerEm>{flag}</DangerEm> <Em>jeden</Em>{' '}
        nový súbor
      </>
    );
  }

  if (n > 1 && n < 5) {
    if (!username) {
      return (
        <>
          Nahrané <Em>{n} nové</Em> súbory <DangerEm>{flag}</DangerEm>
        </>
      );
    }
    return (
      <>
        <Em>{username}</Em> nahral <DangerEm>{flag}</DangerEm> <Em>{n} nové</Em>{' '}
        súbory
      </>
    );
  }

  if (!username) {
    return (
      <>
        Nahraných <Em>{n} nových</Em> súborov <DangerEm>{flag}</DangerEm>
      </>
    );
  }
  return (
    <>
      <Em>{username}</Em> nahral <DangerEm>{flag}</DangerEm> <Em>{n} nových</Em>{' '}
      súborov
    </>
  );
};

const sharedPlural = (
  username: string,
  instanceName: string,
  flag: string,
  n: number
) => {
  if (n === 1) {
    return (
      <>
        Zdieľaný <Em>jeden</Em> nový súbor <DangerEm>{flag}</DangerEm> z konania{' '}
        <Em>{instanceName}</Em>
      </>
    );
  }

  if (n > 1 && n < 5) {
    return (
      <>
        Zdieľané <Em>{n} nové</Em> súbory <DangerEm>{flag}</DangerEm> z konania{' '}
        <Em>{instanceName}</Em>
      </>
    );
  }

  return (
    <>
      Zdieľaných <Em>{n} nových</Em> súborov <DangerEm>{flag}</DangerEm> z
      konania <Em>{instanceName}</Em>
    </>
  );
};

const removedPlural = (username: string, flag: string, n: number) => {
  if (n === 1) {
    if (!username) {
      return (
        <>
          Odstránený <Em>jeden</Em> súbor <DangerEm>{flag}</DangerEm>
        </>
      );
    }
    return (
      <>
        <Em>{username}</Em> odstránil <DangerEm>{flag}</DangerEm> <Em>jeden</Em>{' '}
        súbor
      </>
    );
  }

  if (n > 1 && n < 5) {
    if (!username) {
      return (
        <>
          Odstránené <Em>{n}</Em> súbory <DangerEm>{flag}</DangerEm>
        </>
      );
    }
    return (
      <>
        <Em>{username}</Em> odstránil <DangerEm>{flag}</DangerEm> <Em>{n}</Em>{' '}
        súborov
      </>
    );
  }

  if (!username) {
    return (
      <>
        Odstránených <Em>{n}</Em> súborov <DangerEm>{flag}</DangerEm>
      </>
    );
  }
  return (
    <>
      <Em>{username}</Em> odstránil <DangerEm>{flag}</DangerEm> <Em>{n}</Em>{' '}
      súborov
    </>
  );
};

const DocumentLog: React.FC<Props> = ({ onOpenFile, theme, log, files }) => {
  const [isFetchingFileId, setIsFetchingFileId] = React.useState(null);

  const renderFlagLabel = (flag?: string) => {
    switch (flag) {
      case 'AFTER_ACCEPTATION':
        return 'po schválení';
      case 'AFTER_FAILURE':
        return 'po nesplnení';
      case 'AFTER_DEADLINE':
        return 'po termíne';
      default:
        return '';
    }
  };

  const renderEvent = (
    event: ReturnType<typeof generateEvents>[0],
    idx: number
  ) => {
    if (event.type === 'STATUS_CHANGED') {
      if (event.payload.states[1] === 'OPENED') {
        return null;
        // return this.renderEventHelper(
        //   idx,
        //   <>
        //     Dokument bol <Em>zmenený</Em>
        //   </>,
        //   event.payload.date,
        // );
      } else if (event.payload.states[1] === 'ACCEPTED') {
        return renderEventHelper(
          idx,
          <>
            Dokument bol <Em>akceptovaný</Em>
          </>,
          event.payload.date
        );
      } else if (event.payload.states[1] === 'FAILED') {
        return renderEventHelper(
          idx,
          <>
            Dokument bol <Em>zamietnutý</Em>
            {event.payload.note && ' z nasledujúceho dôvodu'}
          </>,
          event.payload.date,
          event.payload.note
        );
      }
    } else if (event.type === 'COMMENT_ADDED') {
      return renderEventHelper(
        idx,
        <>
          <Em>{formatUserName(event.payload.user)}</Em> pridal{' '}
          {event.payload.flag && (
            <DangerEm>{renderFlagLabel(event.payload.flag)}</DangerEm>
          )}{' '}
          komentár
        </>,
        event.payload.date,
        event.payload.note
      );
    } else if (event.type === 'FILES_SHARED') {
      return renderEventHelper(
        idx,
        sharedPlural(
          event.payload.user ? formatUserName(event.payload.user) : '',
          event.payload.originInstance.name,
          renderFlagLabel(event.payload.flag),
          event.payload.files.length
        ),
        event.payload.date,
        event.payload.files.map((file: any, i: any) => (
          <File key={file.fileid}>
            <LinkLike
              onClick={async () => {
                setIsFetchingFileId(file.fileid);
                await onOpenFile(file.fileid);
                setIsFetchingFileId(null);
              }}
            >
              {file.name} ({bytes2Size(file.size)}){' '}
              {isFetchingFileId === file.fileid && (
                <LeftPadding>
                  <Loader size="xs" />
                </LeftPadding>
              )}
            </LinkLike>
          </File>
        ))
      );
    } else if (event.type === 'FILES_ADDED') {
      return renderEventHelper(
        idx,
        uploadedPlural(
          event.payload.user ? formatUserName(event.payload.user) : '',
          renderFlagLabel(event.payload.flag),
          event.payload.files.length
        ),
        event.payload.date,
        event.payload.files.map((file: any, i: any) => (
          <File key={file.fileid}>
            <LinkLike
              onClick={async () => {
                setIsFetchingFileId(file.fileid);
                await onOpenFile(file.fileid);
                setIsFetchingFileId(null);
              }}
            >
              {file.name} ({bytes2Size(file.size)}){' '}
              {isFetchingFileId === file.fileid && (
                <LeftPadding>
                  <Loader size="xs" />
                </LeftPadding>
              )}
            </LinkLike>
          </File>
        ))
      );
    } else if (event.type === 'FILES_REMOVED') {
      return renderEventHelper(
        idx,
        removedPlural(
          event.payload.user ? formatUserName(event.payload.user) : '',
          renderFlagLabel(event.payload.flag),
          (event.payload.files || []).length
        ),
        event.payload.date,
        (event.payload.files || []).map((file: any, i: any) => (
          <File key={file.fileid}>
            <LinkLike
              onClick={async () => {
                setIsFetchingFileId(file.fileid);
                await onOpenFile(file.fileid);
                setIsFetchingFileId(null);
              }}
            >
              {file.name} ({bytes2Size(file.size)}){' '}
              {isFetchingFileId === file.fileid && (
                <LeftPadding>
                  <Loader size="xs" />
                </LeftPadding>
              )}
            </LinkLike>
          </File>
        ))
      );
    } else if (event.type === 'DATE_TO_CHANGED') {
      return renderEventHelper(
        idx,
        <>
          Zmena termínu odovzdania na{' '}
          <Em>{format(new Date(event.payload.dateTo), 'd.M.yyyy')}</Em>
        </>,
        event.payload.date
      );
    }
    return null;
  };

  const renderEventHelper = (
    key: number,
    summary: React.ReactNode,
    date: string,
    extra: React.ReactNode = null
  ) => {
    const dateDistance = differenceInCalendarDays(new Date(), new Date(date));
    return (
      <Feed2.Event key={key as any}>
        <Feed2.Content>
          <Feed2.Summary>
            {summary}{' '}
            <Feed2.Date
              title={format(new Date(date), 'd.M.yyyy H:mm', {
                locale: skLocale,
              })}
            >
              {dateDistance <= 7
                ? formatDistance(new Date(date), new Date(), {
                    locale: skLocale,
                    addSuffix: true,
                  })
                : format(new Date(date), 'd.M.yyyy H:mm')}
            </Feed2.Date>
          </Feed2.Summary>
          {extra && <StyledExtra>{extra}</StyledExtra>}
        </Feed2.Content>
      </Feed2.Event>
    );
  };

  return <Feed>{generateEvents(log, files).map(renderEvent)}</Feed>;
};

export default withTheme(DocumentLog);
