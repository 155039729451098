import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import { isBrowser } from './utilities';
import Api from './api';
import SportnetApi from './sportnetApi';
import rootReducer, { RootState } from './rootReducer';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';

let composeEnhancers: typeof compose;
if (isBrowser()) {
  const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  composeEnhancers = devToolsCompose
    ? devToolsCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
} else {
  composeEnhancers = compose;
}

const extraArgument = { SportnetApi, Api };
export type ExtraArgumentType = typeof extraArgument;
const thunk = thunkMiddleware.withExtraArgument(extraArgument);

const enhancer = composeEnhancers(applyMiddleware(thunk));

export type CustomThunkAction<R> = (
  dispatch: ThunkDispatch<RootState, ExtraArgumentType, AnyAction>,
  getState: () => RootState,
  extraArgument: ExtraArgumentType
) => R;

export type CustomThunkDispatch = ThunkDispatch<
  RootState,
  ExtraArgumentType,
  AnyAction
>;

export interface ReduxConnectProps {
  dispatch: CustomThunkDispatch;
}

export default function configureStore(initialState?: object) {
  return createStore(rootReducer, initialState || {}, enhancer);
}
