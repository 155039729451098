import * as React from 'react';
import {
  CommitError,
  commit,
  getListResults,
  getListTotal,
  initialize,
  isCommiting,
} from '@sportnet/redux-list';
import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Instance } from '../../../../library/Instance';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { RootState } from '../../../../rootReducer';
import { __ } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../../../containers/App/actions';
import HeaderBar from '@sportnet/ui/HeaderBar';
import InstancesListTable from '..';
import Layout from '../../../../containers/Layout';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import api from '../../../../api';
import config from '../../../../config';
import useQuery, {
  ArrayParam,
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';

const LIST_NAME = 'INSTANCES_LIST';
const LIST_LIMIT = config.DEFAULT_LIST_LIMIT;

const mapStateToProps = (state: RootState) => ({
  appSpace: activeAppspaceIdSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
  instances: (getListResults(LIST_NAME)(state) || []) as Instance[],
  total: getListTotal(LIST_NAME)(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & {
  dispatch: CustomThunkDispatch;
};

const prepareSearchString = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    return `${acc}&${key}=${value}`;
  }, '?');
};

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    statuses: ArrayParam(StringParam(''), [], ','),
  },
};

const ProcedureInstancesList: React.FC<Props> = ({
  isFetching,
  instances,
  total,
  appSpace,
  dispatch,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { query, setQuery } = useQuery(
    prepareSearchString(searchParams),
    (serializedQuery) => navigate(`${location.pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG
  );

  const getInstances = React.useCallback(
    async (currentQuery: typeof query) => {
      return dispatch(
        commit.action({
          listName: LIST_NAME,
          load: async () => {
            try {
              const { items, total } = await api.meInstances(appSpace, {
                offset: currentQuery.offset as number,
                limit: LIST_LIMIT,
                statuses: currentQuery.statuses,
              });
              return {
                total,
                results: items,
              };
            } catch (e) {
              throw new CommitError();
            }
          },
        })
      );
    },
    [appSpace, dispatch]
  );

  React.useEffect(() => {
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          offset: 0,
          statuses: [],
        },
      })
    );
    dispatch(
      setBreadcrumbs([
        <Link key="root" to="#">
          {__('Moje konania')}
        </Link>,
      ])
    );
    getInstances(query);
  }, [query, dispatch, getInstances]);

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__('Moje konania')}</HeaderBar.Header>
          </HeaderBar>
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              limit={LIST_LIMIT}
              offset={query.offset}
              loading={isFetching}
              total={total}
              onChangeOffset={(value: number) =>
                setQuery({
                  offset: value,
                })
              }
            />
          </ContextBarItem>
        </ContextBar>
      }
    >
      {!instances.length && !isFetching ? (
        <NotFound title={__('Nenašli sa žiadne konania')} icon="search" />
      ) : (
        <InstancesListTable items={instances} />
      )}
    </Layout>
  );
};

export default compose(connect(mapStateToProps))(ProcedureInstancesList);
