import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FileObjectDefinition } from '../../../../components/FileInput/definitions';
import { RootState } from '../../../../rootReducer';
import { __, required } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadProcedureInstanceById } from '../../actions';
import { procedureInstanceDetailSelector } from '../../selectors';
import Col, { Row } from '@sportnet/ui/Grid';
import FileInputRedux from '../../../../components/FileInput/redux-form';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import api from '../../../../api';
import withPopups, { WithPopupsProps } from '../../../../components/WithPopups';

interface IFormData {
  note: string;
  files: FileList;
}
interface OwnProps {
  form: string;
  initialValues: {
    note?: string;
    files?: Array<{}>;
  };
  handleClose: () => void;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  instance: procedureInstanceDetailSelector(state),
  appSpace: activeAppspaceIdSelector(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  WithPopupsProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & InjectedFormProps<IFormData, OwnProps>;

const ProcedureInstanceReportForm: React.FC<Props> = ({
  appSpace,
  instance,
}) => {
  const onFileClick = async (file: FileObjectDefinition) => {
    if (!instance || file._new) {
      return;
    }
    if (file.fileid) {
      const { downloadId } = await api.getInstanceReportFile(
        appSpace,
        instance._id,
        file.fileid
      );
      window.open(
        `${process.env.REACT_APP_CONTROLLING_API_BASE_URL}/downloader/${downloadId}`
      );
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <FormGroup>
          <Field
            component={FileInputRedux}
            id={instance && instance._id}
            onFileClick={onFileClick}
            disabled={instance && instance.report}
            name="files"
            multiple
          />
        </FormGroup>
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          required={!!instance && !!instance.report}
          validate={[required]}
          readOnly={!!instance && !!instance.report}
          type="textarea"
          name="note"
          label={__('Poznámka')}
        />
      </Col>
    </Row>
  );
};

export default compose(
  connect(mapStateToProps),
  withPopups,
  reduxForm<IFormData, Props>({
    enableReinitialize: true,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      if (props.instance) {
        const { files } = values;
        let uploadedFiles = [];
        if ((files || []).length > 0) {
          let reducedFiles: File[] = [];
          reducedFiles = Array.from(
            files as Array<{ file: File; fileid: string }>
          ).reduce((acc: File[], file: { file: File; fileid: string }) => {
            if (file.fileid) {
              return acc;
            }
            return [...acc, file.file];
          }, []);
          const promises: Array<Promise<any>> = [];
          reducedFiles.forEach((file: File) => {
            promises.push(
              api.uploadInstanceFile(
                props.appSpace,
                props.instance ? props.instance._id : '',
                undefined,
                {
                  upload: file,
                }
              )
            );
          });
          uploadedFiles = await Promise.all(promises);
        }
        try {
          await api.appendInstanceReport(
            props.appSpace,
            props.instance._id,
            undefined,
            { ...values, files: uploadedFiles }
          );
          props.dispatch(
            loadProcedureInstanceById.action({ id: props.instance._id })
          );
          props.handleClose();
        } catch (e) {
          if ((e as any).details.name === 'FORBIDDEN') {
            props.alert(
              __('Záverečnú správu konania môže vyplniť len vlastník konania.')
            );
          } else {
            props.alert(__('Ľutujeme, vyskytla sa neočakávaná chyba.'));
          }
        }
      }
    },
  })
)(ProcedureInstanceReportForm);
