import * as React from 'react';
import { Field } from 'redux-form';
import { __, required } from '../../../../utilities';
import Col, { Row } from '@sportnet/ui/Grid';
import DescriptionField from './descriptionField';
import FormField from '@sportnet/ui/FormField/redux-form';

const BasicForm: React.FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  return (
    <Row>
      <Col xs={12} s={6}>
        <Field
          component={FormField}
          label={__('Názov konania')}
          name="name"
          required
          validate={[required]}
          disabled={disabled}
        />
      </Col>
      <Col xs={12} s={6}>
        <Field
          component={FormField}
          label={__('Dátum ukončenia')}
          name="dateTo"
          type="dateTime"
          required
          validate={[required]}
          disabled={disabled}
        />
      </Col>
      <Col xs={12} s={6}>
          <Field
            label={__("Popis konania")}
            component={DescriptionField}
            name="description"
            disabled={disabled}
          />
      </Col>
    </Row>
  );
};

export default BasicForm;
