import * as React from 'react';
import { ApplicationInfo, Appspace } from './library/App';
import { CustomThunkDispatch } from './configureStore';
import { IAppPublic } from '@sportnet/ui/Authorization/types';
import { RootState } from './rootReducer';
import { activeAppspaceSelector } from './containers/App/selectors';
import { connect } from 'react-redux';
import {
  removeActiveAppspace,
  setActiveAppspace,
  setApplicationInfo,
  setApps,
  setAppspaces,
  setAuthUser,
} from './containers/App/actions';
import { useNavigate, useParams } from 'react-router-dom';
import Administration from './containers/Administration';
import Api from './api';
import Authorization from '@sportnet/ui/Authorization';
import Cookies from 'js-cookie';
import SportnetApi from './sportnetApi';
import config from './config';
import getProp from '@sportnet/utilities/getProp';

const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

interface OwnProps {
  dispatch: CustomThunkDispatch;
}

const mapStateToProps = (state: RootState) => ({
  activeAppspace: activeAppspaceSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & IMapStateToProps;

const Routes: React.FC<Props> = (props) => {
  const { activeAppspace, dispatch } = props;
  const { appspace } = useParams();
  const navigate = useNavigate();

  const onAppspacesReceived = React.useCallback(
    (data: Appspace[]) => {
      dispatch(setAppspaces(data));
    },
    [dispatch]
  );

  React.useEffect(() => {
    const token = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || '';
    Api.setToken(token);
    SportnetApi.setToken(token);
    if (appspace) {
      dispatch(setActiveAppspace(appspace));
      SportnetApi.meAppSpacesForApp(config.APP_ID)
        .then((res) => {
          onAppspacesReceived(getProp(res, ['appspaces'], []));
        })
        .catch((e) => {
          //
        });
    } else {
      dispatch(removeActiveAppspace());
    }
  }, [appspace, dispatch, onAppspacesReceived]);

  const onAuthorized = (data: any) => {
    dispatch(setAuthUser(data));
  };

  const onAppspaceSelect = async (data: Appspace) => {
    dispatch(setActiveAppspace(getProp(data, ['org_profile', '_id'])));
    navigate(`/admin/${getProp(data, ['org_profile', '_id'])}/instances`);
  };

  const onAppInfoReceived = (data: ApplicationInfo) => {
    dispatch(setApplicationInfo(data));
  };

  const onAppsReceived = (data: IAppPublic[]) => {
    dispatch(setApps(data));
  };

  return (
    <Authorization
      appId={config.APP_ID}
      appspace={activeAppspace}
      onAppspaceSelect={onAppspaceSelect}
      onAppsReceived={onAppsReceived}
      onAuthorized={onAuthorized}
      onAppInfoReceived={onAppInfoReceived}
      onAppspacesReceived={onAppspacesReceived}
      scope="profile,messages"
    >
      <Administration {...props} />
    </Authorization>
  );
};

export default connect(mapStateToProps)(Routes);
