import * as React from 'react';
import { CustomThunkDispatch } from '../../configureStore';
import { __ } from '../../utilities';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../containers/App/actions';
import Layout from '../../containers/Layout';

type Props = {
  dispatch: CustomThunkDispatch;
};

const Dashboard: React.FC<Props> = ({ dispatch }) => {
  React.useEffect(() => {
    dispatch(setBreadcrumbs([__('Hlavný prehľad')]));
  }, [dispatch]);

  return <Layout />;
};

export default connect()(Dashboard);
