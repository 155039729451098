import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import {
  Instance,
  ProcedureInstanceSegment,
} from '../../../../library/Instance';
import { RootState } from '../../../../rootReducer';
import { StatusChange } from './ProcedureInstanceDocumentForm';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { ThemeProvider } from 'theme/styled-components';
import { __ } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import { formatDate } from '@sportnet/content/view/utilities';
import { isSubmitting, reduxForm, submit } from 'redux-form';
import { withTheme } from 'styled-components';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import DotLabel from '@sportnet/ui/Label/DotLabel';
import Header from '@sportnet/ui/Header';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import ProcedureInstanceReportForm from './ProcedureInstanceReportForm';
import ProcedureInstanceSegmentForm, {
  warningColor,
} from './ProcedureInstanceSegmentForm';
import Segment from '@sportnet/ui/Segment';
import Widgets from '@sportnet/content/view';

export interface InstanceOptions {
  isParticipant?: boolean;
  isOwner?: boolean;
}

interface IFormData {
  segments: any[];
}

interface OwnProps {
  data: Instance;
}

const getInstanceReportFormName = (instanceId: string) =>
  `INSTANCE_${instanceId}_REPORT_FORM`;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    initialValues: ownProps.data,
    appSpace: activeAppspaceIdSelector(state),
    isCommiting: isSubmitting(getInstanceReportFormName(ownProps.data._id))(
      state
    ),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps &
  IMapStateToProps & { theme: Theme } & { dispatch: CustomThunkDispatch };

const InstanceForm: React.FC<Props> = ({
  dispatch,
  data,
  appSpace,
  theme,
  isCommiting,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const getStatus = (instanceData: Instance) => {
    const { status } = instanceData;
    switch (status) {
      case 'INVITED':
        return (
          <DotLabel color={theme.color.primary}>
            {__('Čaká na prijatie')}
          </DotLabel>
        );
      case 'OPENED':
        return <DotLabel color={warningColor}>{__('Prebieha')}</DotLabel>;
      case 'STORNO':
        return <DotLabel color={theme.color.danger}>{__('Zrušené')}</DotLabel>;
      case 'FAILED':
      case 'CLOSED':
        return renderReportStatus();
      default:
        throw new Error('UNKNOWN_INSTANCE_STATUS');
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const renderReportStatus = () => {
    let isOwner = false;
    if (data && data.createdByAppSpace._id === appSpace) {
      isOwner = true;
    }
    let color = theme.color.success;
    let label = __('Ukončené');

    if (data.status === 'FAILED') {
      color = theme.color.danger;
      label = __('Uzatvorené');
    }

    if (isOwner && data && !data.report) {
      return (
        <DotLabel color={color}>
          {label}{' '}
          <StatusChange onClick={handleOpen}>
            {__('Pridať záverečnú správu')}
          </StatusChange>
        </DotLabel>
      );
    } else if (data && data.report) {
      return (
        <DotLabel color={color}>
          {label}{' '}
          <StatusChange onClick={handleOpen}>
            {__('Zobraziť záverečnú správu')}
          </StatusChange>
        </DotLabel>
      );
    }
    return <DotLabel color={color}>{label}</DotLabel>;
  };

  const renderSegment = (segment: ProcedureInstanceSegment) => {
    const ops: InstanceOptions = {};
    if (data.appSpace === appSpace) {
      ops.isParticipant = true;
    }
    if (data.createdByAppSpace._id === appSpace) {
      ops.isOwner = true;
    }
    return (
      <ProcedureInstanceSegmentForm
        key={segment._id}
        {...ops}
        segment={segment}
      />
    );
  };

  const FORM_NAME = getInstanceReportFormName(data._id);
  return (
    <React.Fragment>
      <Segment raised>
        <Header size="s" subtitle={getStatus(data)} withSeparator>
          {data.appSpaceInfo?.name}: {data.name}
        </Header>
        <Row>
          <Col xs={12} s={6} m={4}>
            <Message primary>
              {__('Organizácia')}: <strong>{data.appSpaceInfo?.name}</strong>
            </Message>
          </Col>
          <Col xs={12} s={6} m={4}>
            <Message primary>
              {__('Termín odovzdania do')}:{' '}
              <strong>{formatDate(data.dateTo)}</strong>
            </Message>
          </Col>
          <Col xs={12} s={6} m={4}>
            <Message primary>
              {__('Organizátor')}:{' '}
              <strong>{data.createdByAppSpace.name}</strong>
            </Message>
          </Col>
          {(data.description || []).length > 0 && (
            <ThemeProvider theme={defaultContentTheme}>
              <Col xs={12}>
                <br />
                <Widgets
                  renderLink={(link: any) => (
                    <a href="/" target="_blank" {...link.linkProps}>
                      {link.linkProps.children}
                    </a>
                  )}
                  items={data.description}
                />
              </Col>
            </ThemeProvider>
          )}
        </Row>
      </Segment>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        <ModalContent>
          <Header size="s" withSeparator>
            {__('Záverečná správa pre konanie')} {data.name}
          </Header>
          <ProcedureInstanceReportForm
            handleClose={handleClose}
            initialValues={data.report || {}}
            form={FORM_NAME}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button secondary type="button" onClick={handleClose}>
              {__('Zavrieť')}
            </Button>
            {!data.report && (
              <Button
                primary
                type="button"
                loading={isCommiting}
                onClick={() => {
                  dispatch(submit(FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            )}
          </div>
        </ModalActions>
      </Modal>
      {(data.segments || []).map(renderSegment)}
    </React.Fragment>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm<IFormData, OwnProps>({
    form: 'INVITATION_FORM',
  }),
  withTheme
)(InstanceForm);
