import * as React from 'react';
import { CustomThunkDispatch } from '../../../../../configureStore';
import {
  Field,
  InjectedFormProps,
  SubmissionError,
  isSubmitting,
  reduxForm,
  submit,
} from 'redux-form';
import { FileObjectDefinition } from '../../../../../components/FileInput/definitions';
import {
  ProcedureInstanceSegment,
  ProcedureInstanceSegmentDocument,
} from '../../../../../library/Instance';
import { RootState } from '../../../../../rootReducer';
import { __ } from '../../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadProcedureInstanceById } from '../../../actions';
import { procedureInstanceDetailSelector } from '../../../../../pages/ProcedureInstances/selectors';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import FileInputRedux from '../../../../../components/FileInput/redux-form';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import InstancesTree from './Tree';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import api from '../../../../../api';
import getProp from '@sportnet/utilities/getProp';
import styled from 'theme/styled-components';
import withPopups, {
  WithPopupsProps,
} from '../../../../../components/WithPopups';

const TopMargin = styled.div`
  margin-top: ${rem(16)};
`;

const CopyWrapper = styled.div`
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  font-size: ${rem(12)};
  margin-top: ${rem(8)};
`;

interface OwnProps {
  document: ProcedureInstanceSegmentDocument;
  segment: ProcedureInstanceSegment;
  sharedInstances: Array<{ _id: string; name: string }>;
  form: string;
  disabled?: boolean;
  invited?: boolean;
  isOwner: boolean;
}

type IFormData = ProcedureInstanceSegmentDocument;

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  isCommiting: isSubmitting(props.form)(state),
  instance: procedureInstanceDetailSelector(state),
  appSpace: activeAppspaceIdSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & WithPopupsProps &
  InjectedFormProps<IFormData, OwnProps>;

const DocumentTypeUploadForm: React.FC<Props> = ({
  document: { _id: documentId },
  dispatch,
  form,
  isOwner,
  disabled,
  sharedInstances,
  invited,
  appSpace,
  instance,
  segment,
  document,
  isCommiting,
}) => {
  const [justSubmitted, setJustSubmitted] = React.useState(false);
  const [filesCopyModalOpened, setFilesCopyModalOpened] = React.useState(false);

  const isMountRef = React.useRef(true);

  React.useEffect(() => {
    if (!isCommiting && !isMountRef.current) {
      setJustSubmitted(true);
      setTimeout(() => {
        setJustSubmitted(false);
      }, 3000);
    } else if (isMountRef.current) {
      isMountRef.current = false;
    }
  }, [isCommiting]);

  const onFileClick = async (file: FileObjectDefinition) => {
    if (!instance || file._new) {
      return;
    }
    if (file.fileid) {
      const { downloadId } = await api.getDocumentFile(
        appSpace,
        instance._id,
        segment._id,
        document._id,
        file.fileid
      );
      window.open(
        `${process.env.REACT_APP_CONTROLLING_API_BASE_URL}/downloader/${downloadId}`
      );
    }
  };

  const toggleFilesCopyModal = () => {
    setFilesCopyModalOpened(!filesCopyModalOpened);
  };

  if (!instance || invited) {
    return null;
  }

  return (
    <>
      <Segment raised>
        <Row>
          <Col xs={12}>
            <Field
              component={FormFieldRedux}
              type="textarea"
              name="data.note"
              // disabled={disabled}
              placeholder={__('Komentár...')}
            />
          </Col>
          <Col xs={12}>
            <Field
              id={documentId}
              component={FileInputRedux}
              onFileClick={onFileClick}
              name="data.files"
              multiple
              disabled={!isOwner && disabled}
            />
          </Col>
          {sharedInstances.length > 0 && (
            <Col xs={12}>
              <TopMargin />
              <Field
                component={FormFieldRedux}
                name="shares"
                label={__('Zdieľať nahrané súbory s')}
                type="theselect"
                multiple
                options={sharedInstances.map((i) => ({
                  label: i.name,
                  value: i._id,
                }))}
              />
            </Col>
          )}
          {isOwner && (
            <Col xs={12}>
              <CopyWrapper onClick={toggleFilesCopyModal}>
                {__('Kopírovať súbory z iného konania')}
              </CopyWrapper>
            </Col>
          )}
        </Row>
        <React.Fragment>
          <br />
          <Row>
            <Col xs={12}>
              <Button
                loading={isCommiting}
                onClick={() => {
                  if (!justSubmitted) {
                    dispatch(submit(form));
                  }
                }}
                {...(justSubmitted ? { success: true } : { primary: true })}
              >
                {justSubmitted ? __('Uložené') : __('Uložiť')}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      </Segment>
      {filesCopyModalOpened && (
        <Modal handleClose={toggleFilesCopyModal} isOpen={filesCopyModalOpened}>
          <ModalContent>
            <InstancesTree
              appSpace={appSpace}
              participantAppSpace={instance ? instance.appSpace || '' : ''}
              instanceId={instance._id}
              segmentId={segment._id}
              documentId={document._id}
              toggleModal={() => {
                toggleFilesCopyModal();
                dispatch(
                  loadProcedureInstanceById.action({
                    id: instance ? instance._id : '',
                  })
                ).then(() => {
                  if (window.history.pushState) {
                    window.history.pushState(
                      null,
                      '',
                      `#segment_${segment._id}`
                    );
                  } else {
                    window.location.hash = `#segment_${segment._id}`;
                  }
                });
              }}
            />
          </ModalContent>
          <ModalActions>
            <div />
            <div>
              <Button onClick={toggleFilesCopyModal}>{__('Zavrieť')}</Button>
            </div>
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  withPopups,
  reduxForm<IFormData, OwnProps>({
    enableReinitialize: true,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      return new Promise<void>((resolve, reject) => {
        try {
          if (props.instance) {
            let { appSpace } = props;
            if (!appSpace) {
              appSpace = props.instance.appSpace;
            }

            const valuesData = getProp(values || {}, ['data'], {
              files: [],
              note: undefined,
            });
            let { files = [] } = valuesData;
            const { note } = valuesData;
            files = files.reduce((acc: any[], file: any) => {
              if (file.fileid) {
                return acc;
              }
              return [...acc, file.file];
            }, []);
            if (files.length || note) {
              const promises: Array<Promise<any>> = [];
              (files || []).forEach((file: File) => {
                promises.push(
                  api.uploadInstanceFile(
                    appSpace,
                    props.document._id,
                    undefined,
                    {
                      upload: file,
                    }
                  )
                );
              });
              Promise.all(promises)
                .then((uploadedFiles) => {
                  const submitData: any = {
                    type: 'upload',
                    data: {
                      files: uploadedFiles || [],
                      note,
                    },
                  };
                  if ((values.shares || []).length) {
                    submitData.shares = (values.shares || []).map((i: any) => {
                      const [procedureId, segmentId, documentId] =
                        i.value.split(':');
                      return { procedureId, segmentId, documentId };
                    });
                  }
                  api
                    .updateInstanceDocument(
                      appSpace,
                      props.instance ? props.instance._id : '',
                      props.segment._id,
                      props.document._id,
                      undefined,
                      submitData
                    )
                    .then(() => {
                      props
                        .dispatch(
                          loadProcedureInstanceById.action({
                            id: props.instance ? props.instance._id : '',
                          })
                        )
                        .then(() => {
                          if (window.history.pushState) {
                            window.history.pushState(
                              null,
                              '',
                              `#document_${props.document._id}`
                            );
                          } else {
                            window.location.hash = `#document_${props.document._id}`;
                          }
                          resolve();
                        });
                    })
                    .catch((e) => {
                      if (e.details.name === 'FORBIDDEN') {
                        props.alert(
                          __(
                            'Na danú akciu nemáte práva. Ak ste kontrolórom konania, pravdepodobne nepatríte do priradenej skupiny.'
                          )
                        );
                      }
                    });
                })
                .catch((e) => {
                  console.error(e);
                  if (e.details && e.details.name === 'MAX_FILE_SIZE_EXCEDED') {
                    props.alert(
                      __(
                        'Súbor je priveľký, priložte súbor s maximálnou veľkosťou 50MB.'
                      )
                    );
                  } else {
                    props.alert(__('Súbor sa nepodarilo nahrať'));
                  }
                  reject(new SubmissionError({ 'data.files': '' }));
                });
            } else {
              props.alert(
                __('Prosím, pred uložením pridajte aspoň jeden súbor.')
              );
              reject(new SubmissionError({ 'data.files': '' }));
            }
          }
        } catch (e) {
          reject(e);
        }
      });
    },
  })
)(DocumentTypeUploadForm);
