import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { ProcedureSegmentDocument } from '../../../../library/Procedure';
import { RootState } from '../../../../rootReducer';
import { __, required } from '../../../../utilities';
import {
  addDocumentAndSave,
  deleteSegment,
  updateSegmentDocumentsOrder,
} from '../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  detailIsReorderingSelector,
  segmentDocumentsSelector,
  segmentDomainByIdSelector,
} from '../selectors';
import { segmentFormNameFactory } from '../';
import { userGroupsSelector } from '../../../../containers/App/selectors';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import DocumentForm, { documentFormNameFactory } from './documentForm';
import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import getProp from '@sportnet/utilities/getProp';
import styled from 'styled-components';
import userGroupsField from './userGroupsField';

interface OwnProps {
  _generatedId: string;
  idx: number;
  form: string;
  disabled?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  moveSegment?: (fromIndex: number, toIndex: number) => void;
}

export const SmallButton = styled(Button)`
  padding: 0;
  min-height: auto;
  vertical-align: middle;
  margin: 0 5px 0 0;
`;

export interface IFormData {
  name: string;
  administratedBy: string[];
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const segmentDomain = segmentDomainByIdSelector(props._generatedId)(state);
  let initialValues = null;
  if (segmentDomain) {
    initialValues = {
      name: segmentDomain.data.name,
      administratedBy: getProp(
        segmentDomain,
        ['data', 'administratedBy', 'groups'],
        []
      ),
    };
  }
  return {
    initialValues,
    segmentId: segmentDomain?.data._id,
    userGroups: userGroupsSelector(state),
    isFetching: !!(segmentDomain && segmentDomain.isFetching),
    isReordering: detailIsReorderingSelector(state),
    fetchError: segmentDomain && segmentDomain.error,
    formValues: getFormValues(segmentFormNameFactory(props._generatedId))(
      state
    ) as IFormData,
    documents: segmentDocumentsSelector(props._generatedId)(state),
  };
};
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & InjectedFormProps<IFormData, OwnProps>;

const SegmentForm: React.FC<Props> = ({
  dispatch,
  disabled = false,
  _generatedId,
  idx,
  userGroups,
  formValues,
  isFetching,
  isReordering,
  documents: defaultDocuments,
  fetchError,
  dirty,
  isFirst = false,
  isLast = false,
  moveSegment,
}) => {
  const handleClickRemove = () => {
    dispatch(deleteSegment.action(_generatedId));
  };

  const [documents, setDocuments] = React.useState(defaultDocuments);
  React.useEffect(() => {
    setDocuments(defaultDocuments);
  }, [defaultDocuments]);
  // Function to move a segment up or down
  const moveDocument = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= documents.length) return; // Prevent invalid moves
    const updatedDocuments = [...documents];
    const [movedDocument] = updatedDocuments.splice(fromIndex, 1); // Remove from fromIndex
    updatedDocuments.splice(toIndex, 0, movedDocument); // Insert at toIndex
    setDocuments(updatedDocuments);
    dispatch(
      updateSegmentDocumentsOrder.action({
        segmentId: _generatedId,
        orderedDocumentIds: updatedDocuments.map(({ data: { _id } }) => _id),
      })
    );
  };

  const renderDocument = (
    document: {
      _generatedId: string;
      data: ProcedureSegmentDocument;
    },
    idx: number,
    all: any[]
  ) => (
    <DocumentForm
      key={document._generatedId}
      _generatedId={document._generatedId}
      disabled={disabled}
      _generatedSegmentId={_generatedId}
      idx={idx}
      form={documentFormNameFactory(document._generatedId)}
      isFirst={idx === 0}
      isLast={idx + 1 === all.length}
      moveDocument={moveDocument}
    />
  );

  const handleAddDocument = () => {
    dispatch(
      addDocumentAndSave.action({
        generatedSegmentId: _generatedId,
      })
    );
  };

  const renderError = (error?: string) => {
    if (!error) {
      return '';
    }
    switch (error) {
      case 'SEGMENT_NOT_CHANGED':
        return '';
      default:
        return ` - ${__('Chyba pri ukladaní')}`;
    }
  };

  const name = getProp(formValues, ['name'], '');
  return (
    <Segment
      raised
      key={_generatedId}
      header={
        <SegmentHeader
          withSeparator
          size="s"
          collapsible
          {...(!disabled && { onDispose: handleClickRemove })}
        >
          {!disabled && (
            <>
              <SmallButton
                icon="arrow-top"
                basic
                disabled={isFirst || isReordering}
                loading={isReordering}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  moveSegment && moveSegment(idx, idx - 1);
                }}
              />
              <SmallButton
                icon="arrow-down"
                basic
                disabled={isLast || isReordering}
                loading={isReordering}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  moveSegment && moveSegment(idx, idx + 1);
                }}
              />
            </>
          )}
          {__('Časť')} {idx + 1}
          {name && ` (${name})`}
          {isFetching && ` - ${__('Ukladá sa ...')}`}
          {dirty && !isFetching && ` - ${__('Neuložené zmeny')}`}
          {renderError(fetchError)}
        </SegmentHeader>
      }
    >
      <Row>
        <Col xs={12} s={6}>
          <Field
            component={FormField}
            label={__('Názov časti')}
            name="name"
            required
            validate={[required]}
            disabled={disabled}
          />
        </Col>
        <Col xs={12} s={6}>
          <Field
            component={userGroupsField}
            label={__('Kontrolné skupiny')}
            name="administratedBy"
            availableUserGroups={userGroups}
            readOnly={disabled}
          />
        </Col>
      </Row>
      {documents.map(renderDocument)}
      {!disabled && (
        <Button icon="plus" primary basic onClick={handleAddDocument}>
          {__('Pridať dokument')}
        </Button>
      )}
    </Segment>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm<IFormData, Props>({
    enableReinitialize: true,
  })
)(SegmentForm);
