import { ExtraArgumentType } from '../../configureStore';
import { Instance } from './../../library/Instance';
import { RootState } from '../../rootReducer';
import { activeAppspaceIdSelector } from '../../containers/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { procedureInstanceDetailSelector } from './selectors';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.INSTANCES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadInvitationById = createAsync<
  {
    id: string;
  },
  Instance
>('GET_INVITATION', async (parameters, dispatch, getState, { Api }) => {
  return await Api.getInvitationById(parameters.id);
});

export const loadProcedureInstanceById = createAsync<
  {
    id: string;
  },
  Instance
>('GET_INSTANCE', async (parameters, dispatch, getState, { Api }) => {
  const appspace = activeAppspaceIdSelector(getState());
  const instance = await Api.getProcedureInstanceById(appspace, parameters.id);
  const shares = await Api.getInstanceShares(appspace, parameters.id);
  return { ...instance, shares };
});

export const changeInvitationStatus = createAsync<
  { status: 'OPENED' | 'STORNO' },
  Instance
>(
  'CHANGE_INVITATION_STATE',
  async (parameters, dispatch, getState, { Api }) => {
    const instance = procedureInstanceDetailSelector(getState());
    if (instance) {
      const invitation = await Api.changeInvitationStatus(
        instance._id,
        undefined,
        { status: parameters.status }
      );
      return invitation;
    }
    throw new Error('NO_ACTIVE_INSTANCE');
  }
);

export const getProcedureInstances = createAsync<
  { procedureId: string; offset: number; limit: number },
  { items: Instance[] }
>(
  'GET_PROCEDURE_INSTANCES',
  async (parameters, dispatch, getState, { Api }) => {
    const appSpace = activeAppspaceIdSelector(getState());
    const instances = await Api.getProcedureInstances(
      appSpace,
      parameters.procedureId
    );
    return instances;
  }
);
