import * as actions from './actions';
import {
  Procedure,
  ProcedureSegment,
  ProcedureSegmentDocument,
} from '../../../library/Procedure';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

// Documents
type ProcedureDetailSegmentDocumentState = Readonly<{
  _generatedId: string;
  data: ProcedureSegmentDocument;
  isFetching: boolean;
  error: string;
}>;

type ProcedureDetailSegmentDocumentsState =
  ReadonlyArray<ProcedureDetailSegmentDocumentState>;

const updateDocument = (
  state: ProcedureDetailState,
  segmentId: string,
  documentId: string,
  updater: (
    prevState: ProcedureDetailSegmentDocumentState
  ) => ProcedureDetailSegmentDocumentState
): ProcedureDetailState => ({
  ...state,
  segments: state.segments.map((seg) => {
    if (seg._generatedId === segmentId) {
      return {
        ...seg,
        documents: seg.documents.map((doc) => {
          if (doc._generatedId === documentId) {
            return updater(doc);
          }
          return doc;
        }),
      };
    }
    return seg;
  }),
});

// Segments

type ProcedureDetailSegmentsState = ReadonlyArray<{
  _generatedId: string;
  data: ProcedureSegment;
  isFetching: boolean;
  error: string;
  documents: ProcedureDetailSegmentDocumentsState;
}>;

const SEGMENTS_INITIAL_STATE: ProcedureDetailSegmentsState = [];

export type ProcedureDetailState = Readonly<{
  data: Procedure | null;
  isFetching: boolean;
  isReordering: boolean;
  error: string;
  segments: ProcedureDetailSegmentsState;
}>;

export const INITIAL_STATE: ProcedureDetailState = {
  data: null,
  isFetching: false,
  isReordering: false,
  error: '',
  segments: SEGMENTS_INITIAL_STATE,
};

export const procedureDetailReducer =
  reducerWithInitialState<ProcedureDetailState>(INITIAL_STATE)
    .case(
      actions.createProcedure.async.started,
      (): ProcedureDetailState => INITIAL_STATE
    )
    .case(
      actions.saveProcedure.async.started,
      (state): ProcedureDetailState => ({
        ...state,
        isFetching: true,
      })
    )
    .case(
      actions.saveProcedure.async.done,
      (state, { result }): ProcedureDetailState => ({
        ...state,
        isFetching: false,
        data: result,
      })
    )
    .case(
      actions.extendProcedure.async.done,
      (state, { result }): ProcedureDetailState => ({
        ...state,
        isFetching: false,
        data: result,
      })
    )
    .case(
      actions.addParticipantToProcedure.async.done,
      (state, { result }): ProcedureDetailState => ({
        ...state,
        isFetching: false,
        data: result,
      })
    )
    .case(
      actions.removeParticipantFromProcedure.async.done,
      (state, { result }): ProcedureDetailState => ({
        ...state,
        isFetching: false,
        data: result,
      })
    )
    .case(
      actions.saveProcedure.async.failed,
      (state, { error }): ProcedureDetailState => ({
        ...state,
        isFetching: false,
        error: (error as any).message,
      })
    )
    .case(
      actions.loadProcedureById.async.started,
      (state): ProcedureDetailState => ({
        ...state,
        isFetching: true,
        error: '',
      })
    )
    .case(
      actions.loadProcedureById.async.done,
      (state, parameters): ProcedureDetailState => ({
        data: parameters.result,
        isFetching: false,
        isReordering: false,
        error: '',
        segments: (parameters.result.segments || []).map(
          (segmentFromResponse) => {
            return {
              documents: (segmentFromResponse.documents || []).map((doc) => ({
                _generatedId: doc._id,
                data: doc,
                error: '',
                isFetching: false,
              })),
              _generatedId: segmentFromResponse._id,
              data: segmentFromResponse,
              isFetching: false,
              error: '',
            };
          }
        ),
      })
    )
    .case(
      actions.loadProcedureById.async.failed,
      ({ error }): ProcedureDetailState => ({
        data: null,
        error: 'failed',
        isFetching: false,
        isReordering: false,
        segments: [],
      })
    )
    .case(
      actions.addSegment.async.done,
      (state, { result }): ProcedureDetailState => ({
        ...state,
        segments: [
          ...state.segments,
          {
            _generatedId: result,
            documents: [],
            data: {
              _id: '',
              name: '',
            },
            isFetching: false,
            error: '',
          },
        ],
      })
    )
    .case(
      actions.saveSegment.async.started,
      (state, segmentId): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === segmentId) {
            return { ...seg, isFetching: true, error: '' };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.saveSegment.async.done,
      (state, { params: segmentId, result }): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === segmentId) {
            return { ...seg, isFetching: false, error: '', data: result };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.saveSegment.async.failed,
      (state, { params: segmentId, error }): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === segmentId) {
            return { ...seg, isFetching: false, error: error.message };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.deleteSegment.async.started,
      (state, segmentId): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === segmentId) {
            return { ...seg, isFetching: true, error: '' };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.deleteSegment.async.done,
      (state, { params: segmentId, result }): ProcedureDetailState => ({
        ...state,
        segments: state.segments.filter(
          (seg) => seg._generatedId !== segmentId
        ),
      })
    )
    .case(
      actions.deleteSegment.async.failed,
      (state, { params: segmentId, error }): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === segmentId) {
            return { ...seg, isFetching: false, error: (error as any).message };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.addDocument.async.done,
      (state, { params, result }): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === params.generatedSegmentId) {
            return {
              ...seg,
              documents: [
                ...seg.documents,
                {
                  _generatedId: result,
                  data: {
                    _id: '',
                    name: '',
                    type: '',
                  },
                  isFetching: false,
                  error: '',
                },
              ],
            };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.saveDocument.async.started,
      (
        state,
        { generatedDocumentId, generatedSegmentId }
      ): ProcedureDetailState =>
        updateDocument(
          state,
          generatedSegmentId,
          generatedDocumentId,
          (prev): ProcedureDetailSegmentDocumentState => ({
            ...prev,
            isFetching: true,
            error: '',
          })
        )
    )
    .case(
      actions.saveDocument.async.done,
      (
        state,
        { params: { generatedDocumentId, generatedSegmentId }, result }
      ): ProcedureDetailState =>
        updateDocument(
          state,
          generatedSegmentId,
          generatedDocumentId,
          (prev): ProcedureDetailSegmentDocumentState => ({
            ...prev,
            data: result,
            isFetching: false,
            error: '',
          })
        )
    )
    .case(
      actions.saveDocument.async.failed,
      (
        state,
        { params: { generatedDocumentId, generatedSegmentId }, error }
      ): ProcedureDetailState =>
        updateDocument(
          state,
          generatedSegmentId,
          generatedDocumentId,
          (prev): ProcedureDetailSegmentDocumentState => ({
            ...prev,
            isFetching: false,
            error: (error as any).message,
          })
        )
    )
    .case(
      actions.deleteDocument.async.started,
      (
        state,
        { generatedDocumentId, generatedSegmentId }
      ): ProcedureDetailState =>
        updateDocument(
          state,
          generatedSegmentId,
          generatedDocumentId,
          (prev): ProcedureDetailSegmentDocumentState => ({
            ...prev,
            isFetching: true,
            error: '',
          })
        )
    )
    .case(
      actions.deleteDocument.async.done,
      (
        state,
        { params: { generatedDocumentId, generatedSegmentId }, result }
      ): ProcedureDetailState => ({
        ...state,
        segments: state.segments.map((seg) => {
          if (seg._generatedId === generatedSegmentId) {
            return {
              ...seg,
              documents: seg.documents.filter(
                (doc) => doc._generatedId !== generatedDocumentId
              ),
            };
          }
          return seg;
        }),
      })
    )
    .case(
      actions.deleteDocument.async.failed,
      (
        state,
        { params: { generatedDocumentId, generatedSegmentId }, error }
      ): ProcedureDetailState =>
        updateDocument(
          state,
          generatedSegmentId,
          generatedDocumentId,
          (prev): ProcedureDetailSegmentDocumentState => ({
            ...prev,
            isFetching: false,
            error: (error as any).message,
          })
        )
    )
    .case(
      actions.updateSegmentsOrder.async.started,
      (state): ProcedureDetailState => ({
        ...state,
        isReordering: true,
      })
    )
    .case(
      actions.updateSegmentsOrder.async.done,
      (state): ProcedureDetailState => ({
        ...state,
        isReordering: false,
      })
    )
    .case(
      actions.updateSegmentDocumentsOrder.async.started,
      (state): ProcedureDetailState => ({
        ...state,
        isReordering: true,
      })
    )
    .case(
      actions.updateSegmentDocumentsOrder.async.done,
      (state): ProcedureDetailState => ({
        ...state,
        isReordering: false,
      })
    );
