import * as React from 'react';
import { CustomThunkDispatch } from '../../configureStore';
import { ProcedureSegmentDocument } from '../../library/Procedure';
import { RootState } from '../../rootReducer';
import { Theme } from '@sportnet/ui/Themes/baseTheme';
import { __ } from '../../utilities';
import { activeAppspaceIdSelector } from '../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isBefore } from 'date-fns';
import { loadProcedureInstanceById } from './actions';
import {
  procedureInstanceDetailIsFetchingSelector,
  procedureInstanceDetailSelector,
} from './selectors';
import { rem } from 'polished';
import { useParams } from 'react-router-dom';
import { warningColor } from './Detail/Forms/ProcedureInstanceSegmentForm';
import Loader from '@sportnet/ui/Loader';
import styled, { css, withTheme } from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  min-height: 100vh;
  padding: ${rem(8)};
`;
const ReportPagePart = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(8)};
  border: 1px solid #bbb;
  font-size: ${rem(14)};
  width: 100%;
`;

const ReportHeader = styled(ReportPagePart)`
  font-size: ${rem(14)};
`;
const ReportDocuments = styled('div')`
  font-size: ${rem(14)};
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    border: 1px solid #bbb;
    text-align: left;
    padding: ${rem(4)} ${rem(8)};
  }
`;

const DocumentStatus = styled.div<{ color: string }>`
  ${({ color }) => css`
    color: ${color};
    font-weight: bold;
  `}
`;

const HalfTd = styled.td`
  width: 50%;
`;
const ThirdTd = styled.td`
  width: 35%;
`;

const mapStateToProps = (state: RootState) => ({
  data: procedureInstanceDetailSelector(state),
  isFetching: procedureInstanceDetailIsFetchingSelector(state),
  appSpace: activeAppspaceIdSelector(state),
});

const InstanceSegmentReport: React.FC<
  { theme: Theme } & {
    dispatch: CustomThunkDispatch;
  } & ReturnType<typeof mapStateToProps>
> = ({ isFetching, data, dispatch, theme }) => {
  const { instanceId = '', segmentId = '' } = useParams();
  React.useEffect(() => {
    dispatch(loadProcedureInstanceById.action({ id: instanceId }));
  }, [dispatch, instanceId]);

  const translateDocumentStatus = (document: ProcedureSegmentDocument) => {
    let dotLabel;
    switch (document.status) {
      case 'NEW':
      case 'FAILED':
        dotLabel = (
          <DocumentStatus color={warningColor}>
            {__('Čaká na úpravy')}
          </DocumentStatus>
        );
        if (
          document.dateTo &&
          isBefore(new Date(document.dateTo), new Date())
        ) {
          dotLabel = (
            <DocumentStatus color={theme.color.danger}>
              {__('Nesplnené')}
            </DocumentStatus>
          );
        }
        return dotLabel;
      case 'OPENED':
        return (
          <DocumentStatus color={warningColor}>
            {__('Čaká na schválenie')}
          </DocumentStatus>
        );
      case 'ACCEPTED':
        if (document.report?.reportStatus === 'ACCEPTED_WITH_NOTICE') {
          return (
            <>
              <DocumentStatus color={theme.color.success}>
                {__('Schválené s výhradou')}
              </DocumentStatus>
            </>
          );
        }
        return (
          <>
            <DocumentStatus color={theme.color.success}>
              {__('Schválené')}
            </DocumentStatus>
          </>
        );
      default:
        return '';
    }
  };

  const renderDocument = (document: ProcedureSegmentDocument) => {
    return (
      <tr>
        <HalfTd>{document.name}</HalfTd>
        <td>{translateDocumentStatus(document)}</td>
        <ThirdTd>{document.report && document.report.note}</ThirdTd>
      </tr>
    );
  };

  if (isFetching || !data) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  const segment = (data.segments || []).find((i: any) => i._id === segmentId);

  if (!segment) {
    return null;
  }

  return (
    <ReportWrapper>
      <ReportHeader>
        <div style={{ fontWeight: 'bold', fontSize: rem(24) }}>
          {__('Správa odborníka o preverení dokumentácie')}
        </div>
        <p />
        <div>
          {__('Výsledok kontroly k oblasti')}: <b>{segment.name}</b>
        </div>
        <div>
          {__('Žiadateľ')}:{' '}
          <b>{data.appSpaceInfo && data.appSpaceInfo.organization_name}</b>
        </div>
      </ReportHeader>
      <p />
      <ReportDocuments>
        <table>
          <thead>
            <tr>
              <th>{__('Dokumentácia')}</th>
              <th>
                {__('splnené')}/{__('nesplnené')}
              </th>
              <th>{__('Poznámka')}</th>
            </tr>
          </thead>
          <tbody>{(segment.documents || []).map(renderDocument)}</tbody>
        </table>
      </ReportDocuments>
      {/* <p style={{ pageBreakBefore: 'always' }}></p>
      <ReportPagePart>
        <div>
          {__('Zhrnutie preverenia predloženej licenčnej dokumentácie:')}
        </div>
        <div style={{ height: rem(100) }} />
      </ReportPagePart>
      <ReportPagePart>
        <div>{__('Odporúčanie k udeleniu licenciu za danú oblasť:')}</div>
        <div style={{ height: rem(200) }} />
      </ReportPagePart> */}
    </ReportWrapper>
  );
};

export default compose(
  connect(mapStateToProps),
  withTheme
)(InstanceSegmentReport);
