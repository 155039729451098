import * as React from 'react';
import getProp from '@sportnet/utilities/getProp';
import styled, { css } from 'styled-components';

const Wrapper = styled('div')<{ hovering?: number }>`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease-in-out;
  ${({ hovering }) =>
    hovering &&
    css`
      background-color: ${({ theme }) => theme.color.successBg};
    `};
`;

interface OwnProps {
  onDropFiles: (files: FileList | File[] | null) => void;
  children?: React.ReactNode;
}

type Props = OwnProps & {};

const Dropzone: React.FC<Props> = ({ onDropFiles, children }) => {
  const [hovering, setHovering] = React.useState(0);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHovering(hovering + 1);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHovering(hovering - 1);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setHovering(0);

    const droppedFiles = e.dataTransfer
      ? e.dataTransfer.files
      : (getProp(e, ['target', 'files'], []) as FileList);
    const files: File[] = [];
    Array.from(droppedFiles).forEach((file) => {
      if (file.type) {
        files.push(file);
      }
    });

    onDropFiles(files);
  };

  return (
    <Wrapper
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      hovering={hovering}
    >
      {children}
    </Wrapper>
  );
};

export default Dropzone;
