import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { RootState } from '../../../../rootReducer';
import { SmallButton } from './segmentForm';
import { WidgetsContent } from '../../../../library/Common';
import { __, required } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteDocument } from '../actions';
import {
  detailIsReorderingSelector,
  detailSelector,
  segmentDocumentDomainByDocumentIdAnySegmentIdSelector,
} from '../selectors';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import DescriptionField from './descriptionField';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import Tree from './Tree';
import config from '../../../../config';
import getProp from '@sportnet/utilities/getProp';

export const documentFormNameFactory = (id: string) =>
  `${config.PROCEDURES_NS}_DOCUMENT_FORM_${id}`;

interface OwnProps {
  _generatedId: string;
  idx: number;
  form: string;
  _generatedSegmentId: string;
  disabled?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  moveDocument?: (fromIndex: number, toIndex: number) => void;
}

export interface IFormData {
  name: string;
  description: WidgetsContent;
  dateTo: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const documentDomain = segmentDocumentDomainByDocumentIdAnySegmentIdSelector(
    props._generatedSegmentId,
    props._generatedId
  )(state);
  let initialValues = null;
  if (documentDomain) {
    initialValues = {
      name: documentDomain.data.name,
      description: documentDomain.data.description || [],
      dateTo: documentDomain.data.dateTo,
    };
  }
  return {
    initialValues,
    isFetching: !!(documentDomain && documentDomain.isFetching),
    isReordering: detailIsReorderingSelector(state),
    fetchError: documentDomain && documentDomain.error,
    formValues: getFormValues(documentFormNameFactory(props._generatedId))(
      state
    ) as IFormData,
    procedure: detailSelector(state),
  };
};
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & InjectedFormProps<IFormData, OwnProps>;

const DocumentForm: React.FC<Props> = ({
  dispatch,
  _generatedId,
  idx,
  formValues,
  isFetching,
  isReordering,
  fetchError,
  dirty,
  disabled,
  procedure,
  _generatedSegmentId,
  isFirst,
  isLast,
  moveDocument,
}) => {
  const [sharesModalOpened, setSharesModalOpened] = React.useState(false);

  const handleClickRemove = () => {
    dispatch(
      deleteDocument.action({
        generatedDocumentId: _generatedId,
        generatedSegmentId: _generatedSegmentId,
      })
    );
  };

  const renderError = (error?: string) => {
    if (!error) {
      return '';
    }
    switch (error) {
      case 'DOCUMENT_NOT_CHANGED':
        return '';
      default:
        return ` - ${__('Chyba pri ukladaní')}`;
    }
  };

  const toggleSharesModal = () => {
    setSharesModalOpened(!sharesModalOpened);
  };

  const name = getProp(formValues, ['name'], '');

  const shares = procedure
    ? (procedure.shares || []).filter(
        (share) => share.sourceDocumentId === _generatedId
      )
    : [];

  return (
    <>
      <Segment
        secondary
        key={_generatedId}
        header={
          <SegmentHeader
            collapsible
            {...(!disabled
              ? { onDispose: handleClickRemove }
              : { isCollapsed: true })}
            withSeparator
            size="xs"
          >
            {!disabled && (
              <>
                <SmallButton
                  icon="arrow-top"
                  basic
                  disabled={isFirst || isReordering}
                  loading={isReordering}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    moveDocument && moveDocument(idx, idx - 1);
                  }}
                />
                <SmallButton
                  icon="arrow-down"
                  basic
                  disabled={isLast || isReordering}
                  loading={isReordering}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    moveDocument && moveDocument(idx, idx + 1);
                  }}
                />
              </>
            )}
            {__('Dokument')} {idx + 1}
            {name && ` (${name})`}
            {isFetching && ` - ${__('Ukladá sa ...')}`}
            {dirty && !isFetching && ` - ${__('Neuložené zmeny')}`}
            {renderError(fetchError)}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12} s={6}>
            <Field
              component={FormField}
              label={__('Názov dokumentu')}
              name="name"
              {...(!disabled && { required: true })}
              disabled={disabled}
              validate={[required]}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={DescriptionField}
              name="description"
              disabled={disabled}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormField}
              label={__(
                'Dátum ukončenia (ak nebude zadaný, platí dátum ukončenia konania)'
              )}
              name="dateTo"
              type="dateTime"
              disabled={disabled}
            />
          </Col>
          <Col xs={12} s={6}>
            <FormGroup>
              <Label>{__('Zdieľanie dokumentu')}</Label>
              <Button basic icon="attach" onClick={toggleSharesModal}>
                {disabled ? __('Zobraziť zdieľania') : __('Upraviť zdieľania')}
                {` ${
                  shares.length > 0
                    ? `(${__('Počet zdieľaní')}: ${shares.length})`
                    : ''
                }`}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Segment>
      {sharesModalOpened && (
        <Modal handleClose={toggleSharesModal} isOpen={sharesModalOpened}>
          <ModalContent>
            <Tree
              disabled={disabled}
              segmentId={_generatedSegmentId}
              documentId={_generatedId}
            />
          </ModalContent>
          <ModalActions>
            <div />
            &nbsp;
            <div>
              <Button onClick={toggleSharesModal}>{__('Zavrieť')}</Button>
            </div>
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export default compose(
  connect(mapStateToProps),
  reduxForm<IFormData, Props>({
    enableReinitialize: true,
  })
)(DocumentForm);
