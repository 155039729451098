import * as React from 'react';
import { Instance, InstanceStatus } from '../../../../library/Instance';
import { RootState } from '../../../../rootReducer';
import { __ } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BasicTable from '@sportnet/ui/BasicTable';

const mapStateToProps = (state: RootState) => ({
  appSpace: activeAppspaceIdSelector(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  items: Instance[];
  isFetching: boolean | null;
}

type Props = OwnProps & IMapStateToProps;

const ProcedureInstancesList: React.FC<Props> = ({ items, appSpace }) => {
  const navigate = useNavigate();

  const getStatus = (status: InstanceStatus) => {
    switch (status) {
      case 'INVITED':
        return __('Čaká na prijatie pozvánky');
      case 'OPENED':
        return __('Prebieha');
      case 'CLOSED':
      case 'FAILED':
        return __('Ukončené');
      case 'STORNO':
        return __('Zrušené');
      default:
        throw new Error('UNKNOWN_INSTANCE_STATUS');
    }
  };

  return (
    <BasicTable
      columns={[{ header: __('Účastník') }, { header: __('Stav') }]}
      rows={items}
      onClickRow={(item: Instance) =>
        navigate(`/admin/${appSpace}/instances/${item._id}`)
      }
      renderRow={(item: Instance) => [
        item.appSpaceInfo && item.appSpaceInfo.name,
        getStatus(item.status),
      ]}
      rowKey="_id"
    />
  );
};

export default connect(mapStateToProps)(ProcedureInstancesList);
