import * as React from 'react';
import { __ } from '../../../../../utilities';
import Loader from '@sportnet/ui/Loader';
import Tree, { MenuItem } from '@sportnet/ui/Tree';
import api from '../../../../../api';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface File {
  fileid: string;
  filepath: string;
  mimetype: string;
  name: string;
  size: number;
}

const InstancesTree: React.FC<{
  appSpace: string;
  participantAppSpace: string;
  instanceId: string;
  segmentId: string;
  documentId: string;
  toggleModal: () => void;
}> = ({
  toggleModal,
  appSpace,
  participantAppSpace,
  instanceId,
  segmentId,
  documentId,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [treeData, setTreeData] = React.useState<
    Array<MenuItem & { isFile?: boolean }>
  >([
    {
      _id: 'one',
      title: 'one',
      children: [
        {
          _id: 'two',
          title: 'two',
          children: [{ _id: 'four', title: 'four' }],
        },
      ],
    },
    { _id: 'tree', title: 'tree', children: [{ _id: 'five', title: 'five' }] },
  ]);

  React.useEffect(() => {
    setIsFetching(true);
    api
      .getParticipantInstances(appSpace, participantAppSpace)
      .then((data) => {
        setTreeData(
          data.items.map((i) => ({
            _id: i._id,
            title: i.name,
            children: (i.segments || []).map((s: any) => ({
              _id: s._id || '',
              title: s.name || '',
              children: (s.documents || []).map((d: any) => ({
                _id: d._id,
                title: d.name,
                children: (d.files || []).map((f: File) => ({
                  _id: f.fileid,
                  title: f.name,
                  instanceId: i._id,
                  segmentId: s._id,
                  documentId: d._id,
                  isFile: true,
                  ...f,
                })),
              })),
            })),
          }))
        );
      })
      .catch((e) => {
        alert(__('Nepodarilo sa získať minulé konania.'));
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [appSpace, participantAppSpace]);

  const onClickFile = async (file: File) => {
    try {
      setIsFetching(true);
      await api.updateInstanceDocument(
        appSpace,
        instanceId,
        segmentId,
        documentId,
        undefined,
        {
          type: 'upload',
          data: {
            files: [
              {
                fileid: file.fileid,
                filepath: file.filepath,
                mimetype: file.mimetype,
                size: file.size,
                name: file.name,
              },
            ],
          },
        }
      );
      toggleModal();
    } catch (e) {
      alert(__('Súbor sa nepodarilo skopírovať'));
    } finally {
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="l" />
      </LoaderWrapper>
    );
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Tree
        onClickNodeTitle={(i: any) => {
          if (i.isFile) {
            onClickFile(i);
          }
        }}
        treeData={treeData}
        onChange={setTreeData}
      />
    </div>
  );
};

export default InstancesTree;
