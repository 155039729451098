import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import {
  Field,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { RootState } from '../../../../rootReducer';
import { __, required } from '../../../../utilities';
import { activeAppspaceIdSelector } from '../../../../containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadProcedureInstanceById } from '../../actions';
import { procedureInstanceDetailSelector } from '../../selectors';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import api from '../../../../api';
import withPopups, { WithPopupsProps } from '../../../../components/WithPopups';
interface IFormData {
  status: 'FAILED' | 'ACCEPTED';
}
interface OwnProps {
  form: string;
  segmentId: string;
  documentId: string;
  handleClose: () => void;
  initialValues: IFormData | {};
  availableStatuses?: Array<{ label: string, value: string }>;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  instance: procedureInstanceDetailSelector(state),
  appSpace: activeAppspaceIdSelector(state),
  status: formValueSelector(props.form)(state, 'status'),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  WithPopupsProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & InjectedFormProps<IFormData, OwnProps>;

const ProcedureInstanceDocumentStatusForm: React.FC<Props> = ({ availableStatuses = [
  {
    value: 'IN_PROCESS',
    label: __('Rozpracované'),
  },
  {
    value: 'ACCEPTED',
    label: __('Schválený'),
  },
  {
    value: 'FAILED',
    label: __('Zamietnutý'),
  },
], status }) => {
  return (
    <Row>
      <Col xs={12}>
        <Field
          component={FormField}
          required
          validate={[required]}
          type="theselectsimple"
          name="status"
          label={__('Stav')}
          options={availableStatuses}
        />
      </Col>
      {status === 'FAILED' && (
        <>
          <Col xs={12}>
            <Field
              component={FormField}
              required
              validate={[required]}
              type="textarea"
              name="note"
              label={__('Dôvod zamietnutia')}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={FormField}
              type="date"
              name="dateTo"
              label={__('Termín dodania zmien')}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default compose(
  connect(mapStateToProps),
  withPopups,
  reduxForm<IFormData, Props>({
    enableReinitialize: true,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      if (props.instance) {
        try {
          await api.updateInstanceDocumentStatus(
            props.appSpace,
            props.instance._id,
            props.segmentId,
            props.documentId,
            undefined,
            values
          );
          props.dispatch(
            loadProcedureInstanceById.action({ id: props.instance._id })
          );
          props.handleClose();
        } catch (e) {
          if ((e as any).details.name === 'FORBIDDEN') {
            props.alert(
              __('Nemáte dostatočné oprávnenia na kontrolu tohoto dokumentu.')
            );
          } else {
            props.alert(
              `${__('Vyskytla sa neočakávaná chyba')} ${JSON.stringify(e)}`
            );
          }
        }
      }
    },
  })
)(ProcedureInstanceDocumentStatusForm);
