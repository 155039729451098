import { createGlobalStyle } from 'styled-components';

export const globalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    background: #F9F9F9;
  }
  #root {
    height: 100%;
  }
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
`;
