import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { __ } from '../../../utilities';
import Button from '@sportnet/ui/Button';
import Paginator from '@sportnet/ui/Paginator';

interface OwnProps {
  limit: number;
  loading?: boolean;
  offset: number;
  total: number;
  setParameter: (parameters: { [key: string]: any }) => void;
  onClickNew: () => void;
}

type Props = OwnProps;

const C = (props: Props) => {
  const { setParameter, limit, offset, total, onClickNew, loading } = props;

  return (
    <ContextBar>
      <ContextBarItem>
        <Paginator
          limit={limit}
          offset={offset}
          loading={loading}
          total={total}
          onChangeOffset={(value: number) =>
            setParameter({
              offset: value,
            })
          }
        />
      </ContextBarItem>
      <ContextBarSpacer />
      <ContextBarItem>
        <Button onClick={onClickNew} primary>
          {__('Nové konanie')}
        </Button>
      </ContextBarItem>
    </ContextBar>
  );
};

export default C;
