import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { CustomThunkDispatch } from '../../../configureStore';
import { FORM_NAME, IFormData } from '.';
import {
  InjectedFormProps,
  getFormValues,
  isSubmitting,
  isValid,
  reduxForm,
} from 'redux-form';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createProcedure } from './actions';
import BasicForm from './forms/basicForm';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Layout from '../../../containers/Layout';
import Segment from '@sportnet/ui/Segment';
import withAppSpaceId, {
  WithAppSpaceIdProps,
} from '../../../components/WithAppSpaceId';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';

interface OwnProps {
  dispatch: CustomThunkDispatch;
}

const mapStateToProps = (state: RootState) => ({
  isFormValid: isValid(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  isSubmitting: isSubmitting(FORM_NAME)(state),
});
type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps &
  WithAppSpaceIdProps &
  WithPopupsProps &
  InjectedFormProps<IFormData, OwnProps>;

const NewProcedure: React.FC<Props> = ({
  isFormValid,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <Layout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              loading={isSubmitting}
              type="submit"
              onClick={handleSubmit}
              primary
              disabled={!isFormValid}
            >
              {__('Pokračovať')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment>
        <Segment raised>
          <Header withSeparator>{__('Základné údaje')}</Header>
          <BasicForm />
        </Segment>
      </Segment>
    </Layout>
  );
};

export default compose(
  withAppSpaceId,
  withPopups,
  reduxForm<IFormData, Props>({
    form: FORM_NAME,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      try {
        const response = await props.dispatch(createProcedure.action());
        props.history.push(
          `/admin/${props.appSpaceId}/procedures/${response._id}`
        );
      } catch (e) {
        await props.alert(__('Chyba pri ukladaní'));
      }
    },
  }),
  connect(mapStateToProps)
)(NewProcedure);
