import * as React from 'react';
import { CustomThunkDispatch } from '../../../../configureStore';
import {
  Field,
  InjectedFormProps,
  change,
  getFormValues,
  isSubmitting,
  reduxForm,
  reset,
  submit,
} from 'redux-form';
import { ModalActions } from '@sportnet/ui/Modal';
import { RootState } from '../../../../rootReducer';
import { __, isValidObjectId, required } from '../../../../utilities';
import { addParticipantToProcedure } from '../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AsyncSelect from '@sportnet/ui/AsyncSelect';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import formatUserName from '@sportnet/utilities/formatUserName';
import sportnetApi, { OrganizationProfile } from '../../../../sportnetApi';
import styled from 'styled-components';
import withPopups, { WithPopupsProps } from '../../../../components/WithPopups';

export const PARTICIPANT_FORM_NAME = 'PARTICIPANT_FORM';

const RelativeWrapper = styled('div')`
  position: relative;
  padding-bottom: 10px;
`;

export interface IFormData {
  ppo: string;
  sportnetId: string;
}

interface OwnProps {
  handleClose: () => void;
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  return {
    formValues: getFormValues(PARTICIPANT_FORM_NAME)(state) as IFormData,
    isSubmitting: isSubmitting(PARTICIPANT_FORM_NAME)(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  WithPopupsProps &
  IMapStateToProps & {
    dispatch: CustomThunkDispatch;
  } & InjectedFormProps<IFormData, OwnProps>;

const AttendantForm: React.FC<Props> = ({ handleClose, form, dispatch }) => {
  const [organization, setOrganization] = React.useState<null | {
    label: string;
    value: string;
  }>(null);
  const [profiles, setProfiles] = React.useState<
    OrganizationProfile[] | undefined
  >([]);

  const getOrganizations = async (q: string) => {
    if (!q || q.length < 3) {
      return [];
    }
    const { organizations } = await sportnetApi.getOrganizations({ q });
    return (organizations || []).map((org) => ({
      label: org.full_name || org.name || '',
      value: org._id || '',
    }));
  };

  const onChangeOrganization = async (newOrganization: {
    value: string;
    label: string;
  }) => {
    setOrganization(newOrganization);
    dispatch(change(form, 'ppo', null));
    const { items } = await sportnetApi.getOrganizationProfiles(
      newOrganization.value
    );
    setProfiles(items);
  };

  const handleSave = () => {
    dispatch(submit(form));
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label>{__('Organizácia (min. 3 znaky)')}</Label>
            <RelativeWrapper>
              <AsyncSelect
                onChange={onChangeOrganization}
                value={organization}
                loadOptions={getOrganizations}
              />
            </RelativeWrapper>
          </FormGroup>
          <Field
            component={FormField}
            label={__('Profil organizácie')}
            type="theselectsimple"
            name="ppo"
            validate={[required]}
            required
            options={(profiles || []).map(
              (ppo: { name: string; _id: string }) => ({
                label: ppo.name,
                value: ppo._id,
              })
            )}
          />
          <Field
            component={FormField}
            label={__('Sportnet ID')}
            type="input"
            name="sportnetId"
            validate={[required, isValidObjectId]}
            required
          />
        </Col>
      </Row>
      <ModalActions>
        <div>&nbsp;</div>
        <div>
          <Button secondary type="button" onClick={handleClose}>
            {__('Zavrieť')}
          </Button>
          <Button
            loading={!!isSubmitting}
            primary
            type="button"
            onClick={handleSave}
          >
            {__('Uložiť')}
          </Button>
        </div>
      </ModalActions>
    </React.Fragment>
  );
};

export default compose(
  connect(mapStateToProps),
  withPopups,
  reduxForm<IFormData, Props>({
    enableReinitialize: false,
    form: PARTICIPANT_FORM_NAME,
    onSubmit: async (values: any, dispatch: any, props: any) => {
      try {
        const user = await sportnetApi.getPublicUserProfile(values.sportnetId);
        const userObject = {
          name: user.name || '',
          surname: user.surname || '',
          titles: user.titles
            ? {
                before: user.titles.before || '',
                after: user.titles.after || '',
              }
            : { before: '', after: '' },
        };
        if (
          await props.confirm(
            `${__(
              'Skutočne si želáte do konania pozvať užívateľa '
            )} ${formatUserName(userObject)}?`
          )
        ) {
          await props.dispatch(
            addParticipantToProcedure.action({
              user: { _id: user._id || '', ...userObject },
            })
          );
          props.dispatch(reset(PARTICIPANT_FORM_NAME));
          props.handleClose();
        }
      } catch (e) {
        if (
          (e as any).details.name === 'PARTICIPANT_ALREADY_EXISTS_IN_PROCEDURE'
        ) {
          await props.alert(__('Daná organizácia je už do konania prizvaná'));
        }
      }
    },
  })
)(AttendantForm);
