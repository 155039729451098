import * as React from 'react';
import { IContent } from '@sportnet/content/utilities/library';
import { RootState } from '../../../../rootReducer';
import { ThemeProvider } from 'styled-components';
import { WidgetContent } from '../../../../library/Common';
import { WrappedFieldProps } from 'redux-form';
import { __ } from '../../../../utilities';
import { activeAppspaceSelector } from '../../../../containers/App/selectors';
import { connect } from 'react-redux';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import Button from '@sportnet/ui/Button';
import Cookies from 'js-cookie';
import DEFAULT_WIDGETS from '@sportnet/content/editor/defaultWidgets';
import FormGroup from '@sportnet/ui/FormGroup';
import Header from '@sportnet/ui/Header';
import Label from '@sportnet/ui/Label/Label';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Tabber from '@sportnet/ui/Tabber';
import Widgets from '@sportnet/content/view';
import WidgetsAdmin from '@sportnet/content/editor';
import config from '../../../../config';

const tabs = [__('Editor'), __('Náhľad')];

const availableWidgets = DEFAULT_WIDGETS.filter((w: WidgetContent) =>
  ['text', 'document'].includes(w.type)
);

const mapStateToProps = (state: RootState) => ({
  appSpace: activeAppspaceSelector(state),
});

type OwnProps = {
  disabled?: boolean;
  label?: string;
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps & WrappedFieldProps;

const DescriptionField: React.FC<Props> = ({
  appSpace,
  input,
  meta: { error, touched },
  disabled,
  label,
}) => {
  const getContentFromProps = React.useCallback(() => {
    const val = input.value;
    return !Array.isArray(val) ? [] : val;
  }, [input]);

  const [isOpened, setIsOpened] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(tabs[0]);
  const [content, setContent] = React.useState(getContentFromProps());

  React.useEffect(() => {
    if (isOpened) {
      setContent(getContentFromProps());
    }
  }, [isOpened, getContentFromProps]);

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleChange = (newContent: IContent) => {
    setContent(newContent);
  };

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleSave = () => {
    input.onChange(content);
    setIsOpened(false);
  };

  const isInvalid = touched && error;
  return (
    <React.Fragment>
      <FormGroup>
        <Label>{label || __('Popis dokumentu')}</Label>
        <Button
          default={!isInvalid}
          danger={isInvalid}
          basic
          icon="pencil"
          onClick={handleOpen}
        >
          {disabled ? __('Zobraziť popis') : __('Upraviť popis')}
          {` (${
            content.length > 0
              ? `${__('Počet widgetov')}: ${content.length}`
              : __('prázdny')
          })`}
        </Button>
      </FormGroup>

      {isOpened && (
        <Modal isOpen={isOpened} handleClose={handleClose}>
          <ModalContent>
            <Header>{label || __('Popis dokumentu')}</Header>
            {disabled ? (
              <ThemeProvider theme={defaultContentTheme}>
                <Widgets
                  items={content}
                  renderLink={(link: any) => (
                    <a href="/" target="_blank" {...link.linkProps}>
                      {link.linkProps.children}
                    </a>
                  )}
                />
              </ThemeProvider>
            ) : (
              <ThemeProvider theme={defaultContentTheme}>
                <Tabber
                  items={tabs}
                  onClickItem={setActiveTab}
                  active={activeTab}
                >
                  {activeTab === tabs[0] && (
                    <WidgetsAdmin
                      appId={config.APP_ID}
                      appSpace={appSpace ? appSpace.app_space : ''}
                      contentDivider="default"
                      token={Cookies.get('access_token') || ''}
                      initialValue={content}
                      availableWidgets={availableWidgets}
                      onChange={handleChange}
                    />
                  )}
                  {activeTab === tabs[1] && (
                    <Widgets
                      items={content}
                      renderLink={(link: any) => (
                        <a href="/" target="_blank" {...link.linkProps}>
                          {link.linkProps.children}
                        </a>
                      )}
                    />
                  )}
                </Tabber>
              </ThemeProvider>
            )}
          </ModalContent>
          {!disabled && (
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button secondary type="button" onClick={handleClose}>
                  {__('Zavrieť')}
                </Button>
                <Button primary type="button" onClick={handleSave}>
                  {__('Uložiť')}
                </Button>
              </div>
            </ModalActions>
          )}
        </Modal>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(DescriptionField as any);
