import * as React from 'react';
import { Procedure } from '../../library/Procedure';
import { __ } from '../../utilities';

type Props = Pick<Procedure, 'status'>;

const getStatus = (s: string) => {
  switch (s) {
    case 'CLOSED':
      return __('Uzavreté');
    case 'DRAFT':
      return __('Rozpracované');
    case 'OPENED':
      return __('Otvorené');
  }
  return '';
};

const getProcedureStatus = (props: Props) => <>{getStatus(props.status)}</>;

export default getProcedureStatus;
